import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { deepMergeObject, eventsOptionsToRemove } from "../../utils";

function EventOptions({ data }) {
  let filtered = deepMergeObject(data?.EventOptions[0], data?.EventOptions[1]);
  eventsOptionsToRemove.map((item) => {
    delete filtered[item];
  });
  let updateEventOptions1Keys = Object.keys(filtered);
  let updateEventOptions1Values = Object.values(filtered);

  return (
    <Box component={Paper} sx={{ padding: "10px 5px" }}>
      <Box my={2} p={1}>
        <Typography gutterBottom fontWeight={600} textTransform={"capitalize"}>
          Event Options
        </Typography>
        <Box>
          <Grid container spacing={2}>
            {updateEventOptions1Keys?.map((item, idx) => (
              <Grid item xs={6} md={3} lg={2} key={`${item}123`}>
                <Chip
                  sx={{ minWidth: "95px" }}
                  variant="outlined"
                  // color="primary"
                  label={`${item}/ ${updateEventOptions1Values[idx]}`}
                />{" "}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default EventOptions;
