import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function GamesTable({
  data,
  page,
  totalCount,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenPreviewGameDialog,
  handleOpenToggleGameDialog,
  selectedGame,
  handleSelectGame,
}) {
  const [selectedId, setSelectedId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game?.lotteryId);
    handleSelectGame(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };
  const handleAddInstanceRoutePage = () => {
    navigate(`create-game-instance/${selectedId}`);
  };
  const handleEditInstanceRoutePage = () => {
    navigate(`/games/edit-game/${selectedId}`);
  };
  const handleViewInstanceRoutePage = () => {
    navigate(`/games/more-details/${selectedId}`);
  };
  const handleAddMasterRoutePage = () => {
    navigate(`/games/add-masters-page/${selectedGame?.slug}`);
  };
  const handleAddHowItWorkRoutePage = () => {
    navigate(`/games/add-how-it-works/${selectedGame?.slug}`);
  };

  const headCells = [
    "Name",
    "Category",
    "Game Count",
    "Result Count",
    "Date Created",
    "Status",
    "Actions",
  ];

  const headers = [
    "Name",
    "Category",
    "Game Count",
    "Result Count",
    "Date Created",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.name,
        item?.category,
        item?.gameCount,
        item?.resultCount,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="lotteries.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="games table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.lotteryId}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.category}</TableCell>
                  <TableCell>{row?.gameCount}</TableCell>
                  <TableCell>{row?.resultCount}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleAddInstanceRoutePage();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Add Game Instance
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleAddMasterRoutePage();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Add Master's Page
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleAddHowItWorkRoutePage();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Add How It Works Page
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenPreviewGameDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Game
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenToggleGameDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {!selectedGame?.status
                          ? "Activate Game"
                          : "Deactivate Game"}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleEditInstanceRoutePage();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleViewInstanceRoutePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* )} */}
    </Box>
  );
}

export default GamesTable;
