import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWRImmutable from "swr/immutable";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken, INFLUENCER } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import AccountInformation from "../components/UserDetailsComponents/AccountInformation";
import WalletInformation from "../components/UserDetailsComponents/WalletInformation";
import PlayerInformation from "../components/UserDetailsComponents/PlayerInformation";
import UsersDownlinesTable from "../components/UserDetailsComponents/UserDownlinesTable";
import UsersTickets from "../components/UserDetailsComponents/UsersTicket";
import UserTransactions from "../components/UserDetailsComponents/UserTransactions";
import UserBookedCodes from "../components/UserDetailsComponents/UserBookedCodes";
import SharedFundHistory from "../components/UserDetailsComponents/SharedFundHistory";
import BundleInformation from "../components/UserDetailsComponents/BundleInformation";

function PlayerDetailsPage() {
  const { userId } = useParams();

  const url = `${process.env.BASE_URL}user/fetch-user/${userId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWRImmutable(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator
        title={`${data && data?.firstname} ${
          data && data?.lastname
        } user's details - Admin`}
      />
      <Container maxWidth="xl">
        <PageHeading title="Player's details" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <PlayerInformation data={data} />
          </Grid>

          <Grid item xs={12} md={5}>
            <WalletInformation data={data} />
            <AccountInformation data={data} />
          </Grid>
        </Grid>
        {data && data?.role === INFLUENCER ? (
          <BundleInformation data={data} />
        ) : null}
      </Container>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <UsersTickets userId={userId} />
          </Grid>

          <Grid item xs={12} md={6}>
            <UserTransactions userId={userId} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <UserBookedCodes userId={userId} />
          </Grid>

          <Grid item xs={12} md={6}>
            <SharedFundHistory userId={userId} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Typography gutterBottom variant="h6">
          Affiliates Information
        </Typography>
        <UsersDownlinesTable data={data?.downlines} />
      </Box>
    </AdminLayout>
  );
}

export default PlayerDetailsPage;
