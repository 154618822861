import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import ExtensionIcon from "@mui/icons-material/Extension";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TimerIcon from "@mui/icons-material/Timer";
import CasinoIcon from "@mui/icons-material/Casino";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import SettingsIcon from "@mui/icons-material/Settings";
import ScienceIcon from "@mui/icons-material/Science";
import AirplayIcon from "@mui/icons-material/Airplay";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";
import LanguageIcon from "@mui/icons-material/Language";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import CelebrationIcon from "@mui/icons-material/Celebration";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from "@mui/icons-material/Article";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import FaceIcon from "@mui/icons-material/Face";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SportsIcon from "@mui/icons-material/Sports";
import DeckIcon from "@mui/icons-material/Deck";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FlagIcon from "@mui/icons-material/Flag";
import PaymentSharpIcon from "@mui/icons-material/PaymentSharp";
import BalanceIcon from "@mui/icons-material/Balance";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PaymentsIcon from "@mui/icons-material/Payments";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import SportsKabaddiOutlinedIcon from "@mui/icons-material/SportsKabaddiOutlined";
import BungalowIcon from "@mui/icons-material/Bungalow";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import FortRoundedIcon from "@mui/icons-material/FortRounded";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ROUTE_LINKS } from "../../utils/routeLinks";
export const navItems = [
  { text: "Dashboard", url: "/dashboard", icon: <HomeIcon /> },
  { text: "Players", url: "/players", icon: <GroupIcon /> },
  {
    text: "Virtual Agents",
    url: "/virtual-agents",
    icon: <SupportAgentIcon />,
  },
  { text: "Payouts", url: "/payouts", icon: <PaymentSharpIcon /> },

  {
    text: "Transactions",
    url: "/transactions",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    text: "Withdrawal Requests",
    url: "/withdrawal-requests",
    icon: <RequestPageIcon />,
  },
  {
    text: "Due Remission",
    url: "/due-remission",
    icon: <TimerIcon />,
  },
  {
    text: "Logs",
    url: "/logs",
    icon: <AddToQueueIcon />,
  },

  { text: "Booked Codes", url: "/bookedcodes", icon: <BookOnlineIcon /> },

  {
    text: "Winning Check",
    url: "/winning-check",
    icon: <HelpOutlineIcon />,
  },
  {
    text: "Device Analytics",
    url: "/device-analytics",
    icon: <AssessmentIcon />,
  },
];

export const navAdminItems = {
  title: "Manage Admin",
  icon: <AdminPanelSettingsIcon />,
  openValue: 1,
  items: [
    {
      text: "Add Admin",
      url: "/admins/add",
      icon: <ControlPointDuplicateIcon />,
    },
    { text: "View Admins", url: "/admins", icon: <GroupIcon /> },
    {
      text: "Admin Transactions",
      url: "/admins-transactions",
      icon: <LanguageIcon />,
    },
  ],
};
export const navAgentItems = {
  title: "Manage Agents",
  icon: <AdminPanelSettingsIcon />,
  openValue: 9,
  items: [
    {
      text: "Add Agent",
      url: "/agents/add",
      icon: <ControlPointDuplicateIcon />,
    },
    { text: "View Agents", url: "/agents", icon: <SupportAgentIcon /> },
  ],
};
export const navReportsItems = {
  title: "Reports",
  icon: <FlagIcon />,
  openValue: 10,
  items: [
    {
      text: "Game Reports",
      url: "/reports",
      icon: <SmartToyIcon />,
    },
    {
      text: "Players Reports",
      url: "/player-reports",
      icon: <NaturePeopleIcon />,
    },
    {
      text: "Agents Reports",
      url: "/agent-reports",
      icon: <DeckIcon />,
    },
    {
      text: "Commission Reports",
      url: "/commission-reports",
      icon: <PaymentsIcon />,
    },
  ],
};

export const navGamesItems = {
  title: "Game Categories",
  icon: <CasinoIcon />,
  openValue: 3,
  items: [
    {
      text: "Add Category",
      url: "/games/add-lottery",
      icon: <ExtensionIcon />,
    },
    { text: "All Games", url: "/games", icon: <AirplayIcon /> },
  ],
};

export const navTicketsItems = {
  title: "Tickets",
  icon: <CandlestickChartIcon />,
  openValue: 13,
  items: [
    {
      text: "Main Tickets",
      url: "/tickets",
      icon: <BalanceIcon />,
    },
    {
      text: "Bonus Tickets",
      url: "/bonus-tickets",
      icon: <DoneAllIcon />,
    },
  ],
};

export const navBannerItems = {
  title: "Banners",
  icon: <ViewCarouselIcon />,
  openValue: 4,
  items: [
    { text: "Add Banner", url: "/banners/add", icon: <AddRoadIcon /> },
    { text: "All Banners", url: "/banners", icon: <ImageAspectRatioIcon /> },
  ],
};

export const navGameInstanceItems = {
  title: "Game Instance",
  icon: <CelebrationIcon />,
  openValue: 5,
  items: [{ text: "All Games", url: "/games-instance", icon: <AirplayIcon /> }],
};
export const navConfigItems = {
  title: "Configurations",
  icon: <SettingsIcon />,
  openValue: 6,
  items: [
    {
      text: "Avatar Settings",
      url: "/configurations/avatar-settings",
      icon: <FaceIcon />,
    },
    // { text: "Game", url: "/configurations/game", icon: <AirplayIcon /> },
    {
      text: "SMTP/Email",
      url: "/configurations/smtp-email",
      icon: <LoyaltyIcon />,
    },
    {
      text: "Lottery Images",
      url: "/configurations/lottery-images",
      icon: <SportsIcon />,
    },
    {
      text: "SMS-Gateway",
      url: "/configurations/sms-gateway",
      icon: <ExtensionIcon />,
    },
    {
      text: "Bonus",
      url: "/configurations/bonus",
      icon: <FreeBreakfastIcon />,
    },
    {
      text: "Bundle Bonus",
      url: "/configurations/bundle-bonus",
      icon: <BungalowIcon />,
    },
    {
      text: "Site Settings",
      url: "/configurations/site-settings",
      icon: <LanguageIcon />,
    },
  ],
};
export const navRolesItems = {
  title: "Roles",
  icon: <ScienceIcon />,
  openValue: 7,
  items: [
    { text: "Agent Roles", url: "/roles/agentroles", icon: <AirplayIcon /> },
    { text: "Admin Roles", url: "/roles/adminroles", icon: <LoyaltyIcon /> },
  ],
};

export const navStaticPagesItems = {
  title: "Static Pages",
  icon: <AutoStoriesIcon />,
  openValue: 8,
  items: [
    { text: "Add Pages", url: "/add-static-page", icon: <PostAddIcon /> },
    {
      text: "View Pages",
      url: "/static-pages/all-pages",
      icon: <ArticleIcon />,
    },
    { text: "Add Headers", url: "/add-header", icon: <PostAddIcon /> },
    {
      text: "View Headers",
      url: "/static-pages/view-headers",
      icon: <LanguageIcon />,
    },
  ],
};

export const navInfluencerItems = {
  title: "Manage Influencers",
  icon: <AllInclusiveIcon />,
  openValue: 11,
  items: [
    {
      text: "Add Influencer",
      url: "/influencers/add",
      icon: <CoffeeMakerIcon />,
    },
    { text: "View Influencers", url: "/influencers", icon: <GroupIcon /> },
  ],
};
export const navSportsItems = {
  title: "Sports Bet",
  icon: <SportsSoccerIcon />,
  openValue: 14,
  items: [
    { text: "Add League", url: "/add-league", icon: <PostAddIcon /> },
    {
      text: "View Leagues",
      url: "/sports-bet/all-leagues",
      icon: <EmojiEventsIcon />,
    },

    {
      text: "Add Team",
      url: "/add-team",
      icon: <SportsKabaddiOutlinedIcon />,
    },
    {
      text: "View Teams",
      url: "/sports-bet/all-teams",
      icon: <TheaterComedyIcon />,
    },
    { text: "Add Event", url: "/add-event", icon: <EventNoteIcon /> },
    {
      text: "Active Events",
      url: "/sports-bet/view-events",
      icon: <LocalFloristIcon />,
    },
    {
      text: "Elapsed Events",
      url: "/sports-bet/elapsed-events",
      icon: <TimelapseIcon />,
    },

    {
      text: "Sports Results",
      url: "/sports-bet/sports-results",
      icon: <FortRoundedIcon />,
    },
    {
      text: "Prize Claims",
      url: "/sports-bet/prize-claims",
      icon: <EmojiEventsOutlinedIcon />,
    },
  ],
};
export const navProductsItems = {
  title: "Products",
  icon: <StorefrontIcon />,
  openValue: 15,
  items: [
    { text: "Add Product", url: "/add-product", icon: <AddShoppingCartIcon /> },
    {
      text: "Add Category",
      url: "/add-product-category",
      icon: <PostAddIcon />,
    },
    {
      text: "Product Categories",
      url: "/product-categories",
      icon: <ShoppingBasketIcon />,
    },
    {
      text: "View Products",
      url: "/products",
      icon: <LocalGroceryStoreIcon />,
    },
    {
      text: "View Lotto Products",
      url: ROUTE_LINKS.LOTTO_PRODUCTS,
      icon: <LocalGroceryStoreIcon />,
    },
  ],
};

export const navDealersItems = {
  title: "Manage Dealers",
  icon: <AdminPanelSettingsIcon />,
  openValue: 16,
  items: [
    {
      text: "Dealers",
      url: "/dealers",
      icon: <LocalOfferOutlinedIcon />,
    },
    {
      text: "Product Requests",
      url: "/product-requests",
      icon: <ViewTimelineOutlinedIcon />,
    },
  ],
};
export const navDealsItems = {
  title: "Deals",
  icon: <SmartToyIcon />,
  openValue: 17,
  items: [
    {
      text: "Deals Ticket",
      url: "/deals-tickets",
      icon: <LocalOfferOutlinedIcon />,
    },
    {
      text: "Booked Deals",
      url: "/bookeddeals",
      icon: <AirplanemodeActiveRoundedIcon />,
    },
  ],
};
