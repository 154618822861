import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { MdSettingsSuggest, MdSettingsInputComposite } from "react-icons/md";

function SiteSettingsTable({ data, totalCount }) {
  //   console.log("dddddddddddddddd", data);
  const navigate = useNavigate();
  const iconStyle = { fontSize: "2.2rem", color: "#F59746" };
  const buttonStyle = { textTransform: "capitalize" };
  return (
    <Grid container spacing={3}>
      {data &&
        data?.length > 0 &&
        data?.map((item, index) => (
          <Grid key={item?.settingId} item xs={12} sm={6} md={4}>
            <Card my={2} sx={{ width: "100%" }}>
              <CardContent sx={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70px",
                    height: "70px",
                    margin: "7px auto",
                    borderRadius: "50%",
                    background: `rgba(205, 222, 223, 0.13)`,
                  }}
                >
                  {(index / 2) % 1 ? (
                    <MdSettingsInputComposite style={iconStyle} />
                  ) : (
                    <MdSettingsSuggest style={iconStyle} />
                  )}
                </div>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  sx={{ fontWeight: 500 }}
                >
                  {item?.title}
                </Typography>
                <Typography align="center">{item?.slug}</Typography>
                <Box
                  mt={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={buttonStyle}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      navigate(`update-details/${item?.settingId}`)
                    }
                  >
                    Update Setting
                  </Button>{" "}
                  <Button
                    sx={buttonStyle}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      navigate(`settings-details/${item?.settingId}`)
                    }
                  >
                    View Setting
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}

export default SiteSettingsTable;
