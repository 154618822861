import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import Typography from "@mui/material/Typography";
import "../styles/addsiteconfig.css";

function AddSiteConfig() {
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const initialValues = {
    title: "",
    slug: "",
    content: [{ name: "", value: "", number: "", description: "" }],
  };
  let siteSchema = yup.object().shape({
    title: yup.string().required().label("Title"),
    slug: yup.string().required().label("Slug"),
    content: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required("Required"),
          value: yup.string().required("Required"),
          number: yup.number(),
          description: yup.string(),
        })
      )
      .required("Settings Content is Required")
      .min(1, "Settings Content is Required"),
  });

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    values.content = JSON.stringify(values?.content);
    axios
      .post(`${process.env.BASE_URL}site-settings/create-settings`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: "Site Settings Created Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Sorry, Site Settings Could Not Be Created",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add Site Setting - Admin" />
      <Container maxWidth="lg">
        <PageHeading title="Add Site Setting" />

        <Box my={1}>
          <Typography gutterBottom>
            To Add a new site settings, fill every required fields, click on the
            add more button to add more setting fields, the display title,
            value, and number are required and finally on the submit button to
            save the settings
          </Typography>
        </Box>
      </Container>
      <br />
      <Container maxWidth="lg">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={siteSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Setting's Title"
                      error={touched.title && errors.title}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>

                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Setting Slug, seperate words by a hyphen, e.g
                      legendary-lotto"
                      error={touched.slug && errors.slug}
                      name="slug"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.slug && errors.slug}
                    />
                  </FormControl>
                </Box>
                <Box my={2}>
                  <Typography gutterBottom>
                    Fill the Site Setting Contents
                  </Typography>
                  <FieldArray name="content">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.content &&
                          values?.content?.length > 0 &&
                          Array.isArray(values?.content) &&
                          values?.content?.map((row, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12} sm={6} md={3}>
                                <label style={labelStyles}>Display Title</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].name`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                                <ErrorMessage
                                  name={`content.${index}.name`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <label style={labelStyles}>Value</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].value`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                                <ErrorMessage
                                  name={`content.${index}.value`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <label style={labelStyles}>Number</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].number`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="number"
                                />
                                <ErrorMessage
                                  name={`content.${index}.number`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item sm={6} md={3}>
                                <label style={labelStyles}>Description</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].description`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                              </Grid>
                              <Grid item sm={6} md={1}>
                                <IconButton
                                  size="large"
                                  type="button"
                                  color="error"
                                  disabled={index === 0}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        <br />
                        <Button
                          size="small"
                          type="button"
                          variant="contained"
                          color="warning"
                          onClick={() =>
                            push({
                              name: "",
                              value: "",
                              number: "",
                              description: "",
                            })
                          }
                        >
                          Add Field
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Box>

                <br />
                <Button
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddSiteConfig;
