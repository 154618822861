import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function TableLoader() {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton height={50} sx={{ mb: "5px" }} />
      <Skeleton height={50} sx={{ mb: "5px" }} animation="wave" />
      <Skeleton height={50} sx={{ mb: "5px" }} animation={false} />
      <Skeleton height={50} sx={{ mb: "5px" }} animation="wave" />
      <Skeleton height={50} sx={{ mb: "5px" }} animation="wave" />
      <Skeleton height={50} sx={{ mb: "5px" }} animation={false} />
      <Skeleton height={50} sx={{ mb: "5px" }} animation={false} />
    </Box>
  );
}
