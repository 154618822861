import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { MdOutlineGppGood, MdOutlinePendingActions } from "react-icons/md";
import { GiMissileSwarm } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import "../../styles/analytics.css";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import MoneyAnalyticsCard from "../MoneyAnalyticsCard/MoneyAnalyticsCard";

const url = `${process.env.BASE_URL}transactions/analytics`;

function TransactionsAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, admins analytics data cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return null;
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MoneyAnalyticsCard
            icon={<BiTransfer className="cardIcon" />}
            moneyText={"Total Amount"}
            countText={"Total Transactions"}
            countValue={data?.totalTransactions}
            moneyValue={
              data?.totalSuccessfulTransactionsAmount +
              data?.totalFailedTransactionsAmount
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MoneyAnalyticsCard
            icon={<MdOutlineGppGood className="cardIcon" />}
            moneyText={"Amount"}
            countText={"Successful Transactions"}
            countValue={data?.totalSuccessfulTransactions}
            moneyValue={data?.totalSuccessfulTransactionsAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MoneyAnalyticsCard
            icon={<MdOutlinePendingActions className="cardIcon" />}
            moneyText={"Amount"}
            countText={"Pending Transactions"}
            countValue={data?.totalPendingTransactions}
            moneyValue={data?.totalPendingTransactionsAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MoneyAnalyticsCard
            icon={<GiMissileSwarm className="errorCardIcon" />}
            moneyText={"Amount"}
            countText={"Failed Transactions"}
            countValue={data?.totalFailedTransactions}
            moneyValue={data?.totalFailedTransactionsAmount}
            error={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TransactionsAnalytics;
