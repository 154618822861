import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

export const headCells = [
  "Name",
  "Home Team",
  "Away Team",
  "Country",
  "League",
  "ID",
  "Week",
  "Type",
  "Event Date",
  "End Date",
  "Status",
  "Actions",
];
function EventsTable({
  data,
  page,
  selectedEvent,
  rowsPerPageOptions,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleSelectCategory,
  handleChangePage,
  handleOpenActivateDialog,
  handleOpenDeactivateDialog,
  totalCount,
}) {
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    handleSelectCategory(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToEditGamePage = () => {
    navigate(`/edit-event-details/${selectedEvent?.eventId}`);
  };
  const moveToSeeMorePage = () => {
    navigate(`/sports-bet/event-details/${selectedEvent?.eventId}`);
  };

  const headers = [
    "Name",
    "Home Team",
    "Away Team",
    "Country",
    "League",
    "ID",
    "Week",
    "Type",
    "Event Date",
    "End Date",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.title,
        item?.HomeTeam?.name,
        item?.AwayTeam?.name,
        item?.HomeTeam?.League?.country,
        item?.HomeTeam?.League?.name,
        item?.id,
        item?.week,
        item?.type,
        item?.eventDate,
        item?.endDate,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton
          data={rows}
          filename={`active_events_${Date.now()}.csv`}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.eventId}>
                  <TableCell>{row?.title}</TableCell>
                  <TableCell>{row?.HomeTeam?.name}</TableCell>
                  <TableCell>{row?.AwayTeam?.name}</TableCell>
                  <TableCell>{row?.HomeTeam?.League?.country}</TableCell>
                  <TableCell>{row?.HomeTeam?.League?.name}</TableCell>
                  <TableCell>{row?.id}</TableCell>
                  <TableCell>{row?.week}</TableCell>
                  <TableCell>{row?.type}</TableCell>
                  <TableCell style={{ width: 120 }}>
                    {dayjs(row?.eventDate).format("MMM D, YYYY, HH:mma")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {dayjs(row?.endDate).format("MMM D, YYYY, HH:mma")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {!selectedEvent?.status ? (
                        <MenuItem
                          onClick={() => handleOpenActivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Event
                        </MenuItem>
                      ) : null}

                      {selectedEvent?.status ? (
                        <MenuItem
                          onClick={() => handleOpenDeactivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate Event
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem
                        onClick={() => moveToEditGamePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToSeeMorePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        See Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default EventsTable;
