import React, { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import Files from "react-files";
import Container from "@mui/material/Container";
import { useFormik, Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formPaperStyle,
  getAuthToken,
  getWindowQueryString,
  labelStyles,
  labelStylesThree,
  labelStylesTwo,
} from "../utils";
import "../styles/AddProductCategory.css";
import useSWR from "swr";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import { useNavigate } from "react-router-dom";

function AddTeam() {
  const [image, setImage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleOpenSnackbar } = useContext(AppContext);
  const navigate = useNavigate();
  const leagueId = getWindowQueryString("leagueId");

  let leagueURL = `${process.env.BASE_URL}game/fetch-leagues?limit=1000&page=1`;

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(leagueURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data?.data;
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      teamName: "",
      customTeamId: "",
      alias: "",
      logo: "",
      leagueId: leagueId ?? "",
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const foreground = await handleSubmitImage(
        image,
        "Sorry, an error occured while uploading the category icon image"
      );

      values.logo = foreground;

      axios
        .post(`${process.env.BASE_URL}game/create-team`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              navigate("/sports-bet/all-teams");
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Team Created Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            navigate("/sports-bet/all-teams");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Team Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      teamName: yup.string().required().label("Team Name"),
      alias: yup.string().required().label("Team Alias"),
      customTeamId: yup.string().required().label("Team ID"),
    }),
  });

  const handleChangeImage = (files) => {
    setImage((curr) => files[0]);
  };

  const handleErrorImage = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const { data, error } = useSWR(leagueURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve league data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Add New Team - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Add New Team" />

        <Paper sx={formPaperStyle}>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>Select Logo for Team</FormLabel>
                  <br />
                  <Files
                    className="dropzone"
                    onChange={handleChangeImage}
                    onError={handleErrorImage}
                    accepts={["image/*"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Typography gutterBottom align="center">
                      Drop file here or click to upload image of team
                    </Typography>
                    {image ? (
                      <Box>
                        <img
                          src={URL.createObjectURL(image)}
                          className="imagePreview"
                          alt="category image"
                        />
                      </Box>
                    ) : null}
                  </Files>
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl
                  fullWidth
                  error={formik.touched.leagueId && formik.errors.leagueId}
                >
                  <FormLabel variant="outlined" sx={labelStylesTwo}>
                    Select League
                  </FormLabel>

                  <Select
                    name="leagueId"
                    value={formik.values.leagueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {data &&
                      data?.length > 0 &&
                      data
                        ?.filter((item) => item.status === true)
                        ?.map((row, idx) => (
                          <MenuItem
                            key={`222${idx}${row?.leagueId}`}
                            value={row?.leagueId}
                          >
                            {row?.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.leagueId && formik.errors.leagueId}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Name of Team"
                    error={formik.touched.teamName && formik.errors.teamName}
                    name="teamName"
                    variant="outlined"
                    value={formik.values.teamName}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.teamName && formik.errors.teamName
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Alias of Team"
                    error={formik.touched.alias && formik.errors.alias}
                    name="alias"
                    variant="outlined"
                    value={formik.values.alias}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.alias && formik.errors.alias}
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Id of Team"
                    error={
                      formik.touched.customTeamId && formik.errors.customTeamId
                    }
                    name="customTeamId"
                    variant="outlined"
                    value={formik.values.customTeamId}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.customTeamId && formik.errors.customTeamId
                    }
                  />
                </FormControl>
              </Box>

              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Adding Team" : " Add Team"}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </AdminLayout>
  );
}

export default AddTeam;
