import { useState, useContext, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  deepMergeObject,
  eventsOptionsToRemove,
  formPaperStyle,
  formatErrorMessage,
  getAuthToken,
  labelStylesThree,
} from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import { useNavigate, useParams } from "react-router-dom";
import FormLoader from "../components/FormLoader/FormLoader";
import { eventTypesList } from "./AddProduct";

function UpdateEvent() {
  const [eventError, setEventError] = useState("");
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [eventData, setEventData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedLeagueData, setSelectedLeagueData] = useState({});
  const [date, setDate] = useState(new Date());

  const { handleOpenSnackbar } = useContext(AppContext);
  const specialInputRef = useRef(null);

  const { eventId } = useParams();
  const navigate = useNavigate();
  const eventUrl = `${process.env.BASE_URL}game/fetch-event/${eventId}`;

  const url = `${
    process.env.BASE_URL
  }game/fetch-teams?page=${1}&limit=${1000}&status=true`;
  const leagueUrl = `${
    process.env.BASE_URL
  }game/fetch-leagues?page=${1}&limit=${1000}`;

  const fetchLeague = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(leagueUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const getSelectedLeagueData = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingTeams(true);
    return axios
      .get(`${process.env.BASE_URL}game/fetch-league/${selectedLeague}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let content = res?.data?.data?.data;

        setSelectedLeagueData(content);
        setLoadingTeams(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };
  const handleSelectLegaue = (e) => {
    const value = e.target.value;

    setSelectedLeague(value);
    setLoadingTeams(true);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);
  };

  // Function to get all the bet options
  const specialOnchange = () => {
    getSelectedLeagueData();
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  let initialValues = {
    leagueId: "",
    homeTeamId: "",
    awayTeamId: "",
    startDate: "",
    duration: "",
    week: "",
    type: "",
    customEventId: "",
    oddDistribution: [],
    odds: [
      { name: "1", field: "" },
      { name: "X", field: "" },
      { name: "2", field: "" },
      { name: "0-0", field: "" },
      { name: "0-1", field: "" },
      { name: "0-2", field: "" },
      { name: "0-3", field: "" },
      { name: "0-4", field: "" },
      { name: "0-5", field: "" },
      { name: "1-0", field: "" },
      { name: "2-0", field: "" },
      { name: "3-0", field: "" },
      { name: "4-0", field: "" },
      { name: "5-0", field: "" },
      { name: "1-1", field: "" },
      { name: "2-1", field: "" },
      { name: "3-1", field: "" },
      { name: "4-1", field: "" },
      { name: "5-1", field: "" },
      { name: "1-2", field: "" },
      { name: "2-2", field: "" },
      { name: "3-2", field: "" },
      { name: "4-2", field: "" },
      { name: "5-2", field: "" },
      { name: "1-3", field: "" },
      { name: "2-3", field: "" },
      { name: "3-3", field: "" },
      { name: "4-3", field: "" },
      { name: "5-3", field: "" },
      { name: "1-4", field: "" },
      { name: "2-4", field: "" },
      { name: "3-4", field: "" },
      { name: "4-4", field: "" },
      { name: "5-4", field: "" },
      { name: "1-5", field: "" },
      { name: "2-5", field: "" },
      { name: "3-5", field: "" },
      { name: "4-5", field: "" },
      { name: "5-5", field: "" },
    ],
  };

  const fetchSingleEvent = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(eventUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setEventData(res?.data?.data?.data);
      let league = res?.data?.data?.data?.HomeTeam?.leagueId;
      if (league) {
        // await getSelectedLeagueData(league);
        const leagueRes = await axios.get(
          `${process.env.BASE_URL}game/fetch-league/${league}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(leagueRes?.data?.data?.data);
        setSelectedLeagueData(leagueRes?.data?.data?.data);
        setSelectedLeague(league);
        setLoadingTeams(false);
      }
      setDate(dayjs(res?.data?.data?.data?.eventDate));
      setLoadingEvent(false);
    } catch (error) {
      setLoadingEvent(false);
      setEventError(error);
      console.log("error fetching lottery data", error);
    }
  };

  useEffect(() => {
    fetchSingleEvent();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      let oddDistribution = [];

      values.odds?.map((item) => {
        oddDistribution?.push(parseFloat(item?.field));
      });

      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const payload = {
        homeTeamId: values.homeTeamId,
        awayTeamId: values.awayTeamId,
        startDate: values.startDate,
        week: values.week,
        oddDistribution,
        eventType: values.type,
        customEventId: values.customEventId,
        // endDate: dayjs(values.startDate).add(150, "minutes"),
      };

      axios
        .put(`${process.env.BASE_URL}game/update-event/${eventId}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Event Updated Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            navigate("/sports-bet/view-events");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          const errMsg = formatErrorMessage(err);

          handleOpenSnackbar({
            message: errMsg,
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      homeTeamId: yup.string().required().label("Home Team"),
      awayTeamId: yup.string().required().label("Away Team"),
      week: yup.number().required().label("Week"),
      startDate: yup.string().required().label("Start Date"),
    }),
  });

  if (eventData?.title) {
    initialValues.leagueId = eventData?.HomeTeam?.leagueId;
    initialValues.homeTeamId = eventData?.HomeTeam?.teamId;
    initialValues.awayTeamId = eventData?.AwayTeam?.teamId;
    initialValues.week = eventData?.week;
    initialValues.startDate = eventData?.eventDate;
    initialValues.type = eventData?.type;
    initialValues.customEventId = eventData?.customEventId;

    let filtered = deepMergeObject(
      eventData?.EventOptions[0],
      eventData?.EventOptions[1]
    );

    eventsOptionsToRemove.map((item) => {
      delete filtered[item];
    });
    let newOdds = [];

    Object.keys(filtered).map((item) => {
      newOdds?.push({ name: item, field: filtered[item] });
    });

    initialValues.odds = newOdds;
    // initialValues.homeTeamId = eventData?.homeTeamId
    // initialValues.homeTeamId = eventData?.homeTeamId
  }
  const { data, error } = useSWR(leagueUrl, fetchLeague);
  // const { data: leagueData, error: leagueError } = useSWR(
  //   leagueUrl,
  //   fetchLeague
  // );

  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update Event - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Event" />
        <Button
          type="button"
          sx={{ display: "none" }}
          onClick={() => specialOnchange()}
          ref={specialInputRef}
        >
          click
        </Button>
        <Paper sx={formPaperStyle}>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formik.touched.leagueId && formik.errors.leagueId}
                >
                  <InputLabel variant="outlined" sx={labelStylesThree}>
                    Select League
                  </InputLabel>

                  <Select
                    name="leagueId"
                    value={formik.values.leagueId}
                    onChange={handleSelectLegaue}
                  >
                    {data?.map((item, idx) => (
                      <MenuItem
                        key={`2${idx}${item?.leagueId}`}
                        value={item?.leagueId}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.leagueId && formik.errors.leagueId}
                  </FormHelperText>
                </FormControl>
              </Box>
              {selectedLeague && (
                <>
                  {" "}
                  {loadingTeams ? (
                    <>
                      <FormLoader />
                      <FormLoader />
                    </>
                  ) : (
                    <>
                      <Box my={1}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={
                            formik.touched.homeTeamId &&
                            formik.errors.homeTeamId
                          }
                        >
                          <InputLabel variant="outlined" sx={labelStylesThree}>
                            Select Home Team
                          </InputLabel>

                          <Select
                            name="homeTeamId"
                            value={formik.values.homeTeamId}
                            onChange={formik.handleChange}
                          >
                            {selectedLeagueData?.Teams?.map((item, idx) => (
                              <MenuItem
                                key={`122${idx}${item?.teamId}`}
                                value={item?.teamId}
                              >
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {formik.touched.homeTeamId &&
                              formik.errors.homeTeamId}
                          </FormHelperText>
                        </FormControl>
                      </Box>

                      <Box my={1}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={
                            formik.touched.awayTeamId &&
                            formik.errors.awayTeamId
                          }
                        >
                          <InputLabel variant="outlined" sx={labelStylesThree}>
                            Select Away Team
                          </InputLabel>

                          <Select
                            name="awayTeamId"
                            value={formik.values.awayTeamId}
                            disabled={!formik.values?.homeTeamId}
                            onChange={formik.handleChange}
                          >
                            {selectedLeagueData?.Teams?.filter(
                              (team) =>
                                team?.teamId !== formik.values?.homeTeamId
                            ).map((category, idx) => (
                              <MenuItem
                                key={`${idx}${category?.teamId}`}
                                value={category?.teamId}
                              >
                                {category?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {formik.touched.awayTeamId &&
                              formik.errors.awayTeamId}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </>
                  )}
                </>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="ID of Event"
                        error={
                          formik.touched.customEventId &&
                          formik.errors.customEventId
                        }
                        name="customEventId"
                        variant="outlined"
                        value={formik.values.customEventId}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.customEventId &&
                          formik.errors.customEventId
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Week of Event"
                        error={formik.touched.week && formik.errors.week}
                        name="week"
                        variant="outlined"
                        type="number"
                        value={formik.values.week}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={formik.touched.week && formik.errors.week}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDateTimePicker
                          value={date}
                          onChange={(value) => {
                            // console.log("ssssssssssssssss", value);
                            setDate(value);
                            formik.setFieldValue("startDate", value);
                          }}
                          disablePast
                          // views={["year", "month", "day", "hours", "minutes"]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Start Date and Time"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel>Event Type</FormLabel>
                    <RadioGroup name="type" row value={formik.values.type}>
                      {eventTypesList?.map((item) => (
                        <FormControlLabel
                          key={item?.value}
                          name="type"
                          value={item.value}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          control={<Radio />}
                          label={item?.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Box my={2}>
                <Typography gutterBottom fontWeight={500}>
                  Odds
                </Typography>
                <Grid container spacing={2}>
                  {formik?.values?.odds?.map((item, idx) => (
                    <Grid key={item.name} item xs={12} sm={6} md={3}>
                      <Box>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            label={item.name}
                            name={`odds[${idx}].field`}
                            variant="outlined"
                            type="number"
                            value={formik.values.odds[idx].field}
                            fullWidth
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Updating Event" : "Update Event"}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </AdminLayout>
  );
}

export default UpdateEvent;
