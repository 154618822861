import React, { useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
// import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import { useNavigate } from "react-router-dom";

import SportsbetResultsTable from "../components/SportsbetResults/SportsbetResultsTable";

function SportbetResults() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [status, setStatus] = useState("");
  const [currentWeekDay, setCurrentWeekDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openAddResultDialog, setOpenAddResultDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const url = `${process.env.BASE_URL}sport/fetch-admin-result-history?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}sport/fetch-admin-result-history?limit=${rowsPerPage}&page=${page}`;
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations
  let sStatus = "status";
  let sStartTime = "startTime";
  let sEndTime = "endTime";
  let sCurrentWeekDay = "currentWeekDay";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sStartTime);
    sessionStorage.removeItem(sEndTime);
    sessionStorage.removeItem(sCurrentWeekDay);
  };

  const handleResetFields = () => {
    setStatus("");
    setStartTime(null);
    setEndTime(null);
    setCurrentWeekDay(null);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectCategory = (game) => setSelectedEvent(game);

  const handleOpenDeactivateDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenApproveDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateDialog = () => {
    setOpenActivateDialog(false);
  };

  const handleSubmitDeactivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      teamId: selectedEvent?.teamId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/toggle-team-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Team have Been Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Team Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleSubmitActivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      resultId: selectedEvent?.resultId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}sport/approve-result`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Result Have Been Successfully Approve",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Result Could Not Be Approve",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <Box>
      <SportsbetResultsTable
        page={page}
        data={data?.data}
        totalCount={data?.totalCount}
        selectedEvent={selectedEvent}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleSelectCategory={handleSelectCategory}
        handleOpenApproveDialog={handleOpenApproveDialog}
      />
    </Box>
  );
}

export default SportbetResults;
