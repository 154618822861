import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import Approval1 from "../components/AgentsCommission/Approval1/Index";
import Approval2 from "../components/AgentsCommission/Approval2/Index";
import Approval3 from "../components/AgentsCommission/Approval3/Index";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";

const AgentsCommission = () => {
  const { adminData } = useContext(AppContext);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <div>
        <MetaDecorator title="Agents Due Commission Table - Admin" />
        <PageHeading title="Agents Commission" />
        <section>
          {/* <Approval1 /> */}
          {/* {adminData?.role === "superaccountant" ? <Approval2 /> : null} */}
          {/* {adminData?.role === "superadmin" ? <Approval3 /> : null} */}
        </section>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          sx={{ color: "green", marginTop: "5px" }}
        >
          <Tab label="Accountant" value={0} />
          <Tab label="Super Accounant" value={1} />
          <Tab label="Super Admin" value={2} />
        </Tabs>
        {value === 0 && <Approval1 value={value} />}
        {value === 1 && <Approval2 value={value} />}
        {value === 2 && <Approval3 value={value} />}
      </Box>
    </AdminLayout>
  );
};

export default AgentsCommission;
