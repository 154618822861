import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import "./styles.css";

dayjs.extend(weekOfYear);

function WeekTabs({
  weeks,
  selected,
  setSelected,
  setChosenWeek,
  handleCancelFilters,
}) {
  const currentWeek = dayjs().week();
  //   React.useEffect(() => {
  //     handleSetSessionStorage();

  //     return () => {
  //       handleClearSessionStorage();
  //     };
  //   }, []);

  const handleClick = (payload, index) => {
    const newWeek = payload?.week();
    handleCancelFilters();
    let newStartDate = payload;
    let endDay = newStartDate.add(6, "day");

    newStartDate = newStartDate.format("YYYY-MM-DD");
    endDay = endDay.format("YYYY-MM-DD");
    setChosenWeek((curr) => newWeek);
    setSelected((curr) => index);
  };
  // console.log("slecsssss", dayjs().duration().days());
  return (
    <div className="weeksCont">
      {weeks?.map((item, idx) => (
        <Button
          key={item?.$d}
          onClick={() => handleClick(item, idx)}
          sx={{ marginRight: "5px" }}
          disabled={idx > currentWeek - 1}
          variant={
            idx === selected || idx === currentWeek - 1
              ? "contained"
              : "outlined"
          }
          title={item?.format("YYYY-MM-DD")}
          color={
            idx === selected
              ? "success"
              : idx === currentWeek - 1
              ? "primary"
              : "inherit"
          }
        >
          WK{idx + 1}
        </Button>
      ))}
    </div>
  );
}

export default React.memo(WeekTabs);
