import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
import { downloadBoxStyles } from "../data";
import DailySalesTotalRow from "./DailySalesTotalRow";
import DownloadCSVButton from "../DownloadCSVButton";
import { currencyFormater } from "../../../utils";

function ReportTable({ data }) {
  const thStyle = {
    fontWeight: 600,
  };

  return (
    <Box sx={{ padding: "10px 2px", marginBottom: "10px" }} component={Paper}>
      <Box mb={2} sx={downloadBoxStyles}>
        {data?.games?.length > 0 && <DownloadCSVButton data={data} />}
      </Box>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 300 }} aria-label="sales table">
          <TableHead>
            <TableRow>
              {data?.games?.map((item, idx) => (
                <TableCell key={`${idx}nn`} component="th" sx={thStyle}>
                  {item}
                </TableCell>
              ))}
              <TableCell component="th" sx={thStyle}>
                No. of Ticket
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.result?.length > 0 &&
              data?.result?.map((row, idx) => (
                <TableRow key={`${idx}eeee`}>
                  {/* <TableCell>{idx + 1}</TableCell> */}
                  {row?.gameName?.map((game, idx) => (
                    <TableCell>
                      <div>&#8358;{currencyFormater(game?.amount, 2)}</div>
                    </TableCell>
                  ))}
                  <TableCell>
                    <div>{currencyFormater(row?.totalticketCount)}</div>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && <DailySalesTotalRow data={data} />}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default ReportTable;
