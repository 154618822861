import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import bgImage from "../images/bgimage.jpg";
export default function NotFoundPage() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <AdminLayout>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          background: `linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(55,55,255,0.1)), url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Typography
          gutterBottom
          align="center"
          variant={matches ? "h4" : "h5"}
          sx={{ fontWeight: 600 }}
        >
          Oops, Page Not Found
        </Typography>
        <Typography gutterBottom align="center">
          The page you are requesting does not exist, if you think it was
          broken, contact the administrator
        </Typography>
      </Box>
    </AdminLayout>
  );
}
