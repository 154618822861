import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/material/Chip";
import {
  boldTextCalculationStyles,
  currencyFormater,
  getTransactionSum,
  menuItemsStyles,
} from "../../utils";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function PayoutsTable({
  totalCount,
  isFiltering,
  handleChangePage,
  page,
  data,
  hasFiltered,
  handleSelectTransaction,
  handleOpenReverseFundsDialog,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleOpenDialog,
  renderStatus,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event, payout) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(payout?.payoutId);
    handleSelectTransaction(payout);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "User ID",
    "Payout ID",
    "Amount",
    "Payout Attempt",
    "Ticket ID",
    "Redemption Method",
    "Date Created",
    "Date Paid",
    "Status",
    "Payment Status",
    "Actions",
  ];
  const headers = [
    "User ID",
    "Payout ID",
    "Amount",
    "Payout Attempt",
    "Ticket ID",
    "Redemption Method",
    "Date Created",
    "Date Paid",
    "Status",
    "Payment Status",
  ];
  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.userId,
        item?.payoutId,
        item?.amount,
        item?.attempts,
        item?.ticketId,
        item?.winningRedemptionMethod,
        item?.createdAt,
        item?.paymentDate,
        item?.status,
        item?.isPaid,
      ]);
    });
    rows = [headers, ...rows];
  }

  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="payouts.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.payoutId}>
                  <TableCell>{row?.userId}</TableCell>
                  <TableCell>{row?.payoutId}</TableCell>
                  <TableCell>
                    <Typography align="left">
                      NGN{currencyFormater(row?.amount)}
                    </Typography>
                  </TableCell>
                  <TableCell>{row?.attempts}</TableCell>
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>{row?.winningRedemptionMethod}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("hh:mm a, MMM D YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(parseInt(row?.paymentDate)).format(
                      "hh:mm a, MMM D YYYY"
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row?.status)}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row?.isPaid)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={menuItemsStyles}
                      >
                        Preview Payout
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={2}>
                  Total
                </TableCell>

                <TableCell align="right">
                  <NumberFormat
                    value={getTransactionSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default PayoutsTable;
