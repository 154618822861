import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import Typography from "@mui/material/Typography";

function SiteSettingsDetailsTable({ data }) {
  const headCells = ["Title", "Value", "Number", "Description"];
  let contents = [];
  try {
    contents = JSON.parse(data) || [];
  } catch (error) {
    console.log("error", error);
    contents = [];
  }

  return (contents &&
    contents?.length > 0 &&
    typeof contents[0] === "string") ||
    (contents && contents?.length > 0 && typeof contents[0] === "number") ? (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {contents &&
        contents?.length > 0 &&
        contents?.map((item, index) => (
          <Typography component="span" key={item}>
            {item}
            {index === contents?.length - 1 ? "." : ","} &nbsp;
          </Typography>
        ))}
    </Box>
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="site settings table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contents &&
              contents?.length > 0 &&
              contents?.map((row, index) => (
                <TableRow key={`${row?.name}l${index}a${row?.description}`}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.value}</TableCell>
                  <TableCell>{row?.number}</TableCell>
                  <TableCell>{row?.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {contents && contents?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default SiteSettingsDetailsTable;
