import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
} from "../../utils";
import { renderStatus } from "../../utils/statusChip";
import dayjs from "dayjs";

export default function PreviewPrizeModal({
  openDialog,
  handleCloseDialog,
  selectedPrize,
}) {
  const url = `${process.env.BASE_URL}sport/fetch-ticket-claim/${selectedPrize?.claimId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  // if (error)
  //   return (
  //     <Dialog
  //       open={openDialog}
  //       fullWidth={true}
  //       maxWidth={"md"}
  //       onClose={handleCloseDialog}
  //     >
  //       <DialogContent>
  //         <Box style={{ dialogLoaderStyles }}>
  //           <FullScreenError
  //             title=" Sorry, player's data cannot be retrieved at this moment"
  //             subtitle={error.message}
  //           />
  //         </Box>
  //       </DialogContent>
  //     </Dialog>
  //   );

  // if (!data)
  //   return (
  //     <Dialog open={true} fullWidth={true} maxWidth={"md"}>
  //       <DialogContent>
  //         <Box style={{ dialogLoaderStyles }}>
  //           <FullScreenLoader />
  //         </Box>
  //       </DialogContent>
  //     </Dialog>
  //   );

  // console.log("bbbbbbbbbbbbbbb", selectedPrize);
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          PRIZE PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Player Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {`${selectedPrize?.SportTicket?.User?.firstname} ${selectedPrize?.SportTicket?.User?.lastname}`}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption"> Last Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedPrize?.SportTicket?.User?.role}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Email:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedPrize?.SportTicket?.User?.email}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Phone Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedPrize?.SportTicket?.User?.phone}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Player Status:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedPrize?.SportTicket?.User?.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip label="Not Active" color="error" size="small" />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Ticket Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Ticket ID:</Typography>
                    <Typography sx={dialogMainTextStyles} align="left">
                      {selectedPrize?.ticketId}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Staked Amount:</Typography>
                    <Typography sx={dialogMainTextStyles} align="left">
                      NGN
                      {currencyFormater(
                        selectedPrize?.SportTicket?.totalStakedAmount
                      )}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Won Amount:</Typography>
                    <Typography sx={dialogMainTextStyles} align="left">
                      NGN
                      {currencyFormater(
                        selectedPrize?.SportTicket?.totalWonAmount
                      )}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Status:</Typography>
                    {renderStatus(selectedPrize?.SportTicket?.status)}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Product Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedPrize?.SportTicket?.Product?.title}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <img
                      src={selectedPrize?.SportTicket?.Product?.imageUrl}
                      alt={selectedPrize?.SportTicket?.Product?.title}
                      width={70}
                      height={70}
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                    />
                  </Box>
                  {selectedPrize?.approvalDate && (
                    <>
                      <Box my={1}>
                        <Typography sx={dialogMainTextStyles}>
                          Date Approved:
                        </Typography>
                        {dayjs(selectedPrize?.approvalDate).format(
                          "DD MMM YYYY, HH:mma"
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box my={1}>
              <Typography variant="caption">Product Description:</Typography>
              <Typography paragraph>
                {selectedPrize?.SportTicket?.Product?.description}{" "}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
