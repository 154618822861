import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  safeJSONParse,
} from "../../utils";
import dayjs from "dayjs";

export default function PreviewLogModal({
  openDialog,
  handleCloseDialog,
  selectedLog,
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="primary" sx={previewDialogMainTitleStyle}>
          LOG PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box my={1}>
              {/* <Typography gutterBottom sx={previewSubTitleStyles}>
                    Player Information
                  </Typography> */}

              <Box my={1}>
                <Typography variant="caption">Admin Name:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {selectedLog?.name}{" "}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Admin ID:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {selectedLog?.adminId}{" "}
                </Typography>
              </Box>
              {safeJSONParse(selectedLog?.action)?.actionName ? (
                <Box my={1}>
                  <Typography variant="caption">Activity:</Typography>
                  <Typography sx={dialogMainTextStyles}>
                    {safeJSONParse(selectedLog?.action)?.actionName}
                  </Typography>
                </Box>
              ) : null}
              <Box my={1}>
                <Typography variant="caption">Activity Date:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {dayjs(selectedLog?.date).format("MMM, D YYYY hh:mm A")}
                </Typography>
              </Box>
              {safeJSONParse(selectedLog?.action)?.actionCategory ? (
                <Box my={1}>
                  <Typography variant="caption">Action Category:</Typography>
                  <Typography sx={dialogMainTextStyles}>
                    {safeJSONParse(selectedLog?.action)?.actionCategory}
                  </Typography>
                </Box>
              ) : null}
              <Box my={1}>
                <Typography variant="caption">Request Method:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {selectedLog?.requestMethod}{" "}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Action:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {selectedLog?.actionString}{" "}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Action Category:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {JSON.parse(selectedLog?.action)?.actionCategory}{" "}
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
