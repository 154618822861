import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import { tableButtonStyles } from "../../utils";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function ApprovedSportsResultsTable({
  data,
  page,
  selectedEvent,
  rowsPerPageOptions,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleSelectCategory,
  handleChangePage,
  totalCount,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    handleSelectCategory(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToEditGamePage = () => {
    navigate(`/edit-result-details/${selectedEvent?.resultId}`);
  };
  const moveToSeeMorePage = () => {
    navigate(`/sports-bet/result-details/${selectedEvent?.resultId}`);
  };

  const headCells = [
    "Name",
    "Home Score",
    "Away Score",
    "ID",
    "Week",
    "Approval",
    "Date Created",
    "Status",
    // "Actions",
  ];
  const headers = [
    "Name",
    "Lottery",
    "Weekday",
    "Start Time",
    "End Time",
    "Date Created",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.name,
        item?.lotteryName,
        item?.weekday,
        item?.startTime,
        item?.endTime,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.eventId}>
                  <TableCell>{row?.drawName}</TableCell>
                  <TableCell>{row?.homeScore}</TableCell>
                  <TableCell>{row?.awayScore}</TableCell>
                  <TableCell>{row?.id}</TableCell>
                  <TableCell>{row?.Event?.week}</TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.isApproved ? (
                      <Chip color="success" label="Approved" size="small" />
                    ) : (
                      <Chip label="Not Approved" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default ApprovedSportsResultsTable;
