import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import { menuItemsStyles } from "../../utils";

function BannerTable({
  totalCount,
  selectedBanner,
  rowsPerPage,
  rowsPerPageOptions,
  isFiltering,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  data,
  handleSelectBanner,
  handleOpenDialog,
}) {
  const [selectedId, setSelectedId] = useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event, banner) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(banner?.bannerId);
    handleSelectBanner(banner);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToBannerEditPage = () => {
    navigate(`edit-banner/${selectedId}`);
  };

  const renderStatusButton = () => {
    if (selectedBanner.status) {
      return "Deactivate";
    } else {
      return "Activate";
    }
  };
  const headCells = [
    "Background Image",
    "Foreground Image",
    "Title",
    "Category",
    "Content",
    "Date Created",
    "Status",
    "Actions",
  ];

  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="recent users table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.bannerId}>
                  <TableCell>
                    <img
                      src={`${row.imageUrl}`}
                      alt="banner image"
                      width="85px"
                      height={"85px"}
                      style={{ objectFit: "contain" }}
                    />
                  </TableCell>
                  <TableCell>
                    {row?.foregroundImage ? (
                      <img
                        src={`${row.foregroundImage}`}
                        alt="banner image"
                        width="85px"
                        height={"85px"}
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      <Typography align="center">N/A</Typography>
                    )}
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.content}</TableCell>

                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => moveToBannerEditPage()}
                        sx={menuItemsStyles}
                      >
                        Edit
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={menuItemsStyles}
                      >
                        {renderStatusButton(row?.status)}
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default BannerTable;
