import React, { useState } from "react";
// import Grid from "@mui/material/Grid";
// import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
} from "../../../utils";
import HalfErrorScreen from "../../HalfErrorScreen/HalfErrorScreen";
import ReportTable from "../DailyReports/ReportTable";
import TableLoader from "../../TableLoader/TableLoader";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear"; // dependent on weekOfYear plugin
import weekOfYear from "dayjs/plugin/weekOfYear";
import "../playerreports.css";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs().weekYear();
dayjs.extend(weekday);

let weeks = [];

const currentWeek = dayjs().weeks();

for (let index = 0; index < currentWeek; index++) {
  weeks.push(index + 1);
}

const WeeklySalesView = ({ value }) => {
  const [selectedWeek, setSelectedWeek] = useState(dayjs().weeks());
  const url = `${
    process.env.BASE_URL
  }admin/fetch-specific-player-report?interval=weekly&specificDate=week-${selectedWeek}-${dayjs().year()}`;
  const handleChange = (value) => {
    setSelectedWeek((prev) => value);
  };

  const TabsSection = () => {
    return (
      <div className="weeksCont">
        {weeks.map((item, idx) => (
          <Button
            onClick={() => handleChange(item)}
            key={idx}
            variant={item === selectedWeek ? "contained" : "text"}
          >
            {`WK${item}`}
          </Button>
        ))}
      </div>
    );
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (typeof data === "boolean" && data === false)
    return (
      <>
        <TabsSection />
        <NoDataAvailable />;
      </>
    );

  if (!data) return <TableLoader />;

  let stakedAmount, winnings;
  let dataSummary = {};
  // console.log("data", data);
  stakedAmount = getSumFromObj(data?.totalStakedAmount);
  winnings = getSumFromObj(data?.totalWinAmount);
  // console.log("winnings", winnings);

  dataSummary["staked amount"] = stakedAmount;
  dataSummary.winnings = winnings;

  return (
    <>
      <TabsSection />
      <br />
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <ReportTable data={data} value={value} />
    </>
  );
};

export default WeeklySalesView;
