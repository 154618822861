import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  previewDialogMainTitleStyle,
  INFLUENCER,
  formatErrorMessage,
} from "../../utils";
import FormLoader from "../FormLoader/FormLoader";
import { AppContext } from "../../context";

export default function AttachInfluencerModal({
  openDialog,
  handleCloseDialog,
  selectedBonus,
  dialogTitle = "ATTACH BUNDLE TO INFLUENCER",
  showQuantityInput = true,
}) {
  const [searchField, setSearchField] = useState("");
  const [nameObj, setNameObj] = useState("");
  const [namesArray, setNamesArray] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSearchName, setHasSearchName] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);

  const url = `${process.env.BASE_URL}user/fetch-users/?role=${INFLUENCER}&search=${searchField}`;

  const handleFetchUser = async () => {
    try {
      setIsFetching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNamesArray((curr) => data?.data?.data);
      setIsFetching(false);
      setHasSearchName(true);
      // console.log("result", data?.data);
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      checkExpiredAuthToken();
      const payload = {
        userId: nameObj?.userId,
        bonusId: selectedBonus?.bonusId,
        quantity: quantity,
      };
      const token = getAuthToken();
      const { data } = await axios.put(
        `${process.env.BASE_URL}admin/assign-bonus-to-influencer`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsSubmitting(false);
      setSearchField("");
      setNamesArray([]);
      setHasSearchName(false);
      setQuantity(1);
      setNameObj({});
      // console.log("result", data?.data);

      handleOpenSnackbar({
        message: "Bundle Attached Successfully",
        severity: "success",
      });
      handleCloseDialog();
    } catch (err) {
      setIsSubmitting(false);
      const errMsg = formatErrorMessage(err);

      handleOpenSnackbar({
        message: errMsg,
        severity: "error",
      });
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Box
            my={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <FormControl variant="outlined" fullWidth>
              <TextField
                size="small"
                label="Search Influencer By Name"
                value={searchField}
                name="search"
                variant="outlined"
                fullWidth
                onChange={(e) => setSearchField(e.target.value)}
              />
            </FormControl>
            <Button
              variant="contained"
              type="button"
              disabled={!searchField}
              onClick={() => handleFetchUser()}
            >
              Search
            </Button>
          </Box>
          <> {isFetching ? <FormLoader /> : null}</>
          <>
            {hasSearchName ? (
              <Box my={2}>
                <Typography color="InfoText" gutterBottom>
                  Search Results:
                </Typography>
                {namesArray && namesArray?.length > 0 ? (
                  <Grid container spacing={1}>
                    {namesArray.map((item, idx) => (
                      <Grid key={item?.userId} item xs={6} sm={6} md={4} lg={3}>
                        <Chip
                          label={`${item?.firstname} ${item?.lastname}`}
                          color={
                            nameObj?.userId === item?.userId
                              ? "success"
                              : "default"
                          }
                          variant={
                            nameObj?.userId === item?.userId
                              ? "filled"
                              : "outlined"
                          }
                          onClick={() => {
                            setNameObj(item);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography>
                    Sorry, No Influencer Name Match Your Search...
                  </Typography>
                )}
              </Box>
            ) : null}
          </>
          <br />
          {showQuantityInput ? (
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  size="small"
                  label="Quantity To Attach"
                  type="number"
                  value={quantity}
                  name="quantity"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </FormControl>
            </Box>
          ) : null}
          <br />
          <Button
            variant="contained"
            disabled={isSubmitting || !nameObj?.userId}
            type="button"
            onClick={() => handleSubmit()}
          >
            {isSubmitting ? "Submitting" : "Submit"}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
