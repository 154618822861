import React, { useState } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import GamesTable from "../components/GameInstanceComponents/GameInstanceTable";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ActivateGameInstanceDialog from "../components/GameInstanceComponents/ActivateGameInstanceDialog";
import DeactivateGameInstanceDialog from "../components/GameInstanceComponents/DeactivateGameInstanceDialog";
import PreviewGameInstanceModal from "../components/GameInstanceComponents/PreviewGameInstanceModal";
import GamesInstanceFilter from "../components/GameInstanceComponents/GameInstanceFilter";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import UploadDrawDialog from "../components/GameInstanceComponents/UploadDrawDialog";
import DrawHistoryModal from "../components/GameInstanceComponents/DrawHistoryModal";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import GameInstanceAnalytics from "../components/GameInstanceComponents/GameInstanceAnalytics";
import GameForecastModal from "../components/GameInstanceComponents/GameForecastModal";
import GameStatisticsModal from "../components/GameInstanceComponents/GameStatisticsModal";
import dayjs from "dayjs";

function GameInstances() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [status, setStatus] = useState("");
  const [gameTitle, setGameTitle] = useState("");
  const [currentWeekDay, setCurrentWeekDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openDrawDialog, setOpenDrawDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openPreviewGameDialog, setOpenPreviewGameDialog] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openForecastModal, setOpenForecastModal] = useState(false);
  const [openStatisticsModal, setOpenStatisticsModal] = useState(false);
  const [selectedGame, setSelectedGame] = React.useState({});

  const url = `${process.env.BASE_URL}game/fetch-games?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}game/fetch-games?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations
  let sStatus = "status";
  let sgameTitle = "gameTitle";
  let sStartTime = "startTime";
  let sEndTime = "endTime";
  let sCurrentWeekDay = "currentWeekDay";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeGameTitle = (e) => {
    setGameTitle(e.target.value);
    sessionStorage.setItem(sgameTitle, e.target.value);
  };

  const handleChangeStartTime = (value) => {
    setStartTime(value);
    sessionStorage.setItem(sStartTime, value);
  };

  const handleChangeEndTime = (value) => {
    setEndTime(value);
    sessionStorage.setItem(sEndTime, value);
  };

  const handleChangeWeekDay = (e) => {
    setCurrentWeekDay(e.target.value);
    sessionStorage.setItem(sCurrentWeekDay, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sgameTitle);
    sessionStorage.removeItem(sStartTime);
    sessionStorage.removeItem(sEndTime);
    sessionStorage.removeItem(sCurrentWeekDay);
  };

  const handleResetFields = () => {
    setGameTitle("");
    setStatus("");
    setStartTime(null);
    setEndTime(null);
    setCurrentWeekDay(null);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }
    let sSgameTitle = sessionStorage.getItem(sgameTitle);
    if (sSgameTitle) {
      setGameTitle(sSgameTitle);
      setHasFiltered(true);
    }

    let sSStartTime = sessionStorage.getItem(sStartTime);
    if (sSStartTime) {
      setStartTime(sSStartTime);
      setHasFiltered(true);
    }
    let sSEndTime = sessionStorage.getItem(sEndTime);
    if (sSEndTime) {
      setEndTime(sSEndTime);
      setHasFiltered(true);
    }

    let sSCurrentWeekDay = sessionStorage.getItem(sCurrentWeekDay);
    if (currentWeekDay && currentWeekDay !== "" && currentWeekDay !== null) {
      setCurrentWeekDay(sSCurrentWeekDay);
      setHasFiltered(true);
    }

    if (gameTitle !== "") {
      if (!filterURL.includes(sgameTitle)) {
        filterURL = filterURL + `&search=${gameTitle}`;
      }
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (currentWeekDay !== "" && currentWeekDay !== null) {
      if (!filterURL.includes(sCurrentWeekDay)) {
        filterURL = filterURL + `&currentWeekDay=${currentWeekDay}`;
      }
    }
    if (startTime) {
      if (!filterURL.includes(sStartTime)) {
        filterURL =
          filterURL + `&startTime=${dayjs(startTime).format("HH:mm")}`;
      }
    }
    if (startTime) {
      if (!filterURL.includes(sEndTime)) {
        filterURL = filterURL + `&endTime=${dayjs(endTime).format("HH:mm")}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectGame = (game) => setSelectedGame(game);

  const handleOpenDeactivateGameDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateGameDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenActivateGameDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateGameDialog = () => {
    setOpenActivateDialog(false);
  };

  const handleOpenPreviewGameDialog = () => {
    setOpenPreviewGameDialog(true);
    setOpenActivateDialog(false);
  };
  const handleClosePreviewGameDialog = () => {
    setOpenPreviewGameDialog(false);
  };
  const handleOpenDrawDialog = () => {
    setOpenDrawDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDrawDialog = () => {
    setOpenDrawDialog(false);
  };
  const handleOpenHistoryModal = () => {
    setOpenHistoryModal(true);
    setOpenActivateDialog(false);
  };
  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
  };

  const handleOpenForecastModal = () => {
    setOpenForecastModal(true);
  };
  const handleCloseForecastModal = () => {
    setOpenForecastModal(false);
  };

  const handleOpenStatisticsModal = () => {
    setOpenStatisticsModal(true);
  };
  const handleCloseStatisticsModal = () => {
    setOpenStatisticsModal(false);
  };

  const handleSubmitDeactivateGame = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      gameId: selectedGame?.gameId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/toggle-game-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Game have Been Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateGameDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Game Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleSubmitActivateGame = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: true,
      gameId: selectedGame?.gameId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/toggle-game-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Game Have Been Successfully Activated",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateGameDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Game Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve games data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Games Instances Table - Admin Dashboard SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Games Instances" />
        <GameInstanceAnalytics />
        {openStatisticsModal ? (
          <GameStatisticsModal
            openDialog={openStatisticsModal}
            selectedGame={selectedGame}
            handleCloseDialog={handleCloseStatisticsModal}
          />
        ) : null}
        {openForecastModal ? (
          <GameForecastModal
            openDialog={openForecastModal}
            selectedGame={selectedGame}
            handleCloseDialog={handleCloseForecastModal}
          />
        ) : null}
        {openHistoryModal ? (
          <DrawHistoryModal
            openDialog={openHistoryModal}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseHistoryModal}
          />
        ) : null}
        {openActivateDialog ? (
          <ActivateGameInstanceDialog
            openDialog={openActivateDialog}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseActivateGameDialog}
            handleSubmit={handleSubmitActivateGame}
          />
        ) : null}
        {openDrawDialog ? (
          <UploadDrawDialog
            openDialog={openDrawDialog}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDrawDialog}
            handleSubmit={handleSubmitActivateGame}
          />
        ) : null}

        {openDeactivateDialog ? (
          <DeactivateGameInstanceDialog
            openDialog={openDeactivateDialog}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateGameDialog}
            handleSubmit={handleSubmitDeactivateGame}
          />
        ) : null}
        {openPreviewGameDialog ? (
          <PreviewGameInstanceModal
            openDialog={openPreviewGameDialog}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleClosePreviewGameDialog}
          />
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <GamesInstanceFilter
            gameTitle={gameTitle}
            status={status}
            startTime={startTime}
            endTime={endTime}
            weekday={currentWeekDay}
            handleChangeStatus={handleChangeStatus}
            handleChangeGameTitle={handleChangeGameTitle}
            handleChangeStartTime={handleChangeStartTime}
            handleChangeEndTime={handleChangeEndTime}
            handleChangeWeekday={handleChangeWeekDay}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        <GamesTable
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedGame={selectedGame}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpenStatisticsModal={handleOpenStatisticsModal}
          handleOpenForecastModal={handleOpenForecastModal}
          handleOpenDrawDialog={handleOpenDrawDialog}
          handleChangePage={handleChangePage}
          handleSelectGame={handleSelectGame}
          handleOpenHistoryModal={handleOpenHistoryModal}
          handleOpenActivateGameDialog={handleOpenActivateGameDialog}
          handleOpenDeactivateGameDialog={handleOpenDeactivateGameDialog}
          handleOpenPreviewGameDialog={handleOpenPreviewGameDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default GameInstances;
