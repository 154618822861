import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import NoDataAvailable from "../EmptyTable/EmptyTable";
import { currencyFormater } from "../../utils";

function AnalysisSummary({ data }) {
  const headCells = ["Bet type", "Count", "Total Won"];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Total Sales
                </Typography>
                <Typography variant="h6" gutterBottom>
                  &#8358;{currencyFormater(data?.totalStaked)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  RTP(%)
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {currencyFormater(data?.totalWon / data?.totalStaked, 3)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  No. of Winners
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {currencyFormater(data?.totalWinners)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.betTypeTotals?.length > 0 &&
              data?.betTypeTotals?.map((row) => (
                <TableRow key={row?.betType}>
                  <TableCell>{row?.betType}</TableCell>
                  <TableCell>{row?.count}</TableCell>

                  <TableCell>
                    {" "}
                    &#8358;{currencyFormater(row.total, 2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default AnalysisSummary;
