import React from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadCSVButton = ({ data }) => {
  const handleDownloadCSV = () => {
    let csvContent;

    const headers = [...data?.games, "No. of Ticket"];
    let rows = [];

    data?.result?.forEach((item, idx) => {
      rows.push([
        ...item?.gameName?.map((game) => game?.amount),
        item?.totalticketCount,
      ]);
    });

    rows = [headers, ...rows];
    csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };
  return (
    <Button
      startIcon={<DownloadIcon />}
      size="small"
      variant="outlined"
      onClick={() => handleDownloadCSV()}
    >
      download csv
    </Button>
  );
};

export default DownloadCSVButton;
