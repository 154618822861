import * as React from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  singleDetailsBoxStyle3,
  BUNDLE_BONUS,
  currencyFormater,
  formatBonusType,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import BundleTransactions from "../components/BundleDetailsComponents/BundleTransactions";

function BundleBonusDetails() {
  const { bonusId } = useParams();

  const url = `${process.env.BASE_URL}bonus/fetch-bonus/${bonusId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator
        title={`${data && data?.title} bonus
         details - Admin`}
      />
      <Container maxWidth="xl">
        <PageHeading title="Bundle Bonus Details" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box py={2}>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Bonus Title:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.title}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Bonus ID:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.bonusId}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Bet Type:</Typography>
                <Typography align="left">{data && data?.betType}</Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Game Type:</Typography>
                <Typography align="left">{data && data?.gameType}</Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Prize:</Typography>
                <Typography align="left">
                  <NumberFormat
                    value={data?.prize}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography align="left" {...props}>
                        {value}
                      </Typography>
                    )}
                  />
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Game Play Count:</Typography>
                <Typography align="left">
                  {data && data?.gamePlayCount}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Commission:</Typography>
                <Typography align="left">
                  {data && data?.commission}%
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Win Count:</Typography>
                <Typography align="left">{data && data?.winCount}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">
                  Bonus Expiration In Days:
                </Typography>
                <Typography align="left">{data?.expiration} days</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Bonus Type:</Typography>
                <Typography align="left">
                  {formatBonusType(data?.type)}
                </Typography>
              </Box>
              {data?.type === BUNDLE_BONUS ? (
                <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                  <Typography color="text.secondary">Quantity:</Typography>
                  <Typography align="left">{data?.quantity}</Typography>
                </Box>
              ) : null}
              {data?.type === BUNDLE_BONUS ? (
                <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                  <Typography color="text.secondary">Unit Cost:</Typography>
                  <Typography align="left">
                    NGN{currencyFormater(data?.unitCost)}
                  </Typography>
                </Box>
              ) : null}
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Date Created:</Typography>
                <Typography align="left">
                  {dayjs(data?.createdAt).format("DD, MMM YYYY hh:ss a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">
                  Date Last Updated:
                </Typography>
                <Typography align="left">
                  {dayjs(data?.updatedAt).format("DD, MMM YYYY hh:ss a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Status:</Typography>
                {data?.status ? (
                  <Chip color="success" label="Active" size="small" />
                ) : (
                  <Chip label="Not Active" color="error" size="small" />
                )}
              </Box>
              <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle3}>
                <Typography color="text.secondary">Description:</Typography>
                <Typography>{data?.description}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Typography gutterBottom variant="h6">
            Bundle Transactions
          </Typography>
          <BundleTransactions />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default BundleBonusDetails;
