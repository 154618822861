import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import TicketInformation from "../components/DealDetailsComponents/TicketInformation";
import ProductInformation from "../components/DealDetailsComponents/ProductInformation";
import TicketPlayerInformation from "../components/DealDetailsComponents/TicketUserInformation";
import DealEventsTable from "../components/DealDetailsComponents/DealEventsTable";
// import BookingCodeUsersTable from "../components/BookedDealDetailsComponents/BookingCodeUsersTable";

function DealDetails() {
  const { ticketId, bookingCode } = useParams();

  const url = `${process.env.BASE_URL}ticket/fetch-ticket/${ticketId}`;
  // const potentialURL = `${process.env.BASE_URL}game/ticket/get-potential-winning`;
  const bookingCodeUsersURL = `${process.env.BASE_URL}game/fetch-booking-code-users/${bookingCode}`;

  const fetcher = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let result = {};
    let ticketData = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let req = {
      category: ticketData?.data?.data?.data?.Game?.Lottery?.category,
      betSlips: ticketData?.data?.data?.data?.betSlips,
    };

    // let potentialData = await axios.post(potentialURL, req, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    result.ticketData = ticketData?.data?.data?.data;
    // result.potentialWinningData = potentialData?.data?.data?.data;
    // result.bookingCodeUsers = potentialData?.data?.data?.data;

    return result;
  };
  const itemBoxStyle = {
    maxWidth: "600px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
  };

  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  console.log("ssssssssssssss", data);
  return (
    <AdminLayout>
      <MetaDecorator title={`Code Ticket ${ticketId} details - Admin `} />
      <Container maxWidth="xl">
        <PageHeading title="Deal Details" />
        <Grid container spacing={2}>
          <TicketInformation
            data={data?.ticketData}
            itemBoxStyle={itemBoxStyle}
            potentialWinningData={data?.potentialWinningData}
          />
          <ProductInformation
            data={data?.ticketData}
            itemBoxStyle={itemBoxStyle}
          />
        </Grid>
        <Grid container spacing={2}>
          <TicketPlayerInformation
            data={data?.ticketData?.User}
            itemBoxStyle={itemBoxStyle}
          />
        </Grid>

        <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Events
          </Typography>
          <DealEventsTable
            data={data?.ticketData?.BetSlips}
            totalStakedAmount={data?.ticketData?.totalStakedAmount}
          />
        </Box>
        {/* <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Booking Code Users Information
          </Typography>
          <BookingCodeUsersTable bookingCode={bookingCode} />
        </Box> */}
      </Container>
    </AdminLayout>
  );
}

export default DealDetails;
