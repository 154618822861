import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

export default function PreviewPlayerModal({
  openDialog,
  handleCloseDialog,
  selectedUser,
}) {
  const url = `${process.env.BASE_URL}user/fetch-user/${selectedUser?.userId}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, player's data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          PLAYER PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Player Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">First Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.firstname}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption"> Last Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.lastname}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Email:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.email}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Phone Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.phone}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Player Status:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip label="Not Active" color="error" size="small" />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Account Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">
                      Commission Balance:
                    </Typography>

                    <Typography sx={dialogMainTextStyles} align="left">
                      NGN{currencyFormater(data?.commissionBalance)}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Wallet Balance:</Typography>

                    <Typography sx={dialogMainTextStyles} align="left">
                      NGN{currencyFormater(data?.walletBalance)}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Account Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.accountName}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Account Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.accountNumber}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Bank Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.bankName}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Bank Code:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.bankCode}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
