import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWRImmutable from "swr/immutable";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";

function StaticPageDetails() {
  const { contentId } = useParams();

  const url = `${process.env.BASE_URL}content/fetch-content/${contentId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWRImmutable(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`Static Page Details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Static Page Details" />
        <Box>
          {/* <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Page Information
          </Typography> */}
          <Box py={2}>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Title:
              </Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.title}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Description:
              </Typography>
              <Typography align="left">{data && data?.description}</Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: "block" }}
              >
                Status:
              </Typography>
              {data?.status ? (
                <Chip color="success" label="Active" size="small" />
              ) : (
                <Chip label="Not Active" color="error" size="small" />
              )}
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Created By:
              </Typography>
              <Typography align="left">
                {data?.createdBy ? data?.createdBy : "Not Available"}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Updated By:
              </Typography>
              <Typography align="left">
                {data?.updatedBy ? data?.updatedBy : "Not Available"}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Date Created:
              </Typography>
              <Typography align="left">
                {dayjs(data?.createdAt).format("DD MMMM YYYY, HH:mm a ")}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography variant="caption" color="text.secondary">
                Date Last Updated:
              </Typography>
              <Typography align="left">
                {dayjs(data?.updatedAt).format("DD MMMM YYYY,  HH:mm a")}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2}>
              <Typography gutterBottom variant="caption" color="text.secondary">
                Content:
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            </Box>
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default StaticPageDetails;
