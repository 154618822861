import React, { useState, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Files from "react-files";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { AppContext } from "../../context";
import {
  GHANA_GAME,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_V2,
  GHANA_GAME_V2_BETOPTIONS,
  WESTERN_INDOOR_GAME,
  WESTERN_INDOOR_GAME_BETOPTIONS,
  WESTERN_INDOOR_GAME_V2,
  WESTERN_INDOOR_GAME_V2_BETOPTIONS,
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  lottoProductCategoryArray,
  safeJSONParse,
} from "../../utils";
import "../../styles/AddProductCategory.css";
import FormLoader from "../../components/FormLoader/FormLoader";

const initialValues = {
  title: "",
  customProductId: "",
  imageUrl: "",
  betType: "",
  gameId: "",
  cashEquivalence: 1,
  fixedStakedAmount: 10,
  category: "",
  description: "",
};
function AddLottoProduct({ categories }) {
  const [productImage, setProductImage] = useState("");
  const [gameName, setGameName] = useState("");
  const [searchedGames, setSearchedGames] = useState([]);
  const [isSearchingGame, setIsSearchingGame] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedGame, setSelectedGame] = useState(false);
  const [loadingBetOption, setLoadingBetOption] = useState(false);
  const [betTypeSettings, setBetTypeSettings] = useState(null);
  const [betOption, setBetOption] = useState([]);
  const [gameAllowedOptions, setGameAllowedOptions] = useState([]);
  const { handleOpenSnackbar } = useContext(AppContext);
  const btnRef = useRef();
  const gameUrl = `${process.env.BASE_URL}game/fetch-games?limit=25&page=1&search=${gameName}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values, helpers) => {
      //  console.log("vvvvvvvvvvvvv", values);
      const found = betOption?.find((item) => item?.value === values?.betType);
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const foreground = await handleSubmitImage(
        productImage,
        "Sorry, an error occured while uploading the category icon image"
      );

      let newValues = {
        title: values.title,
        customProductId: values.customProductId,
        cashEquivalence: values.cashEquivalence,
        fixedStakedAmount: values.fixedStakedAmount,
        category: values.category,
        description: values.description,
        betType: values.betType,
        gameId: values.gameId,
        betTypeSettings: JSON.stringify(found || ""),
      };

      newValues.imageUrl = foreground;
      setBetTypeSettings();
      axios
        .post(`${process.env.BASE_URL}lotto-product/new`, newValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          setProductImage("");
          setBetOption([]);
          setSearchedGames([]);
          setHasSearchedGames(false);
          helpers.resetForm();
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            // return setTimeout(() => {
            //   window.location.reload();
            // }, 1500);
          }
          handleOpenSnackbar({
            message: "Product Created Successfully",
            severity: "success",
          });
          // return setTimeout(() => {
          //   window.location.reload();
          // }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Product Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      customProductId: yup.string().required().label("Product ID"),
      category: yup.string().required().label("Category"),
      description: yup.string().required().label("Description"),
      fixedStakedAmount: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Fixed Staked Amount"),
      cashEquivalence: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Cash Equivalence"),
    }),
  });

  const formPaperStyle = { padding: 3 };
  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const handleChangeImage = (files) => {
    setProductImage((curr) => files[0]);
  };

  const handleErrorImage = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleSearchGames = async () => {
    try {
      setIsSearchingGame(true);
      setBetOption([]);
      const token = getAuthToken();
      const res = await axios.get(gameUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSearchedGames(res?.data?.data?.data);
    } catch (err) {
      if (err?.response?.data?.responsemessage?.msg) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.msg,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      return handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    } finally {
      setIsSearchingGame(false);
      setHasSearchedGames(true);
    }
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOptions = async () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    const parsed = safeJSONParse(selectedGame?.Lottery?.betOptions);
    setGameAllowedOptions(parsed);
    if (selectedGame?.Lottery?.category === "meta4") {
      return await getBetOption("meta4-bet-options");
    } else if (selectedGame?.Lottery?.category === "cash-out") {
      return await getBetOption("cash-out-bet-options");
    } else if (selectedGame?.Lottery?.category === "eazy-win") {
      return await getBetOption("eazy-win-bet-options");
    } else if (selectedGame?.Lottery?.category === "perfect-balls") {
      return await getBetOption("perfect-balls-bet-options");
    } else if (selectedGame?.Lottery?.category === "lotto") {
      return await getBetOption("lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === "metro") {
      return await getBetOption("metro-bet-options");
    } else if (selectedGame?.Lottery?.category === GHANA_GAME) {
      return await getBetOption(GHANA_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME) {
      return await getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === GHANA_GAME_V2) {
      return await getBetOption(GHANA_GAME_V2_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME_V2) {
      return await getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    }
  };

  return (
    <div>
      <button
        style={{ display: "none" }}
        onClick={() => getOptions()}
        ref={btnRef}
      >
        Get Bet Options
      </button>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Paper sx={formPaperStyle}>
          <Box my={2}>
            <FormControl fullWidth>
              <FormLabel sx={labelStyles}>
                Select Display Image for Product
              </FormLabel>
              <br />
              <Files
                className="dropzone"
                onChange={handleChangeImage}
                onError={handleErrorImage}
                accepts={["image/*"]}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <Typography gutterBottom align="center">
                  Drop file here or click to upload image of product
                </Typography>
                {productImage ? (
                  <Box>
                    <img
                      src={URL.createObjectURL(productImage)}
                      className="imagePreview"
                      alt="category image"
                    />
                  </Box>
                ) : null}
              </Files>
            </FormControl>
          </Box>
          <Box my={1}>
            <FormControl
              variant="outlined"
              fullWidth
              error={formik.touched.category && formik.errors.category}
            >
              <FormLabel variant="outlined">Select Category</FormLabel>
              <Select
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
              >
                {lottoProductCategoryArray?.map((item, idx) => (
                  <MenuItem
                    key={`1552t2${idx}${item?.category}`}
                    value={item?.value}
                  >
                    {item?.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.category && formik.errors.category}
              </FormHelperText>
            </FormControl>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Name of Product"
                    error={formik.touched.title && formik.errors.title}
                    name="title"
                    variant="outlined"
                    value={formik.values.title}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="ID of Product"
                    error={
                      formik.touched.customProductId &&
                      formik.errors.customProductId
                    }
                    name="customProductId"
                    variant="outlined"
                    value={formik.values.customProductId}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.customProductId &&
                      formik.errors.customProductId
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Fixed Staked Amount"
                    error={
                      formik.touched.fixedStakedAmount &&
                      formik.errors.fixedStakedAmount
                    }
                    name="fixedStakedAmount"
                    type="number"
                    variant="outlined"
                    value={formik.values.fixedStakedAmount}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.fixedStakedAmount &&
                      formik.errors.fixedStakedAmount
                    }
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Cash Equivalence of Product"
                    error={
                      formik.touched.cashEquivalence &&
                      formik.errors.cashEquivalence
                    }
                    name="cashEquivalence"
                    type="number"
                    variant="outlined"
                    value={formik.values.cashEquivalence}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.cashEquivalence &&
                      formik.errors.cashEquivalence
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Description of Product"
                error={formik.touched.description && formik.errors.description}
                name="description"
                variant="outlined"
                multiline
                value={formik.values.description}
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </FormControl>
          </Box>
        </Paper>

        {/* <Paper sx={formPaperStyle}>
          <Box my={2}>
            <Typography variant="h6" gutterBottom fontWeight={500}>
              Cuts
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 1"
                      name="cut1"
                      variant="outlined"
                      value={formik.values.cut1}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 2"
                      name="cut2"
                      variant="outlined"
                      value={formik.values.cut2}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 3"
                      name="cut3"
                      variant="outlined"
                      value={formik.values.cut3}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 4"
                      name="cut4"
                      variant="outlined"
                      value={formik.values.cut4}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 5"
                      name="cut5"
                      variant="outlined"
                      value={formik.values.cut5}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Cut 6"
                      name="cut6"
                      variant="outlined"
                      value={formik.values.cut6}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper> */}
        <br />
        <Paper sx={formPaperStyle}>
          <Box my={1}>
            <Typography variant="h6">Game Information</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={9} md={10}>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Enter the name of Game"
                    name="gameName"
                    variant="outlined"
                    value={gameName}
                    fullWidth
                    onChange={(e) => setGameName(e.target.value)}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4} sm={3} md={2} alignSelf={"center"}>
              <Button
                type="button"
                variant="contained"
                onClick={() => handleSearchGames()}
                disabled={isSearchingGame}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Box my={1}>
            {isSearchingGame ? (
              <FormLoader />
            ) : !isSearchingGame && !hasSearchedGames ? null : (
              <Box>
                {searchedGames &&
                searchedGames?.length > 0 &&
                hasSearchedGames ? (
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStylesTwo}>
                        Games
                      </InputLabel>
                      <br />
                      <RadioGroup aria-label="Games" row name="searchedGamess">
                        {searchedGames &&
                          searchedGames?.length > 0 &&
                          searchedGames?.map((item, idx) => (
                            <FormControlLabel
                              key={`${item?.name}${idx}`}
                              value={item.gameId}
                              label={item?.name}
                              variant="outlined"
                              onChange={async (e) => {
                                setSelectedGame((curr) => item);
                                formik.setFieldValue("gameId", e.target.value);
                                formik.setFieldValue("betType", "");
                                setTimeout(() => {
                                  return btnRef.current.click();
                                }, 700);
                              }}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ) : (
                  <Typography>Sorry, No Game match your search</Typography>
                )}
              </Box>
            )}
          </Box>
          <Box my={1}>
            {loadingBetOption ? (
              <FormLoader />
            ) : (
              <Box>
                {betOption && betOption?.length > 0 && (
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStylesTwo}>
                        Bet Option
                      </InputLabel>
                      <br />
                      <RadioGroup
                        aria-label="Bet Option"
                        row
                        name="betType"
                        value={formik.values.betType}
                      >
                        {gameAllowedOptions &&
                          gameAllowedOptions?.length > 0 &&
                          gameAllowedOptions?.map((item, idx) => (
                            <FormControlLabel
                              key={`${item?.name}${idx}`}
                              value={item.name}
                              label={item?.name}
                              name="betType"
                              variant="outlined"
                              onChange={(e) => {
                                formik.setFieldValue("betType", e.target.value);
                              }}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
        <Paper sx={formPaperStyle}>
          <br />
          <Button
            startIcon={
              isSubmitting && (
                <CircularProgress severity="primary" size={"1rem"} />
              )
            }
            type="submit"
            disabled={isSubmitting}
            size="large"
            variant="contained"
            color="primary"
          >
            {isSubmitting ? "Adding Product" : " Add Product"}
          </Button>
        </Paper>
      </form>
    </div>
  );
}

export default AddLottoProduct;
