import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Logo from "../../images/logo.png";
import {
  checkExpiredAuthToken,
  getAuthToken,
  logOutUser,
  TOKEN_NAME,
} from "../../utils";
import DrawerHeader from "./DrawerHeader";
import {
  navItems,
  navAdminItems,
  navGamesItems,
  navBannerItems,
  navGameInstanceItems,
  navConfigItems,
  navRolesItems,
  navStaticPagesItems,
  navAgentItems,
  navReportsItems,
  navTicketsItems,
  navSportsItems,
  navProductsItems,
  navInfluencerItems,
  navDealersItems,
  navDealsItems,
} from "./NavItemsConfig";
import { AppContext } from "../../context";
// import CustomErrorBoundary from "../CustomErrorBoundary/CustomErrorBoundary";

const drawerWidth = 268;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminLayout(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const [cookies, setCookie] = useCookies([TOKEN_NAME]);
  const { auth, adminData, handleSetAdminData } = React.useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    try {
      getAuthToken();
      let token = cookies[TOKEN_NAME];
      if (!token) {
        return navigate("/login");
      }

      if (token) {
        token = jwtDecode(token);
        handleSetAdminData(token);
      }
    } catch (error) {
      console.log(error);
      if (error?.message === "Invalid token specified") {
        navigate("/login");
      }
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setExpanded(null);
  };
  const handleCollapseAdmin = (value) => {
    setExpanded(value);
    if (expanded === value) {
      return setExpanded(null);
    }

    if (expanded !== value) {
      return setExpanded(value);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: `linear-gradient(300deg, #494A8C 0%, #494A8C 71%)`,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <img
              src={Logo}
              alt="logo"
              style={{
                width: "40px",
                height: "40px",
                objectFit: "contain",
              }}
            />
          </Box>
          {auth && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: "white", marginRight: "3px" }}
              >
                {adminData?.name}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>My Profile</MenuItem> */}
                <MenuItem onClick={() => logOutUser()}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navItems.map(({ text, icon, url }, index) => (
            <ListItemButton
              key={text}
              style={{
                color:
                  location?.pathname === url
                    ? theme.palette?.primary?.main
                    : "default",
              }}
              onClick={() => navigate(url)}
            >
              <ListItemIcon
                style={{
                  color:
                    location?.pathname === url
                      ? theme.palette?.primary?.main
                      : "default",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navReportsItems?.openValue)}
          >
            <ListItemIcon>{navReportsItems?.icon}</ListItemIcon>
            <ListItemText primary={navReportsItems?.title} />
            {expanded === navReportsItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navReportsItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navReportsItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navTicketsItems?.openValue)}
          >
            <ListItemIcon>{navTicketsItems?.icon}</ListItemIcon>
            <ListItemText primary={navTicketsItems?.title} />
            {expanded === navTicketsItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navTicketsItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navTicketsItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navDealsItems?.openValue)}
          >
            <ListItemIcon>{navDealsItems?.icon}</ListItemIcon>
            <ListItemText primary={navDealsItems?.title} />
            {expanded === navDealsItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navDealsItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navDealsItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navAdminItems?.openValue)}
          >
            <ListItemIcon>{navAdminItems?.icon}</ListItemIcon>
            <ListItemText primary={navAdminItems?.title} />
            {expanded === navAdminItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navAdminItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navAdminItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navAgentItems?.openValue)}
          >
            <ListItemIcon>{navAgentItems?.icon}</ListItemIcon>
            <ListItemText primary={navAgentItems?.title} />
            {expanded === navAgentItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navAgentItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navAgentItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
              {adminData?.role &&
              ["accountant", "superaccountant", "superadmin"]?.includes(
                adminData?.role
              ) ? (
                <ListItemButton
                  key={"Agents Commission"}
                  sx={{ pl: 4 }}
                  onClick={() => navigate("/agents/commission")}
                >
                  <ListItemIcon>
                    <LoyaltyIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Agents Commission"} />
                </ListItemButton>
              ) : null}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navDealersItems?.openValue)}
          >
            <ListItemIcon>{navDealersItems?.icon}</ListItemIcon>
            <ListItemText primary={navDealersItems?.title} />
            {expanded === navDealersItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navDealersItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navDealersItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />

          <ListItemButton
            onClick={() => handleCollapseAdmin(navInfluencerItems?.openValue)}
          >
            <ListItemIcon>{navInfluencerItems?.icon}</ListItemIcon>
            <ListItemText primary={navInfluencerItems?.title} />
            {expanded === navInfluencerItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navInfluencerItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navInfluencerItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navGamesItems?.openValue)}
          >
            <ListItemIcon>{navGamesItems?.icon}</ListItemIcon>
            <ListItemText
              primary={navGamesItems?.title}
              style={{ marginRight: "10px" }}
            />
            {expanded === navGamesItems?.openValue ? (
              <IconButton edge="end" sx={{ color: "black" }}>
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton edge="end" sx={{ color: "black" }}>
                <ExpandMore />
              </IconButton>
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navGamesItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navGamesItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navBannerItems?.openValue)}
          >
            <ListItemIcon>{navBannerItems?.icon}</ListItemIcon>
            <ListItemText primary={navBannerItems?.title} />
            {expanded === navBannerItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navBannerItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navBannerItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navGameInstanceItems?.openValue)}
          >
            <ListItemIcon>{navGameInstanceItems?.icon}</ListItemIcon>
            <ListItemText primary={navGameInstanceItems?.title} />
            {expanded === navGameInstanceItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navGameInstanceItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navGameInstanceItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />

          <ListItemButton
            onClick={() => handleCollapseAdmin(navSportsItems?.openValue)}
          >
            <ListItemIcon>{navSportsItems?.icon}</ListItemIcon>
            <ListItemText primary={navSportsItems?.title} />
            {expanded === navSportsItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navSportsItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navSportsItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />

          <ListItemButton
            onClick={() => handleCollapseAdmin(navProductsItems?.openValue)}
          >
            <ListItemIcon>{navProductsItems?.icon}</ListItemIcon>
            <ListItemText primary={navProductsItems?.title} />
            {expanded === navProductsItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navProductsItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navProductsItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />

          <ListItemButton
            onClick={() => handleCollapseAdmin(navConfigItems?.openValue)}
          >
            <ListItemIcon>{navConfigItems?.icon}</ListItemIcon>
            <ListItemText primary={navConfigItems?.title} />
            {expanded === navConfigItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navConfigItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navConfigItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navRolesItems?.openValue)}
          >
            <ListItemIcon>{navRolesItems?.icon}</ListItemIcon>
            <ListItemText primary={navRolesItems?.title} />
            {expanded === navRolesItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navRolesItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navRolesItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => handleCollapseAdmin(navStaticPagesItems?.openValue)}
          >
            <ListItemIcon>{navStaticPagesItems?.icon}</ListItemIcon>
            <ListItemText primary={navStaticPagesItems?.title} />
            {expanded === navStaticPagesItems?.openValue ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={expanded === navStaticPagesItems?.openValue}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {navStaticPagesItems.items.map((item) => (
                <ListItemButton
                  key={item.text}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(item.url)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          {/* End of Outer List  */}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}
