import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";

import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import GameInstanceDetailsStatsCard from "../components/GameInstanceDetailsComponents/GameInstanceDetailsStatsCard";
import GameInformation from "../components/GameInstanceDetailsComponents/GameInformation";
import OtherGameInformation from "../components/GameInstanceDetailsComponents/OtherGameInformation";
import GameInstanceOptions from "../components/GameInstanceDetailsComponents/GameInstanceOptions";
import GameInstanceDrawInformation from "../components/GameInstanceDetailsComponents/GameInstanceDrawInformation";
import { AppContext } from "../context";
import GameDrawStatusDialog from "../components/GameInstanceDetailsComponents/GameDrawStatusDialog";

function GameInstanceDetails() {
  const { gameId } = useParams();
  const [selectedDraw, setSelectedDraw] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleSelectDraw = (draw) => setSelectedDraw(draw);
  const handleOpenDrawStatusDialog = () => setOpenDialog(true);
  const handleCloseDrawStatusDialog = () => {
    if (isSubmitting) {
      return null;
    }
    setOpenDialog(false);
  };

  const url = `${process.env.BASE_URL}game/fetch-game/${gameId}`;
  const analyticsURL = `${process.env.BASE_URL}game/instances/analytics/${gameId}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetchAnalytics = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(analyticsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };

  const handleSubmitApproveDrawStatus = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let submitURL = selectedDraw?.isApproved
      ? `${process.env.BASE_URL}game/reverse-result`
      : `${process.env.BASE_URL}game/approve-result`;

    let values = {
      resultId: selectedDraw?.resultId,
    };
    setIsSubmitting(true);
    return axios
      .post(submitURL, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);

        if (res?.data?.data?.message) {
          handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }

        mutate(url);
        return handleCloseDrawStatusDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log("ggggggg", err?.response?.data);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.message,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, An Unexpected Error Occured",
          severity: "error",
        });
      });
  };
  const { data: analyticsData, error: analyticsError } = useSWR(
    analyticsURL,
    fetchAnalytics,
    {
      // dedupingInterval: 70000,
    }
  );

  const { data, error } = useSWR(url, fetcher, {
    dedupingInterval: 70000,
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  // console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator title={`Game Instance Details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Game Instance Details" />
        {openDialog ? (
          <GameDrawStatusDialog
            selectedDraw={selectedDraw}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDrawStatusDialog}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmitApproveDrawStatus}
          />
        ) : null}
        <GameInstanceDetailsStatsCard data={analyticsData} />
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <GameInformation data={data} />
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <OtherGameInformation data={data} />
              {/* <AccountInformation data={data} /> */}
            </Grid>
          </Grid>
        </Box>
        <GameInstanceOptions data={data} />
        <Box my={2}>
          <Typography variant="h6">Game Draws</Typography>
          <GameInstanceDrawInformation
            gameDraw={data?.Gameresults}
            selectedDraw={selectedDraw}
            handleSelectDraw={handleSelectDraw}
            handleOpenDrawStatusDialog={handleOpenDrawStatusDialog}
          />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default GameInstanceDetails;
