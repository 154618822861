import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import AgentDownlinesTable from "../components/AgentDetailsComponents/AgentDownlinesTable";
import PersonalDetails from "../components/AgentDetailsComponents/PersonalDetails";
import ModifyMultiplierDialog from "../components/AgentDetailsComponents/ChangeMultiplierDialog";
import WalletInformation from "../components/AgentDetailsComponents/WalletInformation";
import ToggleAgentDialog from "../components/AgentsComponents/ToggleAgentDialog";
import UsersTickets from "../components/UserDetailsComponents/UsersTicket";
import UserTransactions from "../components/UserDetailsComponents/UserTransactions";
import BetTypeExclusionDialog from "../components/AgentDetailsComponents/BetTypeExclusionDialog";

function AgentDetailsPage() {
  const { userId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openToggleDialog, setOpenToggleDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openBetTypesDialog, setOpenBetTypesDialog] = useState(false);

  const { handleOpenSnackbar } = useContext(AppContext);

  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleOpenToggleAgentDialog = () => setOpenToggleDialog(true);
  const handleCloseToggleAgentDialog = () => setOpenToggleDialog(false);
  const handleOpenBetTypeDialog = () => setOpenBetTypesDialog(true);
  const handleCloseBetTypeDialog = () => setOpenBetTypesDialog(false);

  const url = `${process.env.BASE_URL}/agent/fetch-agent/${userId}`;

  const handleToggleSubmit = (agentInfo) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: agentInfo?.status ? false : true,
      userId: agentInfo?.userId,
    };

    axios
      .post(`${process.env.BASE_URL}agent/toggle-account-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.data?.message) {
          handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
          mutate(url);
          return handleCloseToggleAgentDialog();
        }
        handleOpenSnackbar({
          message: "Operation successful",
          severity: "success",
        });
        mutate(url);
        return handleCloseToggleAgentDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, An Unexpected error occured",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator
        title={`${data && data?.firstname} ${
          data && data?.lastname
        } details - Admin`}
      />
      <ModifyMultiplierDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        userId={data?.userId}
        agentURL={url}
      />
      <ToggleAgentDialog
        openDialog={openToggleDialog}
        handleCloseDialog={handleCloseToggleAgentDialog}
        selectedUser={data}
        isSubmitting={isSubmitting}
        handleSubmit={handleToggleSubmit}
      />
      <BetTypeExclusionDialog
        openDialog={openBetTypesDialog}
        handleCloseDialog={handleCloseBetTypeDialog}
        user={data}
        userId={data?.userId}
        agentURL={url}
        handleSubmit={handleToggleSubmit}
      />
      <Container maxWidth="xl">
        <PageHeading title="Agent's details" />
        <Grid container spacing={2}>
          <PersonalDetails data={data} />
          <WalletInformation
            data={data}
            handleOpenDialog={handleOpenDialog}
            handleOpenToggleAgentDialog={handleOpenToggleAgentDialog}
            handleOpenBetTypeDialog={handleOpenBetTypeDialog}
          />
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <UsersTickets userId={userId} />
            </Grid>

            <Grid item xs={12} md={6}>
              <UserTransactions userId={userId} />
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Typography gutterBottom variant="h6" color="secondary">
            Downlines Information
          </Typography>
          <AgentDownlinesTable data={data?.downlines} />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default AgentDetailsPage;
