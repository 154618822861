import React from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadCSVButton = ({ data }) => {
  const handleDownloadCSV = () => {
    let csvContent;

    const headers = [
      "Date",
      "Amount Sold",
      "No of Ticket Sold",
      "Commission",
      "No. of Commission",
      "Amount Won",
      "Profit",
    ];
    let rows = [];

    data?.forEach((item) => {
      rows.push([
        item?.createdOn,
        item?.totalSold,
        item?.ticketCount,
        item?.totalCommission,
        item?.commissionCount,
        item?.totalWon,
        item?.profit,
      ]);
    });

    rows = [headers, ...rows];
    csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };
  return (
    <Button
      startIcon={<DownloadIcon />}
      size="small"
      variant="outlined"
      onClick={() => handleDownloadCSV()}
    >
      download csv
    </Button>
  );
};

export default DownloadCSVButton;
