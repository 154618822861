import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../utils";
import { countryListAllIsoData } from "../../utils/countries";

function LeaguesFilterForm({
  leagueName,
  status,
  startDate,
  endDate,
  country,
  handleChangeStatus,
  handleChangeLeagueName,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeCountry,
  handleFilterSubmit,
}) {
  return (
    <Box mb={2}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Name of League
            </InputLabel>
            <TextField
              value={leagueName}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeLeagueName(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                ampm={true}
                // label="Last Login Date"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                // label="Last Login Date"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Country
            </InputLabel>
            <Select
              variant="outlined"
              value={country}
              onChange={(e) => handleChangeCountry(e)}
            >
              {countryListAllIsoData?.map((row) => (
                <MenuItem value={row?.name} key={row?.code}>
                  {row?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              variant="outlined"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LeaguesFilterForm;
