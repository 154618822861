import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";

export default function GameDrawStatusDialog({
  selectedDraw,
  openDialog,
  handleCloseDialog,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>
          {selectedDraw?.isApproved
            ? "CONFIRM TO REVERSE DRAW"
            : "CONFIRM TO APPROVE DRAW"}
        </DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            <DialogContentText>
              Do you want to {selectedDraw?.isApproved ? "reverse" : "approve"}{" "}
              this draw, click on the{" "}
              {selectedDraw?.isApproved ? "reverse " : "approve "}
              button to proceed or, click on the cancel button to ignore
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            onClick={() => handleSubmit()}
            variant="contained"
            color="success"
          >
            {isSubmitting ? (
              "Processing"
            ) : (
              <> {selectedDraw?.isApproved ? "Reverse" : "Approve"} </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
