import React, { useState } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  FILTER_DATE_FORMAT,
} from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import LeaguesTable from "../components/Leagues/LeaguesTable";
import DeactivateLeagueDialog from "../components/Leagues/DectivateLeagueDialog";
import ActivateLeagueDialog from "../components/Leagues/ActivateLeagueDialog";
import { useNavigate } from "react-router-dom";
import LeaguesFilterForm from "../components/Leagues/LeaguesFilterForm";
import dayjs from "dayjs";

function Leagues() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [status, setStatus] = useState("");
  const [country, setCountry] = useState("");
  const [leagueName, setLeagueName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({});

  const url = `${process.env.BASE_URL}game/fetch-leagues?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}game/fetch-leagues?limit=${rowsPerPage}&page=${page}`;
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // SessionStorage  Variable Declarations
  let sStatus = "status";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sCountry = "country";
  let sLeagueName = "search";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };
  const handleChangeLeagueName = (e) => {
    setLeagueName(e.target.value);
    sessionStorage.setItem(sLeagueName, e.target.value);
  };

  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
    sessionStorage.setItem(sCountry, e.target.value);
  };
  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };
  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sCountry);
    sessionStorage.removeItem(sLeagueName);
  };

  const handleResetFields = () => {
    setStatus("");
    setStartDate(null);
    setEndDate(null);
    setCountry("");
    setLeagueName("");
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (leagueName !== "") {
      if (!filterURL.includes(sLeagueName)) {
        filterURL = filterURL + `&search=${leagueName}`;
      }
    }
    if (country !== "") {
      if (!filterURL.includes(sCountry)) {
        filterURL = filterURL + `&country=${country}`;
      }
    }

    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL +
          `&startDate=${dayjs(startDate).format(FILTER_DATE_FORMAT)}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format(FILTER_DATE_FORMAT)}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectCategory = (game) => setSelectedCategory(game);

  const handleOpenDeactivateDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenActivateDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateDialog = () => {
    setOpenActivateDialog(false);
  };

  const handleSubmitDeactivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      leagueId: selectedCategory?.leagueId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/toggle-league-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "League have Been Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, League Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleSubmitActivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: true,
      leagueId: selectedCategory?.leagueId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/toggle-league-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "League Have Been Successfully Activated",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "League Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    setIsFiltering(true);
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        setIsFiltering(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve categories data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Leagues Table - Admin Dashboard SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Leagues" />

        {openDeactivateDialog ? (
          <DeactivateLeagueDialog
            openDialog={openDeactivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateDialog}
            handleSubmit={handleSubmitDeactivate}
          />
        ) : null}
        {openActivateDialog ? (
          <ActivateLeagueDialog
            openDialog={openActivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseActivateDialog}
            handleSubmit={handleSubmitActivate}
          />
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={() => navigate("/add-league")}>
            Add new league
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <LeaguesFilterForm
            leagueName={leagueName}
            status={status}
            startDate={startDate}
            endDate={endDate}
            country={country}
            handleChangeCountry={handleChangeCountry}
            handleChangeStatus={handleChangeStatus}
            handleChangeLeagueName={handleChangeLeagueName}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        <LeaguesTable
          isFiltering={isFiltering}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedCategory={selectedCategory}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSelectCategory={handleSelectCategory}
          handleOpenActivateDialog={handleOpenActivateDialog}
          handleOpenDeactivateDialog={handleOpenDeactivateDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default Leagues;
