import jwtDecode from "jwt-decode";
import { createBrowserHistory } from "history";
import axios from "axios";

const history = createBrowserHistory();

export const TOKEN_NAME = "auth";
export const PREV_PATH = "PREV_PATH";
export const FILTER_DATE_FORMAT = "DD/MM/YYYY";
export const META4 = "meta4";
export const CASH_OUT = "cash-out";
export const EAZY_WIN = "eazy-win";
export const PERFECT_BALLS = "perfect-balls";
export const LOTTO = "lotto";
export const METRO = "metro";
export const WESTERN_INDOOR_GAME = "western-indoor-game";
export const WESTERN_INDOOR_GAME_BETOPTIONS = "western-indoor-game-bet-options";
export const WESTERN_INDOOR_GAME_RESULTOPTIONS =
  "western-indoor-game-result-options";
export const WESTERN_INDOOR_GAME_OVERUNDER_OPTIONS =
  "western-indoor-game-over-under-bet-options";

export const WESTERN_INDOOR_GAME_V2 = "western-indoor-game-v2";
export const WESTERN_INDOOR_GAME_V2_BETOPTIONS =
  "western-indoor-game-v2-bet-options";
export const WESTERN_INDOOR_GAME_V2_RESULTOPTIONS =
  "western-indoor-game-v2-result-options";
export const WESTERN_INDOOR_GAME_V2_OVERUNDER_OPTIONS =
  "western-indoor-game-v2-over-under-bet-options";

export const GHANA_GAME = "ghana-game";
export const GHANA_GAME_BETOPTIONS = "ghana-game-bet-options";
export const GHANA_GAME_RESULTOPTIONS = "ghana-game-result-options";
export const GHANA_GAME_OVERUNDER_OPTIONS = "ghana-game-over-under-bet-options";

export const GHANA_GAME_V2 = "ghana-game-v2";
export const GHANA_GAME_V2_BETOPTIONS = "ghana-game-v2-bet-options";
export const GHANA_GAME_V2_RESULTOPTIONS = "ghana-game-v2-result-options";
export const GHANA_GAME_V2_OVERUNDER_OPTIONS =
  "ghana-game-v2-over-under-bet-options";

export const DYNAMIC_HEADINGS = "dynamic-headings";
export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

export const imagePreviewStyle = {
  objectFit: "contain",
};
export const imageBox = {
  display: "flex",
  justifyContent: "space-between",
};

export const formPaperStyle = { padding: 3 };
export const lottoProductCategory = {
  LOTTO_CAR: "Lotto-Car",
  LOTTO_HOUSE: "Lotto-House",
  LOTTO_SALARY: "Lotto-Salary",
  LOTTO_PHONE: "Lotto-Phone",
};

export const lottoProductCategoryArray = [
  {
    title: "Lotto Car",
    value: lottoProductCategory.LOTTO_CAR,
  },
  {
    title: "Lotto House",
    value: lottoProductCategory.LOTTO_HOUSE,
  },
  {
    title: "Lotto Phone",
    value: lottoProductCategory.LOTTO_PHONE,
  },
  {
    title: "Lotto Salary",
    value: lottoProductCategory.LOTTO_SALARY,
  },
];
export const existingProductCatImageBoxStyle = {
  display: "flex",
  gap: "7px",
  alignItems: "center",
  justifyContent: "center",
};
export const boxStyle5 = {
  maxWidth: "500px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};
export const singleDetailsBoxStyle3 = {
  idth: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};
export const IMAGE_PREVIEW_WIDTH = 70;
export const IMAGE_PREVIEW_HEIGHT = 70;
export const INFLUENCER = "influencer";
export const DEALER = "dealer";
export const BUNDLE_BONUS = "bundle-bonus";
export const NORMAL_BONUS = "normal-bonus";
export const BONUS_TYPE = "bonusType";
export const rowsPerPageOptions = [20, 50, 100, 200];

export const eventsOptionsToRemove = [
  "eventId",
  "eventOptionId",
  "status",
  "deleted",
  "id",
  "category",
  "createdAt",
  "updatedAt",
];
export const formatBonusType = (type = NORMAL_BONUS) => {
  if (type === BUNDLE_BONUS) return "Bundle Bonus";
  return "Normal Bonus";
};
export const getAuthToken = () => {
  let name = TOKEN_NAME + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  // console.log("decodedCookie", decodedCookie);
  setDefaultHeaders();
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      // console.log("c", c.substring(1));
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export const getRecomendedBannerDimension = (category) => {
  if (category === "slider") {
    return "Banner Category Recomended Image Size Width by Height: 820px by 384px";
  }
  if (category === "left-desktop") {
    return "Banner Category Recomended Image Size Width by Height: 240px by 270px";
  }
  if (category === "right-desktop") {
    return "Banner Category Recomended Image Size Width by Height: 240px by 270px";
  }
  if (category === "top-mobile") {
    return "Banner Category Recomended Image Size Width by Height: 728px by 90px";
  }
  if (category === "popup") {
    return "Banner Category Recomended Image Size Width by Height: 500px by 500px";
  }
  return "Banner not currently in use, kindly change to a new banner";
};
export const deepMergeObject = (obj1, obj2) => {
  let Obj1Clone = { ...obj1 };
  let Obj2Clone = { ...obj2 };

  for (const key in obj1) {
    if (!obj1[key]) {
      delete Obj1Clone[key];
    }
  }
  for (const key in obj2) {
    if (!obj2[key]) {
      delete Obj2Clone[key];
    }
  }
  let result = { ...Obj1Clone, ...Obj2Clone };
  return result;
};

export function deleteCookie() {
  if (getAuthToken()) {
    document.cookie =
      TOKEN_NAME + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const checkExpiredAuthToken = () => {
  const pathname = history?.location?.pathname;
  try {
    const token = getAuthToken();

    if (token) {
      const decodedToken = jwtDecode(token);

      const tokenExpired = decodedToken.exp < new Date().getTime() / 1000;
      if (tokenExpired) {
        //  console.log("Your Login Session Have Expired");
        deleteCookie();
        // return history.push("/login", {
        //   state: {
        //     prevPath: pathname,
        //   },
        // });
        return (window.location.href = "/login");
      }
    }
  } catch (error) {
    console.log("err", error);
    deleteCookie();
    return history.push("/login", {
      state: {
        prevPath: pathname,
      },
    });
  }
};

export const formatErrorMessage = (errObj) => {
  if (errObj?.response?.data?.responsemessage?.msg) {
    return `${errObj?.response?.data?.responsemessage?.msg}: ${errObj?.message}`;
  }
  if (errObj?.response?.data?.responsemessage) {
    return `${errObj?.response?.data?.responsemessage} : ${errObj?.message}`;
  }
  if (errObj?.message) {
    return errObj?.message;
  }
  return "An Unexepected error occured";
};
export const logOutUser = () => {
  sessionStorage.setItem(PREV_PATH, history?.location?.pathname);
  deleteCookie();

  return (window.location.href = "/login");
};

export const labelStyles = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "-9px",
  fontSize: "13.5px",
};
export const labelStylesTwo = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "7.5px",
  fontSize: "13.5px",
};
export const labelStylesThree = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "65px",
  fontSize: "13.5px",
};

export const seeMoreBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0px",
};

export const menuItemsStyles = { fontSize: "12.7px" };
export const dialogLoaderStyles = { width: "330px", minWidth: "330px" };
export const headerStyles = { fontWeight: 600 };
export const previewSubTitleStyles = { fontWeight: 600 };
export const boldTextCalculationStyles = { fontWeight: 600 };
export const dialogMainTextStyles = { color: "black" };
export const previewDialogMainTitleStyle = {
  textAlign: "center",
  fontWeight: 600,
};
export const singleDetailsBoxStyle = {
  maxWidth: "500px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};

export const singleDetailsBoxStyleTwo = {
  maxWidth: "800px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};

export const AddButtonBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "5px",
  marginTop: "5px",
};

export const tableButtonStyles = {
  textTransform: "capitalize",
  fontSize: "12px",
  padding: "2px",
};
export const detailsActionBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const csvBoxStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "5px",
};

export const csvLinkStyle = { textDecoration: "none" };
export const userRoles = [
  {
    title: "Ordinary User",
    value: "ordinary User",
  },
  {
    title: "First Class User",
    value: "first class user",
  },
  {
    title: "VIP User",
    value: "vip User",
  },
];
export const agentRoles = [
  {
    title: "Cashier",
    value: "cashier",
  },
  {
    title: "Ordinary Agent",
    value: "ordinaryagent",
  },
  {
    title: "Super Agent",
    value: "superagent",
  },
  {
    title: "Principal Agent",
    value: "principalagent",
  },
];
export const BannerCategories = [
  {
    title: "Top-Desktop",
    value: "top-desktop",
  },
  {
    title: "Top-Mobile",
    value: "top-mobile",
  },
  {
    title: "Left-Desktop",
    value: "left-desktop",
  },
  {
    title: "Right-Desktop",
    value: "right-desktop",
  },

  {
    title: "Box-mobile",
    value: "box-mobile",
  },
  {
    title: "Slider",
    value: "slider",
  },
  {
    title: "Pop up",
    value: "popup",
  },
];
export const ProviderList = [
  {
    title: "InterSwitch",
    value: "interswitch",
  },
  {
    title: "PayStack",
    value: "paystack",
  },
  {
    title: "Source",
    value: "source",
  },
];
export const WeekdayList = [
  {
    title: "0 - Sunday",
    value: 0,
  },
  {
    title: "1 - Monday",
    value: 1,
  },
  {
    title: "2 - Tuesday",
    value: 2,
  },
  {
    title: "3 - Wednesday",
    value: 3,
  },
  {
    title: "4 - Thursday",
    value: 4,
  },
  {
    title: "5 - Friday",
    value: 5,
  },
  {
    title: "6 - Saturday",
    value: 6,
  },
];
export const limit = 20;

export const getWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommisionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].commissionBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getOverdraftAmountSum = (array) => {
  let initialValue = 0;
  for (let index = 0; index < array.length; index++) {
    let element = array[index]?.initialAmount;
    element = parseFloat(element);
    initialValue += element;
  }

  return initialValue.toFixed(2);
};

export const getWalletFlowSum = (array) => {
  let initialValue = 0;
  for (let index = 0; index < array.length; index++) {
    let element = array[index]?.amount;
    element = parseFloat(element);
    initialValue += element;
  }

  return initialValue.toFixed(2);
};

export const getCommissionSalesSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalSales;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getCommissionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalCommission;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getTransactionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].amount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommissionWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommissionWonSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWon;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
// Function to set default headers
export const setDefaultHeaders = () => {
  axios.defaults.headers.common["x-api-key"] = `${process.env.ADMIN_API_KEY}`;
};

// To safely JSON.parse a string without breaking the application
export const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.log("err while parseing", error);
  }
};
export const renderHostURL = () => {
  if (typeof window !== "undefined") {
    return `${window.location.protocol}//${window.location?.host}`;
  }
  return "";
};

export function currencyFormater(x, dp) {
  if (!x) {
    return 0;
  }
  let num = parseFloat(x).toFixed(dp || 0);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getSumFromObj = (data) => {
  let total = 0;

  for (const item in data) {
    let elem = data[item];
    total += elem;
  }

  return total;
};

export const getStakedAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalStakedAmount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getWinningAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWinAmount || 0;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const sortGamesLabel = (data = []) => {
  return data;
  // let result = [];
  // result = data?.sort((a, b) => (a < b ? 1 : a > b ? -1 : 0));
  // console.log("bbbbbbbbbbbbbb", result);
  // return result;
};
export const sortGamesLabelByAmount = (data = []) => {
  let result = [];
  result = data?.sort((a, b) =>
    a.amount < b.amount ? 1 : a?.amount > b?.amount ? -1 : 0
  );
  // console.log("bbbbbbbbbbbbbb", result);
  return result;
};

// (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0

export const getWindowQueryString = (name) => {
  let result = "";
  if (typeof window === "object") {
    const urlParams = new URLSearchParams(window.location.search);
    result = urlParams.get(name);
  }
  return result;
};
