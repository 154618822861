import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  getAuthToken,
  checkExpiredAuthToken,
  dialogMainTextStyles,
  singleDetailsBoxStyle,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

function Transaction() {
  const { transactionId } = useParams();

  const url = `${process.env.BASE_URL}transactions/fetch-transaction/${transactionId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator title={`Transaction's details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Transaction's details" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={5}>
            <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
              Player Information
            </Typography>
            <Box my={2}>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>First Name:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.User?.firstname}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Last Name:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.User?.lastname}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Email:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.User?.email}{" "}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Phone Number:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.User?.phone}{" "}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Player Role:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.User?.role}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Player Status:</Typography>
                <Box component="span" sx={dialogMainTextStyles}>
                  {data?.User?.status ? (
                    <Chip color="success" label="Active" size="small" />
                  ) : (
                    <Chip label="Not Active" color="error" size="small" />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} lg={5}>
            <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
              Transaction Information
            </Typography>
            <Box my={2}>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Amount:</Typography>
                <NumberFormat
                  value={data?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"NGN"}
                  renderText={(value, props) => (
                    <Typography
                      sx={dialogMainTextStyles}
                      align="left"
                      {...props}
                    >
                      {value}
                    </Typography>
                  )}
                />
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Transaction Type:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.transactionType}{" "}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Reference ID:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.referenceId}{" "}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Transaction Status:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.status}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Tranaction ID:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.transactionId}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Provider:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.provider}
                </Typography>
              </Box>
              <Box my={1} sx={singleDetailsBoxStyle}>
                <Typography>Date Created:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {dayjs(data?.createdAt).format("hh:mm a, DD MMM YYYY")}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="body2">Narration:</Typography>
                <Typography sx={dialogMainTextStyles} paragraph>
                  {data?.narration}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
}

export default Transaction;
