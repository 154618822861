import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  checkExpiredAuthToken,
  formatErrorMessage,
  getAuthToken,
} from "../../utils";
import axios from "axios";
import { AppContext } from "../../context";
import { mutate } from "swr";

export default function ModifyResultDialog({
  openDialog,
  handleCloseDialog,
  selectedEvent,
  url,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventId: "",
      homeScore: "",
      awayScore: "",
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      values.eventId = selectedEvent?.eventId;

      axios
        .put(
          `${process.env.BASE_URL}sport/update-result/${selectedEvent?.resultId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          await mutate(url);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return handleCloseDialog();
          }
          handleOpenSnackbar({
            message: "Result Uploaded Successfully",
            severity: "success",
          });
          handleCloseDialog();
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          const errMsg = formatErrorMessage(err);

          handleOpenSnackbar({
            message: errMsg,
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      homeScore: yup.number().required().label("Home Score"),
      awayScore: yup.number().required().label("Away Score"),
    }),
  });
  console.log("ssssssssssssssssssssss", selectedEvent);
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
      <DialogTitle>MODIFY RESULT</DialogTitle>
      <form noValidate onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box>
            <Typography gutterBottom variant="h6">
              Event Title: {selectedEvent?.Event?.title}
            </Typography>
            <Typography gutterBottom>
              Current Home Team Score: {selectedEvent?.homeScore}, &nbsp;
              Current Away Team Score: {selectedEvent?.awayScore}
            </Typography>
            <Typography gutterBottom>{selectedEvent?.title}</Typography>
          </Box>
          <Box my={1} sx={{ minWidth: "300px" }}>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Enter new home team score"
                  error={formik.touched.homeScore && formik.errors.homeScore}
                  name="homeScore"
                  variant="outlined"
                  value={formik.values.homeScore}
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.homeScore && formik.errors.homeScore
                  }
                />
              </FormControl>
            </Box>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Enter new away team score"
                  error={formik.touched.awayScore && formik.errors.awayScore}
                  name="awayScore"
                  variant="outlined"
                  value={formik.values.awayScore}
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.awayScore && formik.errors.awayScore
                  }
                />
              </FormControl>
            </Box>

            <br />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>

          <Button
            startIcon={
              isSubmitting && (
                <CircularProgress severity="primary" size={"1rem"} />
              )
            }
            type="submit"
            disabled={isSubmitting}
            size="large"
            variant="contained"
            color="primary"
          >
            {isSubmitting ? "Processing" : "Modify Result"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
