import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import { AppContext } from "../../context";
import {
  checkExpiredAuthToken,
  formatErrorMessage,
  getAuthToken,
} from "../../utils";
import FormLoader from "../../components/FormLoader/FormLoader";
import dayjs from "dayjs";

let summarySchema = yup.object().shape({
  mainDraw: yup
    .string()
    .matches(/([\d+-])+(\d)$/gm, "Pattern should be e.g: 4-5-6-7-8")
    .required()
    .label("Main Draw"),
  bonusDraw: yup
    .string()
    .matches(/([\d+-])+(\d)$/gm, "Pattern should be e.g: 4-5-6-7-8")
    .required()
    .label("Bonus Draw"),
  startDate: yup.string().required().label("Start Date"),
  endDate: yup.string().required().label("End Date"),
});

function WinningCheckForm({
  selectedGame,
  setSelectedGame,
  setIsResultAvailable,
  setResult,
  setSelectionList1,
  setSelectionList2,
}) {
  const [bonusSearchField, setBonusSearchField] = useState("");
  const [currentGameId, setCurrentGameId] = useState("");
  const [gamesArray, setGamesArray] = useState([]);
  const [isGameSelected, setIsGameSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const { handleOpenSnackbar } = useContext(AppContext);

  let initialValues = {
    mainDraw: "",
    bonusDraw: "",
    startDate: null,
    endDate: null,
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();

    setSelectionList1((curr) => values?.mainDraw?.split("-"));
    setSelectionList2((curr) => values?.bonusDraw?.split("-"));
    const payload = {
      winningSelections: values?.mainDraw,
      machineSelections: values?.bonusDraw,
      gameId: selectedGame?.gameId,
    };
    axios
      .post(
        `${process.env.BASE_URL}admin/fetch-ticket-analytics?startDate=${dayjs(
          startDate
        ).format("DD/MM/YYYY")}&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        helpers.setSubmitting(false);
        // helpers.resetForm({ values: initialValues });
        setResult(res?.data?.data?.data);
        setIsResultAvailable(true);

        return handleOpenSnackbar({
          message: "Analysis Fetched Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        const message = formatErrorMessage(err);
        helpers.setSubmitting(false);

        handleOpenSnackbar({
          message: message,
          severity: "error",
        });
      });
  };

  const handleChangeSearch = (e) => {
    setBonusSearchField(e.target.value);
  };
  const url = `${
    process.env.BASE_URL
  }game/fetch-games?limit=${100}&search=${bonusSearchField}`;
  // const url = `${process.env.BASE_URL}game/fetch-games?limit=${20}`;

  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };

  const handleClick = async (game) => {
    await setSelectedGame(game);
    await setCurrentGameId(game?.gameId);
    setIsGameSelected(true);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={summarySchema}
        validateOnBlur={true}
        validateOnChange={true}
        //   onSubmit={(values) => console.log(values)}
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      >
        {({
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form noValidate>
            <Box my={2}>
              <Typography gutterBottom>
                To check for the analysis of ticket winning for a game, search
                for the game or click on the search button without any text to
                get the most recent 100 games, select a game from the result and
                complete filling the form, and click on the Run Check button
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter Game Title"
                    fullWidth
                    onChange={(e) => handleChangeSearch(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    size="large"
                    color="primary"
                    disabled={isSearching}
                    variant="contained"
                    type="button"
                    onClick={() => searchGamesData()}
                  >
                    {isSearching ? "Searching" : "Search"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {hasSearchedGames ? (
              <Box>
                {gamesArray && gamesArray?.length > 0 ? (
                  <Grid container spacing={1}>
                    {gamesArray.map((item, idx) => (
                      <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                        <Chip
                          label={item?.name}
                          color={
                            currentGameId === item?.gameId
                              ? "primary"
                              : "default"
                          }
                          variant={
                            currentGameId === item?.gameId
                              ? "filled"
                              : "outlined"
                          }
                          onClick={() => {
                            handleClick(item);
                            setSelectedGame(item);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography>
                    Sorry, no game match your search, search for a new game
                    again
                  </Typography>
                )}
              </Box>
            ) : isSearching ? (
              <FormLoader />
            ) : null}
            <br />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Winning Draw Selections, e.g (1-2-3-4-5-6)"
                      placeholder="1-2-3-4-5-6"
                      error={touched.mainDraw && errors.mainDraw}
                      disabled={isGameSelected === false}
                      value={values.mainDraw}
                      name="mainDraw"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.mainDraw && errors.mainDraw}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Machine Draw Selections, e.g (1-2-3-4-5-6)"
                      placeholder="1-2-3-4-5-6"
                      value={values.bonusDraw}
                      error={touched.bonusDraw && errors.bonusDraw}
                      disabled={isGameSelected === false}
                      name="bonusDraw"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.bonusDraw && errors.bonusDraw}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={values.startDate}
                        disabled={isGameSelected === false}
                        disableFuture
                        onChange={(value) => {
                          setStartDate(value);
                          setFieldValue("startDate", value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="Start Date" />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={values.endDate}
                        disabled={isGameSelected === false}
                        disableFuture
                        onChange={(value) => {
                          setEndDate(value);
                          setFieldValue("endDate", value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="End Date" />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <br />
            <Button
              startIcon={
                isSubmitting && (
                  <CircularProgress severity="primary" size={"1rem"} />
                )
              }
              disabled={isSubmitting}
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {isSubmitting ? "Processing" : "Run Check"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default WinningCheckForm;
