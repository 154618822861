import React, { useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import AddEventResultDialog from "../components/ElapsedEvents/AddEventResultDialog";
import EventsTable from "../components/ElapsedEvents/EventsTable";
import ElapsedEventsFilterForm from "../components/ElapsedEvents/ElapsedEventsFilterForm";
import { sSearch, sTimeline, sEventOptions } from "../pages/Events";
function ElapsedRealEvents() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [timeline, setTimeline] = useState("");
  const [eventOptions, setEventOptions] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAddResultDialog, setOpenAddResultDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const url = `${process.env.BASE_URL}game/fetch-events?limit=${rowsPerPage}&page=${page}&hasResult=false&timeline=ended&eventType=real-sport`;
  let filterURL = `${process.env.BASE_URL}game/fetch-events?limit=${rowsPerPage}&page=${page}&hasResult=false&timeline=ended&eventType=real-sport`;
  // endDate: dayjs(values.startDate).add(130, "minutes"),

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    sessionStorage.setItem(sSearch, e.target.value);
  };
  const handleChangeTimeline = (e) => {
    setTimeline(e.target.value);
    sessionStorage.setItem(sTimeline, e.target.value);
  };
  const handleChangeEventOptions = (e) => {
    setEventOptions(e.target.value);
    sessionStorage.setItem(sEventOptions, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sSearch);
    sessionStorage.removeItem(sTimeline);
    sessionStorage.removeItem(sEventOptions);
  };

  const handleResetFields = () => {
    setSearch("");
    setTimeline("");
    setEventOptions("");
  };

  const handleSetSessionStorage = () => {
    let sSSearch = sessionStorage.getItem(sSearch);
    if (sSSearch) {
      setSearch(sSSearch);
      setHasFiltered(true);
    }

    if (search !== "") {
      if (!filterURL.includes(sSearch)) {
        filterURL = filterURL + `&search=${search}`;
      }
    }
    if (timeline !== "") {
      if (!filterURL.includes(sTimeline)) {
        filterURL = filterURL + `&timeline=${timeline}`;
      }
    }
    if (eventOptions !== "") {
      if (!filterURL.includes(sEventOptions)) {
        filterURL = filterURL + `&eventOptions=${eventOptions}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const handleSelectEvent = (game) => setSelectedEvent(game);

  const handleOpenAddResultDialog = (item) => {
    setOpenAddResultDialog(true);
    // setSelectedEvent(item);
  };
  const handleCloseAddResultDialog = () => {
    setOpenAddResultDialog(false);
    setSelectedEvent(null);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <Box>
      {openAddResultDialog ? (
        <AddEventResultDialog
          openDialog={openAddResultDialog}
          isSubmitting={isSubmitting}
          handleCloseDialog={handleCloseAddResultDialog}
          selectedEvent={selectedEvent}
          url={url}
        />
      ) : null}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <>{hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}</>
        <FormControlLabel
          sx={{ marginLeft: "auto" }}
          control={
            <Switch checked={showFilter} onChange={handleChangeFilter} />
          }
          label="Show Filters"
        />
      </Box>
      {showFilter && (
        <ElapsedEventsFilterForm
          search={search}
          timeline={timeline}
          eventOptions={eventOptions}
          handleChangeSearch={handleChangeSearch}
          handleChangeTimeline={handleChangeTimeline}
          handleChangeEventOptions={handleChangeEventOptions}
          handleFilterSubmit={handleFilterSubmit}
        />
      )}
      <EventsTable
        page={page}
        data={data?.data}
        totalCount={data?.totalCount}
        selectedEvent={selectedEvent}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleSelectEvent={handleSelectEvent}
        handleOpenAddResultDialog={handleOpenAddResultDialog}
      />
    </Box>
  );
}

export default ElapsedRealEvents;
