import React, { useState } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  FILTER_DATE_FORMAT,
} from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import ProductsTable from "../components/LottoProducts/ProductsTable";
import DeactivateProduct from "../components/LottoProducts/DeactivateProduct";
import ActivateProduct from "../components/LottoProducts/ActivateProduct";
import dayjs from "dayjs";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import LottoProductsFilterForm from "../components/LottoProducts/LottoProductsFilterForm";

function LottoProducts() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [status, setStatus] = useState("");
  const [productName, setProductName] = useState("");
  const [minAmount, setMinAmount] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openDrawDialog, setOpenDrawDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const navigate = useNavigate();
  const url = `${process.env.BASE_URL}lotto-product/fetch-products?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}lotto-product/fetch-products?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations
  let sStatus = "status";
  let sProductName = "search";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";
  let sCategory = "categories";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeProductName = (e) => {
    setProductName(e.target.value);
    sessionStorage.setItem(sProductName, e.target.value);
  };
  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };
  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
    sessionStorage.setItem(sCategory, e.target.value);
  };
  const handleChangeMinimumAmount = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumAmount = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };
  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sProductName);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
    sessionStorage.removeItem(sCategory);
  };
  const handleResetFields = () => {
    setStatus("");
    setProductName("");
    setCategory("");
    setStartDate(null);
    setEndDate(null);
    setMinAmount("");
    setMaxAmount("");
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (category !== "") {
      if (!filterURL.includes(sCategory)) {
        filterURL = filterURL + `&categories=${category}`;
      }
    }
    if (productName !== "") {
      if (!filterURL.includes(sProductName)) {
        filterURL = filterURL + `&search=${productName}`;
      }
    }
    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL +
          `&startDate=${dayjs(startDate).format(FILTER_DATE_FORMAT)}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format(FILTER_DATE_FORMAT)}`;
      }
    }
  };
  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectCategory = (game) => setSelectedCategory(game);

  const handleOpenDeactivateDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenActivateDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateDialog = () => {
    setOpenActivateDialog(false);
  };

  const handleSubmitDeactivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      productId: selectedCategory?.productId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}lotto-product/toggle-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Product have Been Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Product Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleSubmitActivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: true,
      productId: selectedCategory?.productId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}lotto-product/toggle-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Product Have Been Successfully Activated",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Product Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    setIsFiltering(true);

    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);

        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Lotto Products Table - Admin Dashboard SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Lotto Products" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => navigate("/add-product")} variant="contained">
            Add New Product
          </Button>
        </Box>
        {openDeactivateDialog ? (
          <DeactivateProduct
            openDialog={openDeactivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateDialog}
            handleSubmit={handleSubmitDeactivate}
          />
        ) : null}
        {openActivateDialog ? (
          <ActivateProduct
            openDialog={openActivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseActivateDialog}
            handleSubmit={handleSubmitActivate}
          />
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <LottoProductsFilterForm
            productName={productName}
            status={status}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            isFiltering={isFiltering}
            category={category}
            handleChangeCategory={handleChangeCategory}
            handleChangeMinimumAmount={handleChangeMinimumAmount}
            handleChangeMaximumAmount={handleChangeMaximumAmount}
            handleChangeStatus={handleChangeStatus}
            handleChangeProductName={handleChangeProductName}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        <ProductsTable
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedCategory={selectedCategory}
          rowsPerPage={rowsPerPage}
          isFiltering={isFiltering}
          category={category}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeCategory={handleChangeCategory}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSelectCategory={handleSelectCategory}
          handleOpenActivateDialog={handleOpenActivateDialog}
          handleOpenDeactivateDialog={handleOpenDeactivateDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default LottoProducts;
