import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Image from "../../images/no-data.jpg";

const NoDataAvailable = ({ message = "No Data Available" }) => {
  return (
    <Box
      component={Paper}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 10px",
      }}
    >
      <img
        src={Image}
        alt="no data available"
        style={{
          width: "280px",
          height: "280px",
          marginBottom: "5px",
          objectFit: "contain",
        }}
      />
      <Typography align="center" gutterBottom variant="h6">
        {message}
      </Typography>
    </Box>
  );
};

export default NoDataAvailable;
