import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../utils";

const eventTimeLine = [
  { title: "Upcoming", value: "upcoming" },
  { title: "Ongoing", value: "ongoing" },
  { title: "Ended", value: "ended" },
];

const eventOptionTypes = [
  { title: "1X2", value: "1X2" },
  { title: "Correct Scores", value: "correct-scores" },
];
function ElapsedEventsFilterForm({
  search,
  timeline,
  eventOptions,
  handleChangeSearch,
  handleChangeTimeline,
  handleChangeEventOptions,
  handleFilterSubmit,
}) {
  return (
    <Box mb={2}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Event Name
            </InputLabel>
            <TextField
              value={search}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeSearch(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Event Status
            </InputLabel>
            <Select
              variant="outlined"
              value={timeline}
              onChange={(e) => handleChangeTimeline(e)}
            >
              <MenuItem value={""}>None</MenuItem>
              {eventTimeLine?.map((item) => (
                <MenuItem value={item?.value}>{item?.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Event Option Type
            </InputLabel>
            <Select
              variant="outlined"
              value={eventOptions}
              onChange={(e) => handleChangeEventOptions(e)}
            >
              <MenuItem value={""}>None</MenuItem>
              {eventOptionTypes?.map((item) => (
                <MenuItem value={item?.value}>{item?.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                ampm={true}
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ElapsedEventsFilterForm;
