import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { dialogMainTextStyles, singleDetailsBoxStyle } from "../../utils";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { renderStatus } from "../../utils/statusChip";

function WithdrawalInformation({ data }) {
  return (
    <Grid item xs={12} md={6}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Withdrawal Information
      </Typography>
      <Box my={2}>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Amount:</Typography>
          <NumberFormat
            value={data?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"NGN"}
            renderText={(value, props) => (
              <Typography sx={dialogMainTextStyles} align="left" {...props}>
                {value}
              </Typography>
            )}
          />
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Request ID:</Typography>
          <Typography sx={dialogMainTextStyles}>{data?.requestId} </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Reference ID:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.referenceId}{" "}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Approval Status:</Typography>
          <Box component="span" sx={dialogMainTextStyles}>
            {data?.isApproved ? (
              <Chip color="success" label="Approved" size="small" />
            ) : (
              <Chip label="Not Approved" color="error" size="small" />
            )}
          </Box>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Request Status:</Typography>
          <Box component="span" sx={dialogMainTextStyles}>
            {renderStatus(data?.status)}
          </Box>
        </Box>

        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Tranaction ID:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.transactionId}
          </Typography>
        </Box>

        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Date Created:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default WithdrawalInformation;
