import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  checkExpiredAuthToken,
  formatErrorMessage,
  getAuthToken,
  labelStyles,
} from "../../utils";
import { countryListAllIsoData } from "../../utils/countries";
import FormLoader from "../FormLoader/FormLoader";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import axios from "axios";

function TeamsFilterForm({
  teamName,
  status,
  startDate,
  endDate,
  country,
  leagueId,
  handleChangeStatus,
  handleChangeTeamName,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeCountry,
  handleChangeLeague,
  handleFilterSubmit,
}) {
  const [loading, setLoading] = useState(true);
  const [leaguesData, setLeaguesData] = useState([]);
  const [error, setError] = useState("");
  const url = `${process.env.BASE_URL}game/fetch-leagues?limit=1000&page=1`;

  const fetcher = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLeaguesData(res?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      const errMsg = formatErrorMessage(error);
      setError(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetcher();
  }, []);

  if (error) {
    return <HalfErrorScreen title={"An Error just occured"} subtitle={error} />;
  }
  if (loading) {
    return (
      <>
        <FormLoader />
        <FormLoader />
      </>
    );
  }

  return (
    <Box mb={2}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Name of Team
            </InputLabel>
            <TextField
              value={teamName}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeTeamName(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                ampm={true}
                // label="Last Login Date"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                // label="Last Login Date"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Country
            </InputLabel>
            <Select
              variant="outlined"
              value={country}
              onChange={(e) => handleChangeCountry(e)}
            >
              {countryListAllIsoData?.map((row) => (
                <MenuItem value={row?.name} key={row?.code}>
                  {row?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              League
            </InputLabel>
            <Select
              variant="outlined"
              value={leagueId}
              onChange={(e) => handleChangeLeague(e)}
            >
              {leaguesData?.map((row) => (
                <MenuItem value={row?.leagueId} key={row?.leagueId}>
                  {row?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              variant="outlined"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TeamsFilterForm;
