import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function HoldTicketDialog({
  openDialog,
  handleCloseDialog,
  selectedTicket,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>
          {selectedTicket?.status !== "held"
            ? "CONFIRM TO RELEASE TICKET"
            : "CONFIRM TO HOLD TICKET"}
        </DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            {selectedTicket?.status !== "held" ? (
              <DialogContentText>
                Do you want to release this ticket, click on the release ticket
                button to proceed or, click on the cancel button to ignore
              </DialogContentText>
            ) : (
              <DialogContentText>
                Do you want to hold this ticket, click on the hold ticket button
                to proceed or, click on the cancel button to ignore
              </DialogContentText>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            onClick={() => handleSubmit(selectedTicket)}
            variant="contained"
            color="success"
          >
            {isSubmitting ? (
              "Processing"
            ) : (
              <>
                {selectedTicket?.status !== "held"
                  ? "Release Ticket"
                  : "Hold Ticket"}
              </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
