import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
} from "../../utils";
import { renderStatus } from "../../utils/statusChip";

export default function PreviewRequestModal({
  openDialog,
  handleCloseDialog,
  selectedRequest,
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          SALES REQUEST PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Box my={1}>
                    <Typography sx={dialogMainTextStyles}>
                      <img
                        src={selectedRequest?.imageUrl}
                        alt={selectedRequest?.title}
                        style={{
                          width: "130px",
                          height: "130px",
                          objectFit: "contain",
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Product Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedRequest?.title}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Description:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedRequest?.description}
                    </Typography>
                  </Box>

                  <Box my={1}>
                    <Typography variant="caption">Price:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {currencyFormater(selectedRequest?.price)}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Approval Status:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {renderStatus(selectedRequest?.approvalStatus)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
