import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AppContext } from "../../context";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const context = React.useContext(AppContext);

  return (
    <>
      <Snackbar
        open={context.openSnackbar}
        autoHideDuration={6000}
        onClose={context.handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={context.handleCloseSnackbar}
          severity={context.snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {context.snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
