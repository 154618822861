import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { labelStyles } from "../../utils";

function LogsFilter({
  userName,
  status,
  endDate,
  startDate,
  requestMethod,
  handleChangeRequestMethod,
  handleChangeUserName,
  handleChangeStartDate,
  handleChangeEndDate,
  handleFiterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Admin's Name
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              value={userName}
              fullWidth
              onChange={(e) => handleChangeUserName(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Request Method
            </InputLabel>
            <Select
              // label="Status"
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={requestMethod}
              onChange={(e) => handleChangeRequestMethod(e)}
            >
              <MenuItem value={""}>Select</MenuItem>
              <MenuItem value={"GeT"}>GET</MenuItem>
              <MenuItem value={"POST"}>POST</MenuItem>
              <MenuItem value={"PUT"}>Update</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Activity Date (From)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Activity Date (To)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LogsFilter;
