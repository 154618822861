import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { AppProvider } from "./context";
import { CookiesProvider } from "react-cookie";
import CustomizedSnackbars from "./components/CustomSnackbar/CustomSnackbar";

var mountNode = document.getElementById("app");
ReactDOM.render(
  <>
    <React.StrictMode>
      <CookiesProvider>
        <AppProvider>
          <CustomizedSnackbars />
          <CssBaseline />
          <App />
        </AppProvider>
      </CookiesProvider>
    </React.StrictMode>
  </>,
  mountNode
);
