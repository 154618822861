import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import { tableButtonStyles } from "../../utils";
import TableCSVButton from "../TableCSVButton/TableCSVButton";
import { renderStatus } from "../../utils/statusChip";

function PrizeClaimsTable({
  data,
  page,
  selectedPrize,
  rowsPerPageOptions,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleSelectCategory,
  handleChangePage,
  handleOpenApproveDialog,
  handleOpenPreviewDialog,
  handleOpenRejectClaimDialog,
  totalCount,
}) {
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    handleSelectCategory(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  // const moveToEditGamePage = () => {
  //   navigate(`/edit-event-details/${selectedPrize?.claimId}`);
  // };
  const moveToSeeMorePage = () => {
    navigate(`/sports-bet/prize-details/${selectedPrize?.claimId}`);
  };

  const headCells = [
    "User",
    "Role",
    "Ticket ID",
    "Product",
    "Image",
    "Claim Status",
    // "Odds Dist.",
    "Date Created",
    // "Status",
    "Actions",
  ];
  const headers = [
    "User",
    "Lottery",
    "Weekday",
    "Start Time",
    "End Time",
    "Date Created",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.name,
        item?.lotteryName,
        item?.weekday,
        item?.startTime,
        item?.endTime,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.claimId}>
                  <TableCell>{`${row?.SportTicket?.User?.firstname} ${row?.SportTicket?.User?.lastname}`}</TableCell>
                  <TableCell>{row?.SportTicket?.User?.role}</TableCell>
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>{row?.SportTicket?.Product?.title}</TableCell>
                  <TableCell>
                    <img
                      src={row?.SportTicket?.Product?.imageUrl}
                      alt={row?.SportTicket?.Product?.title}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                      width={50}
                      height={50}
                    />
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {/* {row?.hasClaimedWinning ? (
                      <Chip color="success" label="Claimed" size="small" />
                    ) : (
                      <Chip label="Not Claimed" color="warning" size="small" />
                    )} */}
                    {renderStatus(row?.approvalStatus)}
                  </TableCell>

                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  {/* <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell> */}

                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenPreviewDialog(row)}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      {selectedPrize?.approvalDate ? null : (
                        <MenuItem
                          onClick={() => handleOpenApproveDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Approve Claim
                        </MenuItem>
                      )}
                      {selectedPrize?.approvalDate ? <Divider /> : null}
                      {selectedPrize?.approvalDate ? null : (
                        <MenuItem
                          onClick={() => handleOpenRejectClaimDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Reject Claim
                        </MenuItem>
                      )}

                      <Divider />
                      <MenuItem
                        onClick={() => moveToSeeMorePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        See More
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default PrizeClaimsTable;
