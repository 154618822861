import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formPaperStyle,
  getAuthToken,
  labelStyles,
  labelStylesThree,
} from "../utils";
import { countryListAllIsoData } from "../utils/countries";
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";

let initialValues = {
  leagueName: "",
  country: "",
  customLeagueId: "",
  flagImage: "",
};
function UpdateLeague() {
  const [selectedImage, setSelectedImage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leagueData, setLeagueData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { handleOpenSnackbar } = useContext(AppContext);
  const navigate = useNavigate();
  const { leagueId } = useParams();
  const singleLeagueURL = `${process.env.BASE_URL}game/fetch-league/${leagueId}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const formData = new FormData();

      formData.append("leagueName", values.leagueName);
      formData.append("country", values.country);
      formData.append("customLeagueId", values.customLeagueId);
      if (values.flagImage) {
        formData.append("flagImage", values.imageUrl);
      }
      axios
        .put(
          `${process.env.BASE_URL}game/update-league/${leagueId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "League Updated Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, League Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      leagueName: yup.string().required().label("League Name"),
      country: yup.string().required().label("Country"),
      customLeagueId: yup.string().required().label("League ID"),
    }),
  });
  const fetcherSingleTeam = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(singleLeagueURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLeagueData(res?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log("error fetching lottery data", error);
    }
  };

  useEffect(() => {
    fetcherSingleTeam();
  }, []);

  console.log(leagueData);
  if (leagueData?.name) {
    initialValues.leagueName = leagueData?.name;
    initialValues.country = leagueData?.country;
    initialValues.customLeagueId = leagueData?.customLeagueId;
    initialValues.flagImage = leagueData?.flagImage;
  }

  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={error.message}
      />
    );

  if (loading) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update League - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update League" />

        <Paper sx={formPaperStyle}>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box
                my={2}
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>Select Image of Flag</FormLabel>
                  <br />
                  <input
                    type="file"
                    name="imageUrl"
                    accept="image/*"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("imageUrl", e.target.files[0]);
                      setSelectedImage(e.target.files[0]);
                    }}
                  />
                </FormControl>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {selectedImage ? (
                    <>
                      {" "}
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        style={{
                          width: "40px",
                        }}
                        alt="flag"
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Typography>Prev Image</Typography>{" "}
                      <img
                        src={leagueData?.flagImage}
                        style={{
                          width: "40px",
                        }}
                        alt="flag"
                      />
                    </>
                  )}
                </Box>
              </Box>

              <Box my={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formik.touched.category && formik.errors.category}
                >
                  <InputLabel variant="outlined" sx={labelStylesThree}>
                    Select Country
                  </InputLabel>
                  {/* <br />
                  <br /> */}
                  <Select
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  >
                    {countryListAllIsoData?.map((country, idx) => (
                      <MenuItem
                        key={`122${idx}${country?.name}`}
                        value={country?.name}
                      >
                        {country?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.country && formik.errors.country}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Name of League"
                    error={
                      formik.touched.leagueName && formik.errors.leagueName
                    }
                    name="leagueName"
                    variant="outlined"
                    value={formik.values.leagueName}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.leagueName && formik.errors.leagueName
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Id of League"
                    error={
                      formik.touched.customLeagueId &&
                      formik.errors.customLeagueId
                    }
                    name="customLeagueId"
                    variant="outlined"
                    value={formik.values.customLeagueId}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.customLeagueId &&
                      formik.errors.customLeagueId
                    }
                  />
                </FormControl>
              </Box>

              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Processing" : " Update League"}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </AdminLayout>
  );
}

export default UpdateLeague;
