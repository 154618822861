import { useState, useContext, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";

import Files from "react-files";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formPaperStyle,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  safeJSONParse,
  GHANA_GAME,
  GHANA_GAME_V2,
  WESTERN_INDOOR_GAME,
  WESTERN_INDOOR_GAME_V2,
  GHANA_GAME_V2_BETOPTIONS,
  WESTERN_INDOOR_GAME_V2_BETOPTIONS,
  WESTERN_INDOOR_GAME_BETOPTIONS,
  GHANA_GAME_BETOPTIONS,
  formatErrorMessage,
  lottoProductCategoryArray,
} from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import { useNavigate, useParams } from "react-router-dom";
import FormLoader from "../components/FormLoader/FormLoader";
import { ROUTE_LINKS } from "../utils/routeLinks";

function UpdateLottoProduct() {
  const [productImage, setProductImage] = useState("");
  const [gameName, setGameName] = useState("");
  const [searchedGames, setSearchedGames] = useState([]);
  const [isSearchingGame, setIsSearchingGame] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [selectedGame, setSelectedGame] = useState(false);
  const [loadingBetOption, setLoadingBetOption] = useState(false);
  const [betOption, setBetOption] = useState([]);
  const [eventError, setEventError] = useState("");
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [productData, setProductData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedBetType, setSelectedBetType] = useState("");
  const [betTypeSettings, setBetTypeSettings] = useState(null);
  const [gameAllowedOptions, setGameAllowedOptions] = useState([]);

  const { handleOpenSnackbar } = useContext(AppContext);
  const btnRef = useRef();

  const { productId } = useParams();
  const navigate = useNavigate();
  const productUrl = `${process.env.BASE_URL}lotto-product/${productId}`;
  const gameUrl = `${process.env.BASE_URL}game/fetch-games?limit=25&page=1&search=${gameName}`;

  const url = `${
    process.env.BASE_URL
  }categories/fetch-categories?page=${1}&limit=${1000}&status=true`;

  const handleChangeImage = (files) => {
    setProductImage((curr) => files[0]);
  };
  const handleErrorImage = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const handleSearchGames = async () => {
    try {
      setIsSearchingGame(true);
      setBetOption([]);
      const token = getAuthToken();
      const res = await axios.get(gameUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSearchedGames(res?.data?.data?.data);
    } catch (err) {
      if (err?.response?.data?.responsemessage?.msg) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.msg,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      return handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    } finally {
      setIsSearchingGame(false);
      setHasSearchedGames(true);
    }
  };
  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOptions = async () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    const parsed = safeJSONParse(selectedGame?.Lottery?.betOptions);
    setGameAllowedOptions(parsed);
    if (selectedGame?.Lottery?.category === "meta4") {
      return await getBetOption("meta4-bet-options");
    } else if (selectedGame?.Lottery?.category === "cash-out") {
      return await getBetOption("cash-out-bet-options");
    } else if (selectedGame?.Lottery?.category === "eazy-win") {
      return await getBetOption("eazy-win-bet-options");
    } else if (selectedGame?.Lottery?.category === "perfect-balls") {
      return await getBetOption("perfect-balls-bet-options");
    } else if (selectedGame?.Lottery?.category === "lotto") {
      return await getBetOption("lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === "metro") {
      return await getBetOption("metro-bet-options");
    } else if (selectedGame?.Lottery?.category === GHANA_GAME) {
      return await getBetOption(GHANA_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME) {
      return await getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === GHANA_GAME_V2) {
      return await getBetOption(GHANA_GAME_V2_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME_V2) {
      return await getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    }
  };
  let initialValues = {
    title: "",
    customProductId: "",
    imageUrl: "",
    fixedStakedAmount: 1,
    cashEquivalence: 1,
    category: "",
    description: "",
  };
  const fetchSingleProduct = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(productUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setProductData(res?.data?.data?.data);
      setLoadingEvent(false);
    } catch (error) {
      setLoadingEvent(false);
      setEventError(error);
      console.log("error fetching lottery data", error);
    }
  };

  useEffect(() => {
    fetchSingleProduct();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb", values);
      const found = betOption?.find((item) => item?.value === values?.betType);

      let newValues = {
        title: values.title,
        fixedStakedAmount: parseInt(values.fixedStakedAmount),
        maximumSelectionCount: values.maximumSelectionCount,
        cashEquivalence: parseInt(values.cashEquivalence),
        description: values.description,
        category: values.category,
        gameId: values.gameId,
        betType: selectedBetType,
        betTypeSettings: JSON.stringify(found),
      };

      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      if (productImage) {
        const foreground = await handleSubmitImage(
          productImage,
          "Sorry, an error occured while uploading the category icon image"
        );
        newValues.imageUrl = foreground;
      }
      if (selectedBetType) {
        newValues.betType = selectedBetType;
      }
      if (selectedGame?.gameId) {
        newValues.gameId = selectedGame?.gameId;
      }

      axios
        .put(`${process.env.BASE_URL}lotto-product/${productId}`, newValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Product Updated Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            navigate(ROUTE_LINKS.LOTTO_PRODUCTS);
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          const errMsg = formatErrorMessage(err);

          handleOpenSnackbar({
            message: errMsg,
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      customProductId: yup.string().required().label("Product ID"),
      description: yup.string().required().label("Description"),
      cashEquivalence: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Cash Equivalence"),
    }),
  });
  // console.log("sssssssssssssssssss", productData);
  if (productData?.title) {
    initialValues.title = productData?.title;
    initialValues.customProductId = productData?.customProductId;
    initialValues.imageUrl = productData?.imageUrl;
    initialValues.fixedStakedAmount = productData?.fixedStakedAmount;
    initialValues.cashEquivalence = productData?.cashEquivalence;
    initialValues.category = productData?.ProductCategory?.category;
    initialValues.description = productData?.description;
  }
  const { data, error } = useSWR(productUrl, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update Lotto Product - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Lotto Product" />
        <div>
          <button
            style={{ display: "none" }}
            onClick={() => getOptions()}
            ref={btnRef}
          >
            Get Bet Options
          </button>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Paper sx={formPaperStyle}>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>
                    Select Display Image for Product
                  </FormLabel>
                  <br />
                  <Files
                    className="dropzone"
                    onChange={handleChangeImage}
                    onError={handleErrorImage}
                    accepts={["image/*"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Typography gutterBottom align="center">
                      Drop file here or click to upload image of product
                    </Typography>
                    {productImage ? (
                      <Box>
                        <img
                          src={URL.createObjectURL(productImage)}
                          className="imagePreview"
                          alt="product image"
                        />
                      </Box>
                    ) : !productImage && productData?.imageUrl ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "7px",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Prev Image</Typography> &nbsp;{" "}
                        <img
                          src={productData?.imageUrl}
                          className="imagePreview"
                          alt="product image"
                        />
                      </Box>
                    ) : null}
                  </Files>
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formik.touched.category && formik.errors.category}
                >
                  <FormLabel variant="outlined">Select Category</FormLabel>
                  <Select
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                  >
                    {lottoProductCategoryArray?.map((item, idx) => (
                      <MenuItem
                        key={`122${idx}${item?.category}`}
                        value={item?.value}
                      >
                        {item?.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.category && formik.errors.category}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Name of Product"
                        error={formik.touched.title && formik.errors.title}
                        name="title"
                        variant="outlined"
                        value={formik.values.title}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={formik.touched.title && formik.errors.title}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="ID of Product"
                        error={
                          formik.touched.customProductId &&
                          formik.errors.customProductId
                        }
                        name="customProductId"
                        variant="outlined"
                        value={formik.values.customProductId}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.customProductId &&
                          formik.errors.customProductId
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Fixed Staked Amount"
                        error={
                          formik.touched.fixedStakedAmount &&
                          formik.errors.fixedStakedAmount
                        }
                        name="fixedStakedAmount"
                        type="number"
                        variant="outlined"
                        value={formik.values.fixedStakedAmount}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.fixedStakedAmount &&
                          formik.errors.fixedStakedAmount
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Cash Equivalence of Product"
                        error={
                          formik.touched.cashEquivalence &&
                          formik.errors.cashEquivalence
                        }
                        name="cashEquivalence"
                        type="number"
                        variant="outlined"
                        value={formik.values.cashEquivalence}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.cashEquivalence &&
                          formik.errors.cashEquivalence
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Description of Product"
                    error={
                      formik.touched.description && formik.errors.description
                    }
                    name="description"
                    variant="outlined"
                    multiline
                    value={formik.values.description}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>

              <Box></Box>
            </Paper>

            <br />
            <Paper sx={formPaperStyle}>
              <Box my={1}>
                <Typography variant="h6">Game Information</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={9} md={10}>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter the name of Game"
                        name="gameName"
                        variant="outlined"
                        value={gameName}
                        fullWidth
                        onChange={(e) => setGameName(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={3} md={2} alignSelf={"center"}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => handleSearchGames()}
                    disabled={isSearchingGame}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              <Box my={1}>
                {isSearchingGame ? (
                  <FormLoader />
                ) : !isSearchingGame && !hasSearchedGames ? null : (
                  <Box>
                    {searchedGames &&
                    searchedGames?.length > 0 &&
                    hasSearchedGames ? (
                      <Box my={1}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStylesTwo}>
                            Games
                          </InputLabel>
                          <br />
                          <RadioGroup
                            aria-label="Games"
                            row
                            name="searchedGamess"
                          >
                            {searchedGames &&
                              searchedGames?.length > 0 &&
                              searchedGames?.map((item, idx) => (
                                <FormControlLabel
                                  key={`${item?.name}${idx}`}
                                  value={item.gameId}
                                  label={item?.name}
                                  variant="outlined"
                                  onChange={async (e) => {
                                    setSelectedGame((curr) => item);
                                    formik.setFieldValue(
                                      "gameId",
                                      e.target.value
                                    );
                                    setTimeout(() => {
                                      return btnRef.current.click();
                                    }, 700);
                                  }}
                                  control={<Radio />}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    ) : (
                      <Typography>Sorry, No Game match your search</Typography>
                    )}
                  </Box>
                )}
              </Box>
              <Box my={1}>
                {loadingBetOption ? (
                  <FormLoader />
                ) : (
                  <Box>
                    {betOption && betOption?.length > 0 && (
                      <Box my={1}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStylesTwo}>
                            Bet Option
                          </InputLabel>
                          <br />
                          <RadioGroup
                            aria-label="Bet Option"
                            row
                            name="betType"
                          >
                            {gameAllowedOptions &&
                              gameAllowedOptions?.length > 0 &&
                              gameAllowedOptions?.map((item, idx) => (
                                <FormControlLabel
                                  key={`${item?.name}${idx}`}
                                  value={item.name}
                                  label={item?.name}
                                  variant="outlined"
                                  onChange={(e) => {
                                    setSelectedBetType(e?.target?.value);
                                  }}
                                  control={<Radio />}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Paper>
            <Paper sx={formPaperStyle}>
              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Updating Product" : " Update Product"}
              </Button>
            </Paper>
          </form>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default UpdateLottoProduct;
