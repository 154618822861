import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { boxStyle5 } from "../../utils";

function EventTeams({ data }) {
  return (
    <Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Home Team:</Typography>
        <Typography align="left">{data && data?.HomeTeam?.name}</Typography>
      </Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Home Team Alias:</Typography>
        <Typography align="left">{data && data?.HomeTeam?.alias}</Typography>
      </Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Home Team ID:</Typography>
        <Typography align="left">{data && data?.HomeTeam?.id}</Typography>
      </Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Away Team:</Typography>
        <Typography align="left">{data && data?.AwayTeam?.name}</Typography>
      </Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Away Team Alias:</Typography>
        <Typography align="left">{data && data?.AwayTeam?.alias}</Typography>
      </Box>
      <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
        <Typography color="text.secondary">Away Team ID:</Typography>
        <Typography align="left">{data && data?.AwayTeam?.id}</Typography>
      </Box>
    </Box>
  );
}

export default EventTeams;
