import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import ProductInformation from "../components/PrizeDetailsComponents/ProductInformation";
import PlayerInformation from "../components/PrizeDetailsComponents/PlayerInformation";
import PaymentConfigTable from "../components/PrizeDetailsComponents/PaymentConfigTable";

function PrizeDetails() {
  const { claimId } = useParams();

  const url = `${process.env.BASE_URL}sport/fetch-ticket-claim/${claimId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`claim's details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Claim's details" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <PlayerInformation data={data?.SportTicket?.User} />
          </Grid>

          <Grid item xs={12} md={5}>
            <ProductInformation data={data} />
          </Grid>
        </Grid>
      </Container>

      <Box mt={2}>
        <Typography gutterBottom variant="h6">
          Payment Config Information
        </Typography>
        <PaymentConfigTable data={data?.SportTicket?.paymentConfigs} />
      </Box>
    </AdminLayout>
  );
}

export default PrizeDetails;
