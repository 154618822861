import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const HalfErrorScreen = ({ title, subtitle }) => {
  return (
    <Box component={Paper} sx={{ padding: "20px 10px" }}>
      <Typography gutterBottom variant="body1">
        {title}
      </Typography>
      <Typography gutterBottom variant="body2" sx={{ color: "red" }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default HalfErrorScreen;
