import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

export default function PreviewGameInstanceModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.BASE_URL}game/fetch-game/${selectedGame?.gameId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, data cannot be retrieved at this momment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          GAME INSTANCE PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Instance Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.name}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption"> Start Time:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.startTime}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">End Time:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.endTime}
                    </Typography>
                  </Box>

                  <Box my={1}>
                    <Typography variant="caption">Game Status:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip label="Not Active" color="error" size="small" />
                      )}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Week Day:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.weekday}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Date Created:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.createdAt).format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Other Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Game Count:</Typography>
                    <NumberFormat
                      value={data?.Lottery?.gameCount}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Result Count:</Typography>
                    <NumberFormat
                      value={data?.Lottery?.resultCount}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Category Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.Lottery?.name}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Category:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.Lottery?.category}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box my={1}>
              <Typography variant="caption">Description:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {data?.description}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
