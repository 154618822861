import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { boxStyle5 } from "../../utils";

const GameInformation = ({ game, betType }) => {
  return (
    <Box>
      <Typography variant="h6" fontWeight={500} gutterBottom>
        Game Information
      </Typography>
      <Box my={1}>
        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Name:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {game && game?.name}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Bet Type:</Typography>
          <Typography align="left">{betType && betType}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Lottery:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {game && game?.lotteryName}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Draw Method:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {game && game?.drawMethod}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Description:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {game && game?.description}
          </Typography>
        </Box>

        <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
          <Typography color="text.secondary">Game ID:</Typography>
          <Typography align="left">{game && game?.gameId}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GameInformation;
