import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  limit,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";

import { AppContext } from "../context";
import BonusTable from "../components/BundleBonusComponents/BonusTable";
import PreviewBonusModal from "../components/BundleBonusComponents/PreviewBonusModal";
import ToggleBonusDialog from "../components/BundleBonusComponents/ToggleBonusDialog";
import AttachInfluencerModal from "../components/BundleBonusComponents/AttachInfluencerModal";

function BundleBonuses() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAttachDialog, setOpenAttachDialog] = useState(false);
  const [openToggleDialog, setOpenToggleDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState({});

  const navigate = useNavigate();

  const url = `${process.env.BASE_URL}bonus/fetch-all-bonuses?limit=${limit}&page=${page}&type=bundle-bonus`;
  let filterURL = `${
    process.env.BASE_URL
  }bonus/fetch-all-bonuses?limit=${limit}&page=${page}&type=bundle-bonus${
    search && `&search=${search}`
  }`;
  if (status) {
    filterURL = filterURL + `&status=${status}`;
  }

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleChangeStatus = (e) => setStatus(e.target.value);
  const handleChangeSearch = (e) => setSearch(e.target.value);

  const handleSelectBonus = (page) => setSelectedBonus(page);
  const handleCloseDialog = () => {
    if (isSubmitting) return;
    setOpenDialog(false);
  };
  const handleOpenDialog = () => setOpenDialog(true);

  const handleCloseToggleDialog = () => {
    if (isSubmitting) return;
    setOpenToggleDialog(false);
  };
  const handleOpenToggleDialog = () => setOpenToggleDialog(true);
  const handleOpenAttachDialog = () => setOpenAttachDialog(true);
  const handleCloseAttachDialog = () => setOpenAttachDialog(false);
  const handleResetFields = () => {
    setStatus("");
    setSearch("");
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsFiltering(true);
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url, res.data?.data, false);
        setIsFiltering(false);
        return setHasFiltered(true);
      })
      .catch((err) => {
        setIsFiltering(false);
        return err;
      });
  };

  const handleSubmitToggleStatus = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsSubmitting(true);
    let values = {
      bonusId: selectedBonus?.bonusId,
    };
    let submiitURL =
      selectedBonus && selectedBonus.status
        ? `${process.env.BASE_URL}bonus/deactivate-bonus`
        : `${process.env.BASE_URL}bonus/activate-bonus`;
    return axios
      .post(submiitURL, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);

        if (res?.data?.data?.message) {
          handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }

        mutate(url);
        return handleCloseToggleDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.message,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, An Unexpected Error Occured",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.BASE_URL}bonus/fetch-all-bonuses?limit=${limit}&page=${
          newPage + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        mutate(url, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancelFilters = () => {
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, data cannot be retrieved at this time"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`Bundle Bonus Configurations - Admin Dashboard`} />
      <Container maxWidth="xl">
        <PageHeading title="Bundle Bonus" />
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            onClick={() => navigate("/influencers/add-bundle")}
          >
            Add Bonus
          </Button>
        </Box>
        {openDialog ? (
          <PreviewBonusModal
            selectedBonus={selectedBonus}
            openDialog={openDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDialog}
          />
        ) : null}
        {openToggleDialog ? (
          <ToggleBonusDialog
            selectedBonus={selectedBonus}
            openDialog={openToggleDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseToggleDialog}
            handleSubmit={handleSubmitToggleStatus}
          />
        ) : null}{" "}
        {openAttachDialog ? (
          <AttachInfluencerModal
            selectedBonus={selectedBonus}
            openDialog={openAttachDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseAttachDialog}
          />
        ) : null}
        <BonusTable
          page={page}
          selectedBonus={selectedBonus}
          handleSelectBonus={handleSelectBonus}
          handleChangePage={handleChangePage}
          handleOpenDialog={handleOpenDialog}
          handleOpenToggleDialog={handleOpenToggleDialog}
          handleOpenAttachDialog={handleOpenAttachDialog}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default BundleBonuses;
