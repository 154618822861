import React, { useState, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Files from "react-files";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { checkExpiredAuthToken, getAuthToken } from "../utils";
import "../styles/AddProductCategory.css";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import AddSportProduct from "../components/AddProduct/AddSportProduct";
import AddLottoProduct from "../components/AddProduct/AddLottoProduct";

export const eventTypesList = [
  { label: "Real Sport", value: "real-sport" },
  { label: "Virtual Sport", value: "virtual-sport" },
];
const productTypes = {
  SPORTS: "SPORTS",
  LOTTO: "LOTTO",
};
function AddProduct() {
  const [selectedProductType, setSelectedProductType] = useState(
    productTypes.SPORTS
  );

  const url = `${
    process.env.BASE_URL
  }categories/fetch-categories?page=${1}&limit=${1000}&status=true`;

  const handleChangeTab = (event, newValue) => {
    setSelectedProductType(newValue);
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher);
  // console.log("dddddddddd", data);
  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Add Product - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Add Product" />
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedProductType}
              onChange={handleChangeTab}
              centered
            >
              <Tab label="Sports Products" value={productTypes.SPORTS} />
              <Tab label="Lotto Products" value={productTypes.LOTTO} />
            </Tabs>
          </Box>
          <Box sx={{ mt: 1 }}>
            {selectedProductType === productTypes.SPORTS && (
              <AddSportProduct categories={data} />
            )}
            {selectedProductType === productTypes.LOTTO && (
              <AddLottoProduct categories={data} />
            )}
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default AddProduct;
