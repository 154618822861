import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import PreviewDealModal from "../components/DealTickets/PreviewDealModal";
import RealEventDeals from "../components/DealTickets/RealEventsDeals";
import VirtualEventDeals from "../components/DealTickets/VirtualEventDeals";

const REAL_DEALS = "REAL_DEALS";
const VIRTUAL_DEALS = "VIRTUAL_DEALS";
function DealsTickets() {
  const [value, setValue] = useState(REAL_DEALS);
  const [selectedTicket, setSelectedTicket] = React.useState({});
  const [openPreviewTicket, setOpenPreviewTicket] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Deals Tickets Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Deals Tickets" />
        {openPreviewTicket ? (
          <PreviewDealModal
            openDialog={openPreviewTicket}
            selectedTicket={selectedTicket}
            handleCloseDialog={handleClosePreviewTicket}
          />
        ) : null}
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          sx={{ color: "green", marginTop: "5px" }}
        >
          <Tab label="Real Deals" value={REAL_DEALS} />
          <Tab label="Virtual Deals" value={VIRTUAL_DEALS} />
        </Tabs>
        {value === REAL_DEALS && <RealEventDeals value={value} />}
        {value === VIRTUAL_DEALS && <VirtualEventDeals value={value} />}
      </Container>
    </AdminLayout>
  );
}

export default DealsTickets;
