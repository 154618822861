import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { currencyFormater, singleDetailsBoxStyle } from "../../utils";
import dayjs from "dayjs";
import { renderStatus } from "../../utils/statusChip";

function ProductInformation({ data }) {
  return (
    <>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Product Information
      </Typography>
      <Box py={2}>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Ticket ID:</Typography>
          <Typography align="left">{data?.ticketId}</Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Staked Amount:</Typography>
          <Typography align="left">
            NGN
            {currencyFormater(data?.SportTicket?.totalStakedAmount)}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Won Amount:</Typography>
          <Typography align="left">
            NGN
            {currencyFormater(data?.SportTicket?.totalWonAmount)}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Status:</Typography>
          {renderStatus(data?.SportTicket?.status)}
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Product Name:</Typography>
          <Typography>{data?.SportTicket?.Product?.title} </Typography>
        </Box>
        {data?.approvalDate && (
          <>
            <Box my={1} sx={singleDetailsBoxStyle}>
              <Typography>Date Approved:</Typography>
              {dayjs(data?.approvalDate).format("DD MMM YYYY, HH:mma")}
            </Box>
          </>
        )}
        <Box my={1} sx={singleDetailsBoxStyle}>
          <img
            src={data?.SportTicket?.Product?.imageUrl}
            alt={data?.SportTicket?.Product?.title}
            width={100}
            height={100}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "contain",
              overflow: "hidden",
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default ProductInformation;
