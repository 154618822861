import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { boldTextCalculationStyles, safeJSONParse } from "../../utils";

function BetSlipsTable({ data, totalStakedAmount }) {
  // console.log("data", data);
  let betSlips = safeJSONParse(data);
  const headCells = [
    "Bet Type",
    "Amount",
    "Booster Method",
    "Result Type",
    "Selection",
    "Over Under",
    "Agent Commission",
    "Affiliate",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {betSlips &&
              betSlips?.length > 0 &&
              betSlips?.map((row, idx) => (
                <TableRow key={`${row?.betType}99${idx}`}>
                  <TableCell>{row?.betType}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={parseFloat(row?.amount).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>

                  <TableCell>{row?.booster}</TableCell>
                  <TableCell>{row?.resultType}</TableCell>
                  <TableCell>{row?.selections}</TableCell>
                  <TableCell>{row?.overUnder?.over}</TableCell>
                  <TableCell>
                    {row?.agentCommission
                      ? parseFloat(row?.agentCommission).toFixed(2)
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row?.affiliate
                      ? parseFloat(row?.affiliate).toFixed(2)
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell sx={boldTextCalculationStyles}>Total</TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={totalStakedAmount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"NGN"}
                  renderText={(value, props) => (
                    <Typography
                      sx={boldTextCalculationStyles}
                      align="left"
                      {...props}
                    >
                      {value}
                    </Typography>
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {betSlips && betSlips?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default BetSlipsTable;
