import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";

export default function ChangeDeviceIDDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
  agentsURL,
}) {
  const [submitting, setSubmitting] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      registeredDeviceId: 1,
    },
    onSubmit: async (values, helpers) => {
      setSubmitting(true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      axios
        .put(
          `${process.env.BASE_URL}agent/update-profile/${selectedUser?.userId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          mutate(agentsURL);
          setSubmitting(false);
          handleCloseDialog();
          return handleOpenSnackbar({
            message: "Device ID Changed Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          setSubmitting(false);
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          if (err?.response?.data?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Device ID Could Not Be Changed",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      registeredDeviceId: yup.string().required().label("Device ID"),
    }),
  });

  return (
    <div>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>
            CHANGE DEVICE ID
          </DialogTitle>
          <DialogContent>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Enter Device ID"
                  value={formik.values.registeredDeviceId}
                  error={
                    formik.touched.registeredDeviceId &&
                    formik.errors.registeredDeviceId
                  }
                  name="registeredDeviceId"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.registeredDeviceId &&
                    formik.errors.registeredDeviceId
                  }
                />
              </FormControl>
            </Box>

            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "15px" }}>
            <Button
              type="button"
              disabled={submitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={submitting}
              startIcon={submitting && <CircularProgress size={"1rem"} />}
              variant="contained"
              color="primary"
            >
              {submitting ? "Processing" : "Submit"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
