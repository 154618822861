import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";

export default function GameStatisticsModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.BASE_URL}game/fetch-result-statistics?category=${selectedGame?.Lottery?.category}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: res?.data?.data?.message,
          severity: "success",
        });
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    dedupingInterval: 10000,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, game statistics data cannot be retrieved at this momment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          GAME STATISTICS
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Box my={1}>
                  <Box my={2}>
                    <Typography variant="body2" gutterBottom>
                      High Frequency Numbers:
                    </Typography>
                    {data?.highFrequencyNumbers?.length > 0 ? (
                      data?.highFrequencyNumbers?.map((item, index) => (
                        <Typography
                          key={`${item}pp${index}`}
                          component="span"
                          variant="h5"
                          color="black"
                        >
                          {item}
                          {index !== data?.highFrequencyNumbers?.length - 1
                            ? ", "
                            : "."}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="h5" color="black">
                        No draw/result has been released
                      </Typography>
                    )}
                  </Box>
                  <Box my={2}>
                    <Typography variant="body2" gutterBottom>
                      Low Frequency Numbers:
                    </Typography>
                    {data?.lowFrequencyNumbers?.length > 0 ? (
                      data?.lowFrequencyNumbers?.map((item, index) => (
                        <Typography
                          key={`${item}pp${index}`}
                          component="span"
                          variant="h5"
                          color="black"
                        >
                          {item}
                          {index !== data?.lowFrequencyNumbers?.length - 1
                            ? ", "
                            : "."}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="h5" color="black">
                        No draw/result has been released
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
