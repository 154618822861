import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import ElapsedVirtualEvents from "./ElapsedVirtualEvents";
import ElapsedRealEvents from "./ElapsedRealEvents";

const REAL_EVENTS = "REAL_EVENTS";
const VIRTUAL_EVENTS = "VIRTUAL_EVENTS";

const ElapesedEvents = () => {
  const [value, setValue] = useState(REAL_EVENTS);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <div>
        <MetaDecorator title="Elapesed Events - Admin Dashboard" />
        <PageHeading title="Elapesed Events" />
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          sx={{ color: "green", marginTop: "5px" }}
        >
          <Tab label="Real Events" value={REAL_EVENTS} />
          <Tab label="Virtual Events" value={VIRTUAL_EVENTS} />
        </Tabs>
        {value === REAL_EVENTS && <ElapsedRealEvents value={value} />}
        {value === VIRTUAL_EVENTS && <ElapsedVirtualEvents value={value} />}
      </Box>
    </AdminLayout>
  );
};

export default ElapesedEvents;
