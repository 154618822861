import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { useFormik, Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWRImmutable from "swr/immutable";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  safeJSONParse,
  WESTERN_INDOOR_GAME,
  GHANA_GAME,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_RESULTOPTIONS,
  GHANA_GAME_OVERUNDER_OPTIONS,
  WESTERN_INDOOR_GAME_BETOPTIONS,
  WESTERN_INDOOR_GAME_RESULTOPTIONS,
  WESTERN_INDOOR_GAME_OVERUNDER_OPTIONS,
  GHANA_GAME_V2_BETOPTIONS,
  GHANA_GAME_V2_RESULTOPTIONS,
  GHANA_GAME_V2_OVERUNDER_OPTIONS,
  WESTERN_INDOOR_GAME_V2_BETOPTIONS,
  WESTERN_INDOOR_GAME_V2_RESULTOPTIONS,
  WESTERN_INDOOR_GAME_V2_OVERUNDER_OPTIONS,
  GHANA_GAME_V2,
  WESTERN_INDOOR_GAME_V2,
  METRO,
  EAZY_WIN,
  PERFECT_BALLS,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FormLoader from "../components/FormLoader/FormLoader";
import produce from "immer";

function EditGame() {
  const [selectedSlug, setSelectedSlug] = React.useState("");
  const [booster, setBoosterOption] = React.useState([]);
  const [betOption, setBetOption] = React.useState([]);
  const [betOptionsValues, setBetOptionsValues] = React.useState([]);
  const [resultOptionsValues, setResultOptionsValues] = React.useState([]);
  const [overUnderOptionsValues, setOverUnderOptionsValues] = React.useState(
    []
  );
  const [over, setOverOption] = React.useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = React.useState([]);
  const [loadingbooster, setLoadingBoosterOption] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [singleLotteryData, setSingleLotteryData] = React.useState({});
  const [loadingSingleLotteryData, setLoadingSingleLotteryData] =
    React.useState(false);
  const [loadingbetOption, setLoadingBetOption] = React.useState(false);
  const [loadingover, setLoadingOverOption] = React.useState(false);
  const [resultTypes, setResultTypes] = React.useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const specialInputRef = React.useRef();

  const { lotteryId } = useParams();

  const singleLotteryURL = `${process.env.BASE_URL}game/fetch-lottery/${lotteryId}`;
  const settingsURL = `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/game-categories`;

  // Bet Options Onchange handler
  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);
    const found = checkedBetOptions.find((element) => value === element?.name);

    // console.log("nnnnnnnnnn", found);
    if (index === -1 && !found) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: "" },
      ]);
    } else if (found) {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  // Result Options Onchange Handler
  const handleResultOptionsChange = (e) => {
    const value = e.target.value;
    const index = resultOptionsValues?.indexOf(value);
    if (index === -1) {
      setResultOptionsValues((prevValue) => [...prevValue, value]);
    } else {
      setResultOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  // Over/Under Options Onchange Handler
  const handleOverUnderOptionsChange = (e) => {
    const value = e.target.value;
    // console.log("valuess", value);
    const index = overUnderOptionsValues?.indexOf(value);
    // console.log("wwwwwwwwwwwww", index);
    if (index === -1) {
      // console.log("aaaaaaaaaaaaaa");
      setOverUnderOptionsValues((prevValue) => [...prevValue, value]);
      // console.log("bbbbbbbbbbbbbb");
    } else {
      setOverUnderOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  const fetcherSingleLottery = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(singleLotteryURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await setSelectedSlug((prevState) => res?.data?.data?.data?.category);
      const category = res?.data?.data?.data?.category;
      if (category === "meta4") {
        await getmeta4Options();
      } else if (category === METRO) {
        await getMetroOptions();
      } else if (category === EAZY_WIN) {
        await getEasyWinOptions();
      } else if (category === PERFECT_BALLS) {
        await getPerfectBallsOptions();
      } else if (category === GHANA_GAME) {
        await getGhanaOptions();
      } else if (category === WESTERN_INDOOR_GAME) {
        await getWesternIndoorOptions();
      } else if (category === GHANA_GAME_V2) {
        await getGhanaOptionsV2();
      } else if (category === WESTERN_INDOOR_GAME_V2) {
        await getWesternIndoorOptionsV2();
      }
      let parsedOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      setSingleLotteryData(res?.data?.data?.data);
      setCheckedBetOptions(parsedOptions);
      let tempData, betOptions;
      betOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      tempData = betOptions.map((option) => option?.name);
      setBetOptionsValues(tempData);

      setResultOptionsValues(
        safeJSONParse(res?.data?.data?.data?.resultOptions)
      );
      // setOverUnderOptionsValues(
      //   safeJSONParse(res?.data?.data?.data?.overUnderOptions)
      // );
      setLoadingSingleLotteryData(false);
    } catch (error) {
      setLoadingSingleLotteryData(false);
      console.log("error fetching lottery data", error);
    }
  };

  React.useEffect(() => {
    fetcherSingleLottery();
  }, []);
  // console.log("nnnnnnnnn", overUnderOptionsValues);
  let initialValues = {
    lotteryName: "",
    category: "",
    slug: "",
    imageUrl: "",
    audioUrl: "",
    resultOptions: [],
    betOptions: [],
  };
  if (singleLotteryData && singleLotteryData?.lotteryId) {
    try {
      initialValues.lotteryName = singleLotteryData?.name;
      initialValues.category = singleLotteryData?.category;
      initialValues.slug = singleLotteryData?.slug;
      initialValues.imageUrl = singleLotteryData?.imageUrl;
    } catch (error) {
      console.log("ee", error);
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      checkExpiredAuthToken();
      const token = getAuthToken();

      helpers.setSubmitting(true);
      setIsSubmitting((prevState) => true);
      values.overOptions = JSON.stringify(overUnderOptionsValues);
      values.betOptions = JSON.stringify(checkedBetOptions);
      values.resultOptions = JSON.stringify(resultOptionsValues);
      values.category = selectedSlug !== "5/90" ? selectedSlug : "lotto";
      const formData = new FormData();
      // return console.log("resutl uot", values.resultOptions);

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("imageUrl", values.imageUrl);
      formData.append("audioUrl", values.audioUrl);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("overUnderOptions", values.overOptions);

      axios
        .put(
          `${process.env.BASE_URL}game/update-lottery/${lotteryId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          helpers.resetForm();
          window.scrollTo(0, 0);
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Game Updated Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
    }),
  });
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const getOptions = () => {
    if (selectedSlug === "meta4") {
      getmeta4Options();
    } else if (selectedSlug === "cash-out") {
      getCashOutOptions();
    } else if (selectedSlug === EAZY_WIN) {
      getEasyWinOptions();
    } else if (selectedSlug === PERFECT_BALLS) {
      getPerfectBallsOptions();
    } else if (selectedSlug === "metro") {
      getMetroOptions();
    } else if (selectedSlug === GHANA_GAME) {
      getGhanaOptions();
    } else if (selectedSlug === WESTERN_INDOOR_GAME) {
      getWesternIndoorOptions();
    } else if (selectedSlug === GHANA_GAME_V2) {
      getGhanaOptionsV2();
    } else if (selectedSlug === WESTERN_INDOOR_GAME_V2) {
      getWesternIndoorOptionsV2();
    }
  };

  const getmeta4Options = () => {
    setResultTypes([]);
    getBetOption("meta4-bet-options");
    setBoosterOption([]);
    getOverUnderOption("meta4-over-under-bet-options");
  };

  const getCashOutOptions = () => {
    getBetOption("cash-out-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
  };

  const getEasyWinOptions = () => {
    setResultTypes([]);
    getBetOption("eazy-win-bet-options");
    setBoosterOption([]);
    getOverUnderOption("eazy-win-over-under-bet-options");
  };

  const getPerfectBallsOptions = () => {
    getBetOption("perfect-balls-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
  };

  const getMetroOptions = () => {
    getBetOption("metro-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
  };

  const getGhanaOptions = () => {
    getBetOption(GHANA_GAME_BETOPTIONS);
    getResultTypes(GHANA_GAME_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(GHANA_GAME_OVERUNDER_OPTIONS);
  };
  const getWesternIndoorOptions = () => {
    getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    getResultTypes(WESTERN_INDOOR_GAME_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(WESTERN_INDOOR_GAME_OVERUNDER_OPTIONS);
  };
  const getGhanaOptionsV2 = () => {
    getBetOption(GHANA_GAME_V2_BETOPTIONS);
    getResultTypes(GHANA_GAME_V2_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(GHANA_GAME_V2_OVERUNDER_OPTIONS);
  };
  const getWesternIndoorOptionsV2 = () => {
    getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    getResultTypes(WESTERN_INDOOR_GAME_V2_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(WESTERN_INDOOR_GAME_V2_OVERUNDER_OPTIONS);
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);

        setOverOption(content);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setBetOptionsValues([]);
    setResultOptionsValues([]);
    setOverUnderOptionsValues([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);
  };
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };
  const { data, error } = useSWRImmutable(settingsURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data || loadingSingleLotteryData) return <FullScreenLoader />;

  let Categories = data && data?.content ? JSON.parse(data?.content) : [];
  // console.log("betopitions values", betOptionsValues);
  // console.log("sigle data values", singleLotteryData);
  return (
    <AdminLayout>
      <MetaDecorator title="Update Game Category- Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Game Category" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box
                      my={1}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Display Image
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                      <div>
                        {singleLotteryData?.imageUrl && (
                          <div>
                            <Typography variant="caption">
                              Previous Image
                            </Typography>
                            <img
                              src={singleLotteryData?.imageUrl}
                              alt="lottery image"
                              width="60px"
                              height="60px"
                            />{" "}
                          </div>
                        )}
                      </div>
                    </Box>
                    <Box my={2}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max file size of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          value={formik.values.lotteryName}
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>

                    <>
                      {loadingResultTypes ? (
                        <FormLoader />
                      ) : (
                        <Box>
                          {resultTypes && resultTypes?.length > 0 && (
                            <Box my={1}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel
                                  variant="outlined"
                                  sx={labelStylesTwo}
                                >
                                  Result Options
                                </InputLabel>
                                <br />
                                <FormGroup
                                  aria-label="result options"
                                  row
                                  name="resultOptions"
                                  value={resultOptionsValues}
                                >
                                  {resultTypes &&
                                    resultTypes?.length > 0 &&
                                    resultTypes?.map((item, idx) => (
                                      <FormControlLabel
                                        key={`${item?.value}a0${item?.name}${idx}`}
                                        value={item?.value}
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={resultOptionsValues?.includes(
                                              item?.value
                                            )}
                                            onChange={handleResultOptionsChange}
                                            name="resultOptions"
                                          />
                                        }
                                        label={item?.name}
                                      />
                                    ))}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>

                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={betOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingover ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over/ Under Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="over options"
                                row
                                name="overOptions"
                              >
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.name}p${idx}`}
                                      value={item?.name}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={overUnderOptionsValues?.includes(
                                            item?.name
                                          )}
                                          onChange={
                                            handleOverUnderOptionsChange
                                          }
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="primary" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting ? "Updating Game" : " Update Game"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp${idx}`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditGame;
