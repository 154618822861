import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik, Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  safeJSONParse,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_RESULTOPTIONS,
  GHANA_GAME_OVERUNDER_OPTIONS,
  WESTERN_INDOOR_GAME_BETOPTIONS,
  WESTERN_INDOOR_GAME_RESULTOPTIONS,
  WESTERN_INDOOR_GAME_OVERUNDER_OPTIONS,
  GHANA_GAME,
  WESTERN_INDOOR_GAME,
  GHANA_GAME_V2,
  GHANA_GAME_V2_RESULTOPTIONS,
  GHANA_GAME_V2_OVERUNDER_OPTIONS,
  WESTERN_INDOOR_GAME_V2_BETOPTIONS,
  WESTERN_INDOOR_GAME_V2_RESULTOPTIONS,
  WESTERN_INDOOR_GAME_V2_OVERUNDER_OPTIONS,
  WESTERN_INDOOR_GAME_V2,
  GHANA_GAME_V2_BETOPTIONS,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FormLoader from "../components/FormLoader/FormLoader";
import produce from "immer";

function AddGame() {
  const [selectedSlug, setSelectedSlug] = React.useState("");
  const [booster, setBoosterOption] = React.useState([]);
  const [betOption, setBetOption] = React.useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = React.useState([]);
  const [betOptionsValues, setBetOptionsValues] = React.useState([]);
  const [over, setOverOption] = React.useState([]);
  const [loadingbooster, setLoadingBoosterOption] = React.useState(false);
  const [loadingbetOption, setLoadingBetOption] = React.useState(false);
  const [loadingover, setLoadingOverOption] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [resultTypes, setResultTypes] = React.useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const specialInputRef = React.useRef();

  // const settingsURL = `${process.env.BASE_URL}site-settings/fetch-settings`;
  const settingsURL = `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/game-categories`;

  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);

    if (index === -1) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: "" },
      ]);
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      lotteryName: "",
      category: "",
      slug: "",
      imageUrl: "",
      audioUrl: "",
      resultOptions: [],
      betOptions: [],
      boosterOptions: [],
      overOptions: [],
    },
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      values.overOptions = JSON.stringify(values.overOptions);
      values.betOptions = JSON.stringify(checkedBetOptions);
      values.boosterOptions = JSON.stringify(values.boosterOptions);
      values.resultOptions = JSON.stringify(values.resultOptions);
      values.category = selectedSlug;
      const formData = new FormData();

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("imageUrl", values.imageUrl);
      formData.append("audioUrl", values.audioUrl);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("boosterOptions", values.boosterOptions);
      formData.append("overUnderOptions", values.overOptions);

      axios
        .post(`${process.env.BASE_URL}game/create-lottery`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Game Created Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Category Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
    }),
  });
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const getOptions = () => {
    handleResetOptions();
    if (selectedSlug === "meta4") {
      getmeta4Options();
    } else if (selectedSlug === "cash-out") {
      getCashOutOptions();
    } else if (selectedSlug === "eazy-win") {
      getEasyWinOptions();
    } else if (selectedSlug === "perfect-balls") {
      getPerfectBallsOptions();
    } else if (selectedSlug === "metro") {
      getMetroOptions();
    } else if (selectedSlug === GHANA_GAME) {
      getGhanaOptions();
    } else if (selectedSlug === WESTERN_INDOOR_GAME) {
      getWesternIndoorOptions();
    } else if (selectedSlug === GHANA_GAME_V2) {
      getGhanaOptionsV2();
    } else if (selectedSlug === WESTERN_INDOOR_GAME_V2) {
      getWesternIndoorOptionsV2();
    }
  };

  const getmeta4Options = () => {
    setResultTypes([]);
    getBetOption("meta4-bet-options");
    setBoosterOption([]);
    getOverUnderOption("meta4-over-under-bet-options");
  };

  const getCashOutOptions = () => {
    getBetOption("cash-out-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
  };

  const getEasyWinOptions = () => {
    setResultTypes([]);
    getBetOption("eazy-win-bet-options");
    setBoosterOption([]);
    getOverUnderOption("eazy-win-over-under-bet-options");
  };

  const getPerfectBallsOptions = () => {
    getBetOption("perfect-balls-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
  };

  const getMetroOptions = () => {
    getBetOption("metro-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
  };

  const getGhanaOptions = () => {
    getBetOption(GHANA_GAME_BETOPTIONS);
    getResultTypes(GHANA_GAME_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(GHANA_GAME_OVERUNDER_OPTIONS);
  };

  const getWesternIndoorOptions = () => {
    getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    getResultTypes(WESTERN_INDOOR_GAME_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(WESTERN_INDOOR_GAME_OVERUNDER_OPTIONS);
  };

  const getGhanaOptionsV2 = () => {
    getBetOption(GHANA_GAME_V2_BETOPTIONS);
    getResultTypes(GHANA_GAME_V2_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(GHANA_GAME_V2_OVERUNDER_OPTIONS);
  };
  const getWesternIndoorOptionsV2 = () => {
    getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    getResultTypes(WESTERN_INDOOR_GAME_V2_RESULTOPTIONS);
    setBoosterOption([]);
    getOverUnderOption(WESTERN_INDOOR_GAME_V2_OVERUNDER_OPTIONS);
  };

  const handleResetOptions = () => {
    setBetOptionsValues((prevValue) => []);
    setCheckedBetOptions((prevState) => []);
    setBetOption((prevValue) => []);
    setBoosterOption((prevValue) => []);
    setResultTypes((prevValue) => []);
    setOverOption((prevValue) => []);
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getBoosterOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBoosterOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBoosterOption(content);
        setLoadingBoosterOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);

        setOverOption(content);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);

    // return getOptions();
  };

  // Function to get all the bet options
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };

  const { data, error } = useSWR(settingsURL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  let Categories = [];
  Categories = data && data?.content && safeJSONParse(data?.content);

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Game Category- Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Add New Game Category" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box my={1}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Display Image
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box my={2}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max file size of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>
                    {selectedSlug !== "salary4life" &&
                    selectedSlug !== "mega-7" ? (
                      <>
                        {loadingResultTypes ? (
                          <FormLoader />
                        ) : (
                          <Box>
                            {resultTypes && resultTypes?.length > 0 && (
                              <Box my={1}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel
                                    variant="outlined"
                                    sx={labelStylesTwo}
                                  >
                                    Result Options
                                  </InputLabel>
                                  <br />
                                  <FormGroup
                                    aria-label="result options"
                                    row
                                    name="resultOptions"
                                  >
                                    {resultTypes &&
                                      resultTypes?.length > 0 &&
                                      resultTypes?.map((item, idx) => (
                                        <FormControlLabel
                                          key={`${item?.value}a0${item?.name}${idx}`}
                                          value={item?.value}
                                          control={
                                            <Checkbox
                                              color="primary"
                                              onChange={formik.handleChange}
                                              name="resultOptions"
                                            />
                                          }
                                          label={item?.name}
                                        />
                                      ))}
                                  </FormGroup>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    ) : null}
                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={betOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingbooster ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {booster && booster?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Booster Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="booster options"
                                row
                                name="boosterOptions"
                                onChange={formik.handleChange}
                              >
                                {booster &&
                                  booster?.length > 0 &&
                                  booster?.map((item) => (
                                    <FormControlLabel
                                      key={item.value}
                                      value={item.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          onChange={formik.handleChange}
                                          name="boosterOptions"
                                        />
                                      }
                                      label={item.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingover ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over/Under Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="over options"
                                row
                                name="overOptions"
                                onChange={formik.handleChange}
                              >
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}p${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          onChange={formik.handleChange}
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="primary" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting ? "Adding Game" : " Add Game"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          type="number"
                          variant="outlined"
                          size="small"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                      <IconButton
                        color="error"
                        type="button"
                        onClick={() => {
                          setCheckedBetOptions((prevState) =>
                            prevState.filter(
                              (option) => option?.name !== item?.name
                            )
                          );
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default AddGame;
