import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../utils";

const requestStatuses = [
  {
    name: "Approved",
    value: "approved",
  },
  {
    name: "In review",
    value: "in review",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
  {
    name: "Pending",
    value: "pending",
  },
];
function ProductRequestsFilter({
  productName,
  status,
  startDate,
  endDate,
  handleChangeStatus,
  handleChangeProductName,
  handleChangeStartDate,
  handleChangeEndDate,
  handleFiterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Product Name
            </InputLabel>
            <TextField
              // label="Player's Name"
              variant="outlined"
              size="small"
              value={productName}
              fullWidth
              onChange={(e) => handleChangeProductName(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                // label="Date Joined"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              variant="outlined"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={""}>Select</MenuItem>
              {requestStatuses?.map((item) => (
                <MenuItem value={item.value} key={item?.value}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductRequestsFilter;
