import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import TableLoader from "../HalfErrorScreen/HalfErrorScreen";
import dayjs from "dayjs";

function BookedDealEventsTable({ data }) {
  const headCells = [
    "Name",
    "Home Team",
    "Away Team",
    "ID",
    "Week",
    "Type",
    "Event Date",
    "End Date",
    "Status",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, idx) => (
                <TableRow key={`${row?.betType}99${idx}`}>
                  <TableCell>{row?.title}</TableCell>
                  <TableCell>{row?.HomeTeam?.name}</TableCell>
                  <TableCell>{row?.AwayTeam?.name}</TableCell>
                  <TableCell>{row?.id}</TableCell>
                  <TableCell>{row?.week}</TableCell>
                  <TableCell>{row?.type}</TableCell>
                  <TableCell style={{ width: 120 }}>
                    {dayjs(row?.eventDate).format("MMM D, YYYY, HH:mma")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {dayjs(row?.endDate).format("MMM D, YYYY, HH:mma")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default BookedDealEventsTable;
