import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import TableLoader from "../TableLoader/TableLoader";
import { getAuthToken, seeMoreBoxStyles, headerStyles } from "../../utils";
import dayjs from "dayjs";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import NumberFormat from "react-number-format";

function UserTransactions({ userId }) {
  const [selectedTicket, setSelectedTicket] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState("");
  const naviagate = useNavigate();
  const open = Boolean(anchorEl);

  const url = `${process.env.BASE_URL}transactions/fetch-transactions?searchId=${userId}`;

  const handleClick = (event, ticket) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(ticket?.transactionId);
    setSelectedTicket(ticket);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const fetcher = () => {
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, cant retrieve users recent transactions at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;

  const headCells = [
    "Provider",
    "Amount",
    "Transaction Type",
    "Reference ID",
    "Date Created",
    "Actions",
  ];
  // {

  //   "transactionSource": "mainWallet",
  //   "": "1000.00",
  //   "narration": "New ticket creation",
  //   "provider": "source",
  //   "userId": "0d074cc9-b81e-4e7c-9438-19f086b4bda7",
  //   "status": "success",
  //   "deleted": false,
  //   "createdAt": "2022-04-12T23:16:20.000Z",
  //   "updatedAt": "2022-04-12T23:16:20.000Z",
  //   "User": {
  //     "firstname": "test",
  //     "lastname": "player",
  //     "phone": "08623456789",
  //     "email": "testplayer@gmail.com",
  //     "role": "player",
  //     "isAgent": false,
  //     "status": true
  //   }
  // },
  return (
    <Box component={Paper} sx={{ padding: "10px 5px" }}>
      <Box sx={{ paddingLeft: "15px" }}>
        <Typography gutterBottom color="error" sx={headerStyles}>
          Recent Transactions
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 300 }} size="small">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.transactionId}>
                  <TableCell>{row?.provider}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>
                  <TableCell>{row?.transactionType}</TableCell>
                  <TableCell>{row.referenceId}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {" "}
                      <MenuItem
                        onClick={() => handleOpenPreviewTicket()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          naviagate(
                            `/transactions/transaction-details/${selectedId}`
                          )
                        }
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      {/* <Box sx={seeMoreBoxStyles}>
        <Typography color="center" variant="button">
          See More
        </Typography>
      </Box> */}
    </Box>
  );
}

export default UserTransactions;
