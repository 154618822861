import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import NoDataAvailable from "../EmptyTable/EmptyTable";
import { boldTextCalculationStyles, currencyFormater } from "../../utils";

const getStakedAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].stakedAmount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
const getWonAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].winAmount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
function AnalysisTable({ data, selectedGame, selectionList1, selectionList2 }) {
  const headCells = [
    "Game",
    "Ticket ID",
    "Selections",
    "Won Amount",
    "Staked Amount",
    "Bet type",
    "Result type",
  ];
  const headers = [
    "Player",
    "Ticket ID",
    "Staked Amount",
    "Wining",
    "Redemption Method",
    "Game Name",
    "Status",
    "Date Created",
    "Ticket State",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.ticketId,
        item?.totalStakedAmount,
        `${item?.resultId ? `${item?.totalWinAmount}` : `No Result Yet`}`,
        item?.winningRedemptionMethod,
        item?.Game?.name,
        item?.gameStatus,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {/* {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="tickets.csv" />
      ) : null} */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.ticketId}>
                  <TableCell>{selectedGame?.name}</TableCell>
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>
                    {row?.selections?.map((sel, idx) => (
                      <Typography
                        component={"span"}
                        key={`${sel}123`}
                        sx={{
                          color: selectionList1?.includes(sel)
                            ? "green"
                            : selectionList2?.includes(sel)
                            ? "red"
                            : "default",
                          fontWeight: selectionList1?.includes(sel)
                            ? 600
                            : selectionList2?.includes(sel)
                            ? 600
                            : 400,
                        }}
                      >
                        {sel}
                        {idx < row?.selections?.length - 1 ? "-" : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    &#8358;{currencyFormater(row?.winAmount)}
                  </TableCell>
                  <TableCell>
                    &#8358;{currencyFormater(row?.stakedAmount)}
                  </TableCell>
                  <TableCell>{row.betType}</TableCell>
                  <TableCell>{row.resultType}</TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={3}>
                  Total
                </TableCell>

                <TableCell sx={boldTextCalculationStyles} align="left">
                  &#8358;{currencyFormater(getWonAmountSum(data))}
                </TableCell>
                <TableCell sx={boldTextCalculationStyles} align="left">
                  &#8358;{currencyFormater(getStakedAmountSum(data))}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default AnalysisTable;
