import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken, boxStyle5 } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";
import EventTeams from "../components/EventDetailsComponents/EventTeams";
import EventOptions from "../components/EventDetailsComponents/EventOptions";

function EventDetailsPage() {
  const { eventId } = useParams();

  const url = `${process.env.BASE_URL}game/fetch-event/${eventId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`${data && data?.title} event details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Event details" />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box py={2}>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Name:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.title}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Week:</Typography>
                <Typography align="left">{data && data?.week}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Type:</Typography>
                <Typography align="left">{data && data?.type}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Event Date:</Typography>
                <Typography align="left">
                  {data && dayjs(data?.eventDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Date Created:</Typography>
                <Typography align="left">
                  {dayjs(data?.createdAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Last Updated:</Typography>
                <Typography align="left">
                  {dayjs(data?.updatedAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Event ID:</Typography>
                <Typography align="left">{data && data?.eventId}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Custom Event ID:</Typography>
                <Typography align="left">
                  {data && data?.customEventId}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Status:</Typography>
                {data?.status ? (
                  <Chip color="success" label="Active" size="small" />
                ) : (
                  <Chip label="Not Active" color="error" size="small" />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <EventTeams data={data} />
          </Grid>
        </Grid>
        <Box my={2}>
          <EventOptions data={data} />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default EventDetailsPage;
