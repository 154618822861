import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import DailySalesView from "../components/SingleAgentCommissionReport/DailyReports/DailySalesView";
import WeeklySalesView from "../components/SingleAgentCommissionReport/WeeklyReports/WeeklySalesView";
import MonthlySalesView from "../components/SingleAgentCommissionReport/MonthlyReports/MonthlySalesView";
import { DAILY, MONTHLY, WEEKLY } from "../utils/index";
import { useParams } from "react-router-dom";

export default function SingleAgentCommissionReports() {
  const [value, setValue] = useState(DAILY);

  const params = useParams();
  // console.log("ssssssssssss", params);
  const handleChange = (event, newValue) => {
    // console.log("newvalue", newValue);
    setValue((prev) => newValue);
  };

  return (
    <AdminLayout>
      <MetaDecorator
        title={`${params?.userName} Agent Commission Reports Table - Admin`}
      />
      <Container maxWidth="xl">
        <PageHeading title={`${params?.userName}'s Commission Report`} />
        <Box sx={{ width: "100%" }}>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            sx={{ color: "green" }}
          >
            <Tab value={DAILY} label="Daily Commission" />
            <Tab value={WEEKLY} label="Weekly Commission" />
            <Tab value={MONTHLY} label="Monthly Commission" />
          </Tabs>
          <br />
          <Box sx={{ minHeight: "70vh" }}>
            {value === DAILY && <DailySalesView value={value} />}
            {value === WEEKLY && <WeeklySalesView value={value} />}
            {value === MONTHLY && <MonthlySalesView value={value} />}
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  );
}
