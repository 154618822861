import React, { useState, useContext, useEffect } from "react";
import { mutate } from "swr";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import { mutate } from "swr";
import NoDataAvailable from "../EmptyTable/EmptyTable";

export default function BetTypeExclusionDialog({
  openDialog,
  handleCloseDialog,
  userId,
  agentURL,
  user,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exclusionList, setExclusionList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const { handleOpenSnackbar } = useContext(AppContext);

  useEffect(() => {
    setExclusionList(user?.excludedAgentBetTypes);
    return () => {
      mutate(agentURL);
      setExclusionList([]);
      setSelectedList([]);
    };
  }, [openDialog]);

  const handleSubmit = async () => {
    let payload = { excludedAgentBetTypes: [] };

    let newBetOptions = [];
    exclusionList?.map((item) => {
      if (!selectedList?.includes(item?.lotteryId)) {
        newBetOptions?.push(item);
      }
    });

    payload.excludedAgentBetTypes = newBetOptions;

    setIsSubmitting(true);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .put(`${process.env.BASE_URL}agent/update-profile/${userId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(agentURL);
        setIsSubmitting(false);
        handleCloseDialog();
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Agent Bet Types Modified Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Agent Bet Types Cound Not Be Modified",
          severity: "error",
        });
      });
  };
  // console.log("sssssssssssss", user);
  const handleClick = (item) => {
    setSelectedList((prev) => [...prev, item?.lotteryId]);
  };
  const handleReverse = (item) => {
    setSelectedList((prev) => prev?.filter((id) => id !== item?.lotteryId));
  };
  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="xl"
    >
      <div>
        <DialogTitle sx={{ textAlign: "center" }}>
          MODIFY AGENT BET TYPES EXCLUSION
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            {exclusionList?.length > 0 ? (
              <Box>
                {exclusionList?.map((item) => (
                  <Box
                    sx={{ borderBottom: "0.5px solid #ddd" }}
                    mb={2}
                    key={item?.lotteryId}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          textDecoration:
                            selectedList?.includes(item?.lotteryId) &&
                            "line-through",
                        }}
                      >
                        Lottery: {item?.lotteryName}
                      </Typography>

                      <Box>
                        <IconButton onClick={() => handleClick(item)}>
                          <DoneIcon color="red" />
                        </IconButton>
                        &nbsp; &nbsp;
                        <IconButton onClick={() => handleReverse(item)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box>
                      {item?.betTypes?.map((bet, idx) => (
                        <Typography
                          sx={{
                            textDecoration:
                              selectedList?.includes(item?.lotteryId) &&
                              "line-through",
                          }}
                          component={"span"}
                          key={`${item?.lotteryId}${bet}`}
                        >
                          {bet},
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <NoDataAvailable message="No Excluded Bet Types For This Account" />
            )}
          </Box>
          <br />
        </DialogContent>
        <DialogActions sx={{ paddingRight: "15px" }}>
          <Button
            type="button"
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {isSubmitting ? "Processing" : "Change"}
          </Button>
        </DialogActions>{" "}
      </div>
    </Dialog>
  );
}
