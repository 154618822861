import React, { useState } from "react";
import Container from "@mui/material/Container";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  formatErrorMessage,
} from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
// import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import { useNavigate } from "react-router-dom";
import DectivateResultDialog from "../components/PendingResults/DectivateResultDialog";
import PrizeClaimsTable from "../components/PrizeClaims/PrizeClaimsTable";
import ApproveClaimDialog from "../components/PrizeClaims/ApproveClaimDialog";
import PreviewPrizeModal from "../components/PrizeClaims/PreviewPrizeModal";
import RejectClaimDialog from "../components/PrizeClaims/RejectClaimDialog";

function PrizeClaims() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [status, setStatus] = useState("");
  const [currentWeekDay, setCurrentWeekDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const url = `${process.env.BASE_URL}sport/fetch-ticket-claims?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}sport/fetch-ticket-claims?limit=${rowsPerPage}&page=${page}`;
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations
  let sStatus = "status";
  let sStartTime = "startTime";
  let sEndTime = "endTime";
  let sCurrentWeekDay = "currentWeekDay";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sStartTime);
    sessionStorage.removeItem(sEndTime);
    sessionStorage.removeItem(sCurrentWeekDay);
  };

  const handleResetFields = () => {
    setStatus("");
    setStartTime(null);
    setEndTime(null);
    setCurrentWeekDay(null);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectCategory = (game) => setSelectedPrize(game);

  const handleOpenRejectClaimDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateDialog = () => {
    setOpenDeactivateDialog(false);
  };
  const handleOpenPreviewDialog = (prize) => {
    setOpenPreviewDialog(true);
    setSelectedPrize(prize);
  };
  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
  };

  const handleOpenApproveDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseApproveDialog = () => {
    setOpenActivateDialog(false);
  };

  const handleSubmitRejectClaim = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      claimId: selectedPrize?.claimId,
      isApproved: false,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}sport/approve-or-reject-claim`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Claim have Been Successfully Rejected",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);
        let errMsg = formatErrorMessage(err);

        handleOpenSnackbar({
          message: errMsg || "Sorry, Claim Could Not Be Rejected",
          severity: "error",
        });
      });
  };

  const handleSubmitApproveClaim = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      claimId: selectedPrize?.claimId,
      isApproved: true,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}sport/approve-or-reject-claim`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Prize Have Been Successfully Approve",
          severity: "success",
        });
        mutate(url);
        return handleCloseApproveDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);
        let errMsg = formatErrorMessage(err);
        handleOpenSnackbar({
          message: errMsg || "Prize Could Not Be Approve",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  // console.log("gggggggggggggggg", selectedPrize);
  return (
    <AdminLayout>
      <MetaDecorator title="Prize Claims Table - Admin Dashboard SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Prize Claims" />

        {openDeactivateDialog ? (
          <RejectClaimDialog
            openDialog={openDeactivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateDialog}
            handleSubmit={handleSubmitRejectClaim}
          />
        ) : null}
        {openActivateDialog ? (
          <ApproveClaimDialog
            openDialog={openActivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseApproveDialog}
            handleSubmit={handleSubmitApproveClaim}
          />
        ) : null}
        {openPreviewDialog ? (
          <PreviewPrizeModal
            openDialog={openPreviewDialog}
            selectedPrize={selectedPrize}
            handleCloseDialog={handleClosePreviewDialog}
          />
        ) : null}

        <PrizeClaimsTable
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedPrize={selectedPrize}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSelectCategory={handleSelectCategory}
          handleOpenApproveDialog={handleOpenApproveDialog}
          handleOpenPreviewDialog={handleOpenPreviewDialog}
          handleOpenRejectClaimDialog={handleOpenRejectClaimDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default PrizeClaims;
