import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

function TicketPlayerInformation({ data, itemBoxStyle }) {
  return (
    <Grid item xs={12} sm={7}>
      <Box>
        <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
          Ticket Player Information
        </Typography>
        <Box py={2}>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Full Name:</Typography>
            <Typography align="left" sx={{ textTransform: "capitalize" }}>
              {data && data?.firstname} {data && data?.lastname}
            </Typography>
          </Box>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Email:</Typography>
            <Typography align="left">{data && data?.email}</Typography>
          </Box>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Email Verification:</Typography>
            {data?.hasVerifiedEmail ? (
              <Chip color="success" label="Verified" size="small" />
            ) : (
              <Chip label="Not Verified" color="error" size="small" />
            )}
          </Box>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Phone:</Typography>
            <Typography align="left">{data && data?.phone}</Typography>
          </Box>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Role:</Typography>
            <Typography align="left">{data && data?.role}</Typography>
          </Box>
          <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
            <Typography color="text.secondary">Player Status:</Typography>
            {data?.status ? (
              <Chip color="success" label="Active" size="small" />
            ) : (
              <Chip label="Not Active" color="error" size="small" />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default TicketPlayerInformation;
