import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import { mutate } from "swr";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import NumberFormat from "react-number-format";
import BgImage from "../../images/questionbg.jpg";

export default function ConfirmDebitUserDialog({
  openDialog,
  handleFailure,
  selectedUser,
  values,
  usersURL,
}) {
  const [submitting, setSubmitting] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleSuccess = async () => {
    setSubmitting(true);
    checkExpiredAuthToken();
    const token = getAuthToken();

    axios
      .put(`${process.env.BASE_URL}wallet/debit-wallet`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(usersURL);
        setSubmitting(false);
        handleFailure();
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Account Debited Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        setSubmitting(false);
        if (typeof err?.response?.data?.responsemessage === "string") {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message:
            "Sorry, An Unexpected Error Occured, Account Could Not Be Debited",
          severity: "error",
        });
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleFailure}
        sx={{
          background: `url(${BgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          DEBIT ACCOUNT CONFIRMATION
        </DialogTitle>
        <DialogContent>
          <Box
            my={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "340px",
                padding: "0px 10px",
                minHeight: "410px",
              }}
            >
              <IconButton
                color="success"
                sx={{
                  background: "rgba(75, 122, 78, 0.2)",
                }}
              >
                <QuestionMarkIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
              </IconButton>
              <br />
              <NumberFormat
                value={values?.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"NGN"}
                renderText={(value, props) => (
                  <Typography
                    color="success"
                    variant="h5"
                    align="center"
                    {...props}
                  >
                    {value}
                  </Typography>
                )}
              />
              <br />
              <Typography align="center" gutterBottom>
                To debit{" "}
                {`${selectedUser?.firstname} ${selectedUser?.lastname}`}{" "}
                account, click on the Confirm button or cancel to ignore
              </Typography>
              <br />
              <br />

              <Button
                type="submit"
                fullWidth
                disabled={submitting}
                startIcon={submitting && <CircularProgress size={"1rem"} />}
                variant="contained"
                color="primary"
                onClick={() => handleSuccess()}
              >
                {submitting ? "Processing" : "Confirm"}
              </Button>
              <br />
              <Button
                variant="outlined"
                type="button"
                fullWidth
                disabled={submitting}
                color="error"
                onClick={() => handleFailure()}
              >
                Cancel
              </Button>
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
