import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import {
  MdScatterPlot,
  MdOutlineAdminPanelSettings,
  MdOutlineArticle,
} from "react-icons/md";
import { GiTabletopPlayers } from "react-icons/gi";
import { ImTicket } from "react-icons/im";
import { FaNetworkWired } from "react-icons/fa";
import {
  checkExpiredAuthToken,
  currencyFormater,
  getAuthToken,
} from "../../utils";

import "./DashboardStyles.css";
import HalfErrorScreen from "../../components/HalfErrorScreen/HalfErrorScreen";

const url = `${process.env.BASE_URL}fetch-analytics`;

function DashboardStatsCard({ tickets, players }) {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const WebCardItem = ({ icon, mainNumber = 0, text, colorMode = "blue" }) => {
    let CardStyles = {
      width: "100%",
      minHeight: "100px",
      background: `linear-gradient(51deg, rgba(11,10,39,1) 0%, rgba(9,9,121,1) 22%, rgba(0,212,255,1) 100%)`,
    };

    if (colorMode === "yellow") {
      CardStyles = {
        width: "100%",
        minHeight: "100px",
        background: `linear-gradient(51deg, rgba(236,76,0,1) 0%, rgba(255,169,50,1) 50%, rgba(255,217,0,1) 100%)`,
      };
    }
    if (colorMode === "purple") {
      CardStyles = {
        width: "100%",
        minHeight: "100px",
        background: `linear-gradient(51deg, rgba(176,3,111,1) 0%, rgba(255,50,113,1) 50%, rgba(255,0,18,1) 100%)`,
      };
    }
    if (colorMode === "green") {
      CardStyles = {
        width: "100%",
        minHeight: "100px",
        background: `linear-gradient(51deg, rgba(42,78,0,1) 0%, rgba(22,160,0,1) 37%, rgba(68,200,5,1) 100%)`,
      };
    }
    if (colorMode === "red") {
      CardStyles = {
        width: "100%",
        minHeight: "100px",
        background: `linear-gradient(51deg, rgba(194,0,0,1) 0%, rgba(234,32,91,1) 36%, rgba(255,2,2,1) 100%)`,
      };
    }

    if (colorMode === "orange") {
      CardStyles = {
        width: "100%",
        minHeight: "100px",
        background: `linear-gradient(51deg, rgba(171,120,0,1) 0%, rgba(234,126,32,1) 47%, rgba(255,224,41,1) 100%)`,
      };
    }

    return (
      <Card sx={CardStyles}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                width: "60px",
                height: "60px",
                color: "#ffffff",
                background: "rgba(185, 253, 188, 0.15)",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </Box>
            <Box>
              <Typography
                variant="h6"
                color="text-primary"
                align="right"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {currencyFormater(mainNumber)}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                {text}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, cant retrieve requested data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return null;

  const iconStyle = {
    color: "#ffffff",
    fontSize: "1.8rem",
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          mainNumber={data && data?.totalPlayers}
          icon={<GiTabletopPlayers style={iconStyle} />}
          text={players <= 1 ? "Player" : "Players"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          colorMode="yellow"
          mainNumber={data && data?.totalTickets}
          icon={<ImTicket style={iconStyle} />}
          text={tickets <= 1 ? "Ticket" : "Tickets"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          colorMode="purple"
          mainNumber={data && data?.totalAgents}
          icon={<MdScatterPlot style={iconStyle} />}
          text={players <= 1 ? "Agent" : "Agents"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          colorMode="green"
          mainNumber={data && data?.totalTransactions}
          icon={<FaNetworkWired style={iconStyle} />}
          text={players <= 1 ? "Transaction" : "Transactions"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          colorMode="orange"
          mainNumber={data && data?.totalAdmins}
          icon={<MdOutlineAdminPanelSettings style={iconStyle} />}
          text={players <= 1 ? "Admin" : "Admins"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <WebCardItem
          colorMode="red"
          mainNumber={data && data?.totalGameInstances}
          icon={<MdOutlineArticle style={iconStyle} />}
          text={players <= 1 ? "Game" : "Games"}
        />
      </Grid>
    </Grid>
  );
}

export default DashboardStatsCard;
