import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { singleDetailsBoxStyle } from "../../utils";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

function WalletInformation({ data }) {
  return (
    <>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Wallet Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Commission Balance:</Typography>

          <NumberFormat
            value={data?.commissionBalance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"NGN"}
            renderText={(value, props) => (
              <Typography align="left" {...props}>
                {value}
              </Typography>
            )}
          />
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Wallet Balance:</Typography>
          <NumberFormat
            value={data?.walletBalance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"NGN"}
            renderText={(value, props) => (
              <Typography align="left" {...props}>
                {value}
              </Typography>
            )}
          />
        </Box>
      </Box>
    </>
  );
}

export default WalletInformation;
