import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";

export default function GameForecastModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.BASE_URL}game/fetch-forecast/${selectedGame?.gameId}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: res.data?.data?.message,
          severity: "success",
        });
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              showMainTitle={
                error?.response?.data?.responsemessage ? true : true
              }
              mainTitle={
                error?.response?.data?.responsemessage &&
                error?.response?.data?.responsemessage
              }
              title={
                error?.response?.data?.responsemessage
                  ? " "
                  : "Sorry, game forecast data cannot be retrieved at this momment"
              }
              subtitle={
                error?.response?.data?.responsemessage
                  ? error?.response?.data?.responsemessage
                  : error.message
              }
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );

  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          GAME FORECAST
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              {typeof data === "object" && data?.length === 0 ? (
                <Grid item xs={12} sm={8}>
                  <Box my={3}>
                    <Typography
                      align="center"
                      variant="h5"
                      sx={dialogMainTextStyles}
                    >
                      No Forecast yet
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={8}>
                  <Box my={1}>
                    <Box my={1}>
                      <Typography variant="caption">Forecast ID:</Typography>
                      <Typography sx={dialogMainTextStyles}>
                        {data?.forecastId}
                      </Typography>
                    </Box>
                    <Box my={1}>
                      <Typography variant="caption"> Forecast:</Typography>
                      <Typography variant="h5" sx={dialogMainTextStyles}>
                        {data?.selections}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
