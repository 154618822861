import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { singleDetailsBoxStyle } from "../../utils";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

function OtherGameInformation({ data }) {
  return (
    <Box>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Other Game Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Lottery Name:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {data && data?.lotteryName}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Category:</Typography>
          <Typography align="left">
            {data && data?.Lottery?.category}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Game Count:</Typography>
          <NumberFormat
            value={data?.Lottery?.gameCount}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value, props) => (
              <Typography align="left" {...props}>
                {value}
              </Typography>
            )}
          />
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Result Count:</Typography>
          <NumberFormat
            value={data?.Lottery?.resultCount}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value, props) => (
              <Typography align="left" {...props}>
                {value}
              </Typography>
            )}
          />
        </Box>
        {data?.Lottery?.setA && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <Typography color="text.secondary">SetA:</Typography>

            <Chip color="success" label="True" size="small" />
          </Box>
        )}
        {data?.Lottery?.setB && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <Typography color="text.secondary">SetB:</Typography>

            <Chip color="success" label="True" size="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

// betOptions: "[\"nap-1\",\"perm-2\", \"1-by-2\"]"
// boosterOptions: "[\"default\",\"mega\", \"max\"]"
// overOptions: "[\"200\",\"220\", \"250\", \"280\", \"300\", \"320\", \"350\"]"

// setA: true
// setB: true
// status: false
// underOptions: "[\"100\",\"150\", \"180\", \"200\", \"220\", \"250\"]"

export default OtherGameInformation;
