import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const MoneyAnalyticsCard = ({
  icon,
  moneyText,
  countText,
  countValue,
  moneyValue,
  error,
}) => {
  const CardStyles = {
    width: "100%",
    minHeight: "100px",
  };

  const renderBackgroundColor = (error) => {
    if (error) return "rgba(253, 253, 188, 0.35)";
    return "rgba(185, 253, 188, 0.15)";
  };
  return (
    <Card style={CardStyles}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "60px",
                height: "60px",
                background: renderBackgroundColor(error),
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ borderRight: "1px solid #eeeeee", width: "100%" }}>
              <Typography variant="caption" color="text-primary">
                {countText}
              </Typography>
              <NumberFormat
                value={countValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={""}
                renderText={(value, props) => (
                  <Typography color="text-primary" sx={{ fontWeight: "bold" }}>
                    {value}
                  </Typography>
                )}
              />
            </Box>
            <Box
              sx={{
                borderLeft: "1px solid #eeeeee",
                width: "100%",
                paddingLeft: "12px",
              }}
            >
              <Typography variant="caption" color="text-primary">
                {moneyText}
              </Typography>
              <NumberFormat
                value={moneyValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"NGN"}
                renderText={(value, props) => (
                  <Typography color="text-primary" sx={{ fontWeight: "bold" }}>
                    {value}
                  </Typography>
                )}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

MoneyAnalyticsCard.propTypes = {
  icon: PropTypes.any,
  error: PropTypes.bool,
  moneyText: PropTypes.string.isRequired,
  countText: PropTypes.string.isRequired,
  countValue: PropTypes.any.isRequired,
  moneyValue: PropTypes.any.isRequired,
};
export default MoneyAnalyticsCard;

export const HalfMoneyAnalyticsCard = ({
  icon,
  mainNumber = 0,
  text,
  error = false,
}) => {
  const CardStyles = {
    width: "100%",
    minHeight: "115px",
  };

  const renderBackgroundColor = (error) => {
    if (error) return "rgba(253, 253, 188, 0.35)";
    return "rgba(185, 253, 188, 0.15)";
  };
  return (
    <Card style={CardStyles}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              width: "60px",
              height: "60px",
              background: renderBackgroundColor(error),
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Box>
          <Box>
            <NumberFormat
              value={mainNumber}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"NGN"}
              renderText={(value, props) => (
                <Typography
                  gutterBottom
                  variant="h6"
                  color="text-primary"
                  sx={{ fontWeight: "bold" }}
                >
                  {value}
                </Typography>
              )}
            />
            <Typography variant="subtitle2" color="text-secondary">
              {text}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
