// content: "[\"meta4\", \"cash-out\", \"eazy-win\", \"perfect-balls\", \"lotto\", \"metro\"]"

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";
export const headCellsTwo = [
  "Amount Sold",
  "No of Ticket Sold",
  "Total Commission",
  "No. of Commission",
  "Amount Won",
  "Profit",
];
export const getReportSum = (array, search) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index][search];
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(0);
};

export const downloadBoxStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "4px",
};
export const reportTotalStyles = {
  color: "#ffffff",
  background:
    "linear-gradient(270deg, rgba(0,157,105,1) 0%, rgba(0,115,175,1) 45%, rgba(0,178,188,1) 100%)",
};
export const reportWinningStyles = {
  background: "#FFE15D",
};

export const reportCommStyles = {
  color: "#fff",
  background: "green",
};
export const whiteColorStyles = {
  color: "#ffffff",
};
