import React from "react";
import AppRoutes from "./Routes";
import { setDefaultHeaders } from "./utils";

const App = () => {
  React.useEffect(() => {
    setDefaultHeaders();
  }, []);
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
