import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { currencyFormater } from "../../utils";

function ProductInformation({ data, itemBoxStyle }) {
  return (
    <Grid item xs={12} md={5}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Product Information
      </Typography>
      <Box py={1}>
        <Box>
          <img
            src={data?.Product?.imageUrl}
            alt={data?.Product?.title}
            width={140}
            height={100}
            style={{
              width: "140px",
              height: "100px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Name:</Typography>
          <Typography align="left">{data?.Product?.title}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Cash Equivalence:</Typography>
          <Typography align="left">
            &#8358;{currencyFormater(data?.Product?.cashEquivalence)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Min Stake Amount:</Typography>
          <Typography align="left">
            &#8358;{currencyFormater(data?.Product?.minStakedAmount)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Max Stake Amount:</Typography>
          <Typography align="left">
            &#8358;{currencyFormater(data?.Product?.maxStakedAmount)}
          </Typography>
        </Box>

        <Box mb={1} pb={1} pr={2}>
          <Typography color="text.secondary">Description:</Typography>
          <Typography align="left">{data?.Product?.description}</Typography>
        </Box>

        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Date Created:</Typography>
          <Typography align="left">
            {dayjs(data?.Product?.createdAt).format("D MMM YYYY, hh:ss a")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default ProductInformation;
