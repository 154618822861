import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  checkExpiredAuthToken,
  formatErrorMessage,
  getAuthToken,
  labelStyles,
} from "../../utils";
import axios from "axios";
import FormLoader from "../FormLoader/FormLoader";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import ca from "date-fns/esm/locale/ca";
function ProductsFilterForm({
  productName,
  status,
  startDate,
  endDate,
  minAmount,
  maxAmount,
  category,
  handleChangeMinimumAmount,
  handleChangeMaximumAmount,
  handleChangeStatus,
  handleChangeProductName,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeCategory,
  handleFilterSubmit,
}) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const url = `${process.env.BASE_URL}categories/fetch-categories?limit=200`;
  const fetcher = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(res?.data?.data?.data);
    } catch (error) {
      const errMsg = formatErrorMessage(error);
      setError(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetcher();
  }, []);

  if (loading) {
    return (
      <>
        <FormLoader />
        <FormLoader />
      </>
    );
  }
  if (error) {
    return (
      <HalfErrorScreen
        title={"An unexpected error occurred"}
        subtitle={error}
      />
    );
  }
  return (
    <Box mb={2}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Product Name
            </InputLabel>
            <TextField
              value={productName}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeProductName(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Min. Amount
            </InputLabel>
            <TextField
              value={minAmount}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeMinimumAmount(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Max. Amount
            </InputLabel>
            <TextField
              value={maxAmount}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeMaximumAmount(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              Start Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                ampm={true}
                // label="Last Login Date"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" sx={labelStyles}>
              End Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                // label="Last Login Date"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Category
            </InputLabel>
            <Select
              variant="outlined"
              value={category}
              onChange={(e) => handleChangeCategory(e)}
            >
              <MenuItem value={""}>None</MenuItem>
              {categories &&
                categories?.length > 0 &&
                categories?.map((item) => (
                  <MenuItem value={item?.categoryId}>{item?.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Product Status
            </InputLabel>
            <Select
              variant="outlined"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductsFilterForm;
