import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  GHANA_GAME,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_V2,
  GHANA_GAME_V2_BETOPTIONS,
  labelStyles,
  labelStylesTwo,
  WESTERN_INDOOR_GAME,
  WESTERN_INDOOR_GAME_BETOPTIONS,
  WESTERN_INDOOR_GAME_V2,
  WESTERN_INDOOR_GAME_V2_BETOPTIONS,
} from "../utils";
import useSWR, { mutate } from "swr";
import FormLoader from "../components/FormLoader/FormLoader";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  depositRound: yup.string().required().label("Deposit Round"),
  betType: yup.string().required().label("Bet Type"),
  expiration: yup.string().required().label("Expiration"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  minimumDeposit: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Minimum Deposit"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
  description: yup.string().max(60).label("Description"),
});

function AddBonusConfiguration() {
  const [bonusSearchField, setBonusSearchField] = React.useState("");
  const [currentGameId, setCurrentGameId] = React.useState("");
  const [selectedGame, setSelectedGame] = React.useState({});
  const [betOption, setBetOption] = React.useState([]);
  const [gamesArray, setGamesArray] = React.useState([]);
  const [isGameSelected, setIsGameSelected] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [hasSearchedGames, setHasSearchedGames] = React.useState(false);
  const [loadingBetOption, setLoadingBetOption] = React.useState(false);
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const optionsRef = React.useRef();

  let initialValues = {
    title: "",
    depositRound: "",
    winCount: 1,
    gameType: "",
    betType: "",
    expiration: "",
    minimumDeposit: 1,
    prize: 1,
    gamePlayCount: 1,
    description: "",
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // values.expiration = dayjs(values?.expiration).format("DD-MM-YYYY");
    values.gameType = selectedGame?.name;

    axios
      .post(`${process.env.BASE_URL}bonus/create-bonus`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm({ values: initialValues });
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bonus Created Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bonus Could Not Be Created",
          severity: "error",
        });
      });
  };

  const handleChangeSearch = (e) => {
    setBonusSearchField(e.target.value);
  };
  const url = `${
    process.env.BASE_URL
  }game/fetch-games?limit=${100}&search=${bonusSearchField}`;
  // const url = `${process.env.BASE_URL}game/fetch-games?limit=${20}`;

  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption(content);
        setLoadingBetOption(false);
        setHasSearchedGames(true);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const depositRoundOptions = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "every deposit",
    "next deposit",
  ];

  const getOptions = async () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    if (selectedGame?.Lottery?.category === "meta4") {
      return await getBetOption("meta4-bet-options");
    } else if (selectedGame?.Lottery?.category === "cash-out") {
      return await getBetOption("cash-out-bet-options");
    } else if (selectedGame?.Lottery?.category === "eazy-win") {
      return await getBetOption("eazy-win-bet-options");
    } else if (selectedGame?.Lottery?.category === "perfect-balls") {
      return await getBetOption("perfect-balls-bet-options");
    } else if (selectedGame?.Lottery?.category === "lotto") {
      return await getBetOption("lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === "metro") {
      return await getBetOption("metro-bet-options");
    } else if (selectedGame?.Lottery?.category === GHANA_GAME) {
      return await getBetOption(GHANA_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME) {
      return await getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === GHANA_GAME_V2) {
      return await getBetOption(GHANA_GAME_V2_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME_V2) {
      return await getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    }
  };
  const handleClick = async (game) => {
    await setSelectedGame(game);
    await setCurrentGameId(game?.gameId);
    setIsGameSelected(true);
    setTimeout(() => {
      optionsRef.current.click();
    }, 800);
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add New Bonus Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="New Bonus Configuration" />

        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={bonusSchema}
            validateOnBlur={true}
            validateOnChange={true}
            //   onSubmit={(values) => console.log(values)}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={2}>
                  <Typography gutterBottom>
                    To add a bonus, search for a game or click on the search
                    button without any text to get the most recent 100 games,
                    select a game from the result and complete filling the form,
                    and click on the Add Bonus button
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Game Title"
                        size="small"
                        fullWidth
                        onChange={(e) => handleChangeSearch(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        size="large"
                        color="primary"
                        disabled={isSearching}
                        variant="contained"
                        type="button"
                        onClick={() => searchGamesData()}
                      >
                        {isSearching ? "Searching" : "Search"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {hasSearchedGames ? (
                  <Box>
                    {gamesArray && gamesArray?.length > 0 ? (
                      <Grid container spacing={1}>
                        {gamesArray.map((item, idx) => (
                          <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                            <Chip
                              label={item?.name}
                              color={
                                currentGameId === item?.gameId
                                  ? "primary"
                                  : "default"
                              }
                              variant={
                                currentGameId === item?.gameId
                                  ? "filled"
                                  : "outlined"
                              }
                              onClick={() => {
                                handleClick(item);
                                setSelectedGame(item);
                                return optionsRef.current.click();
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography>
                        Sorry, no game match your search, search for a new game
                        again
                      </Typography>
                    )}
                  </Box>
                ) : isSearching ? (
                  <FormLoader />
                ) : null}
                <br />
                <button
                  style={{ display: "none" }}
                  type="button"
                  ref={optionsRef}
                  onClick={() => getOptions()}
                >
                  Fetch Options
                </button>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Bonus Title"
                      error={touched.title && errors.title}
                      disabled={isGameSelected === false}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      variant="outlined"
                      id="statusSelect"
                      sx={labelStyles}
                    >
                      Deposit Round
                    </InputLabel>
                    <Select
                      disabled={isGameSelected === false}
                      name="depositRound"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.depositRound && errors.depositRound}
                      helperText={touched.depositRound && errors.depositRound}
                    >
                      {depositRoundOptions.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2} mb={1}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        {/* <InputLabel variant="outlined" sx={labelStylesTwo}>
                            Minimum Deposit
                          </InputLabel> */}
                        <TextField
                          label="Minimum Deposit"
                          name="minimumDeposit"
                          disabled={isGameSelected === false}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={
                            touched.minimumDeposit && errors.minimumDeposit
                          }
                          helperText={
                            touched.minimumDeposit && errors.minimumDeposit
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Prize"
                          disabled={isGameSelected === false}
                          name="prize"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.prize && errors.prize}
                          helperText={touched.prize && errors.prize}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Win Count"
                          disabled={isGameSelected === false}
                          name="winCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.winCount && errors.winCount}
                          helperText={touched.winCount && errors.winCount}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Game Play Count"
                          disabled={isGameSelected === false}
                          name="gamePlayCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.gamePlayCount && errors.gamePlayCount}
                          helperText={
                            touched.gamePlayCount && errors.gamePlayCount
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Expiration Period in days"
                      disabled={isGameSelected === false}
                      name="expiration"
                      type="number"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.expiration && errors.expiration}
                      helperText={touched.expiration && errors.expiration}
                    />
                  </FormControl>
                </Box>
                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Description"
                      disabled={isGameSelected === false}
                      name="description"
                      variant="outlined"
                      multiline
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </FormControl>
                </Box>
                <Box my={2}>
                  {loadingBetOption ? (
                    <>
                      <FormLoader />
                      <FormLoader />
                    </>
                  ) : betOption && betOption?.length > 0 ? (
                    <FormControl>
                      <FormLabel sx={labelStylesTwo}>Bet Type</FormLabel>
                      <RadioGroup
                        required
                        value={values.betType}
                        row
                        name="betType"
                        onChange={handleChange}
                      >
                        {betOption &&
                          betOption?.length > 0 &&
                          betOption?.map((item, idx) => (
                            <FormControlLabel
                              key={`${item?.name}ll${idx}`}
                              value={item?.value}
                              control={<Radio color="primary" name="betType" />}
                              label={item?.name}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                </Box>
                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress severity="primary" size={"1rem"} />
                    )
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? "Adding Bonus" : "Add Bonus"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddBonusConfiguration;
