import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import WinningCheckForm from "../components/WinningCheck/WinningCheckForm";
import AnalysisTable from "../components/WinningCheck/AnalysisTable";
import AnalysisSummary from "../components/WinningCheck/AnalysisSummary";

const ANALYSIS_TABLE = "ANALYSIS_TABLE";
const ANALYSIS_SUMMARY = "ANALYSIS_SUMMARY";
function WinningCheck() {
  const [isResultAvailable, setIsResultAvailable] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [showResults, setShowResults] = useState(true);
  const [value, setValue] = useState(ANALYSIS_TABLE);
  const [result, setResult] = useState(null);
  const [selectionList1, setSelectionList1] = useState([]);
  const [selectionList2, setSelectionList2] = useState([]);

  const handleChangeShow = (event) => setShowResults(event.target.checked);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Winning Check - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Winning Check" />
        {isResultAvailable && (
          <Box>
            <FormControlLabel
              sx={{ marginLeft: "auto" }}
              control={
                <Switch
                  color="primary"
                  checked={showResults}
                  onChange={handleChangeShow}
                />
              }
              label="Show Only Results"
            />
          </Box>
        )}
        <Box
          sx={{
            display: showResults ? "block" : "none",
          }}
        >
          <WinningCheckForm
            selectedGame={selectedGame}
            setSelectedGame={setSelectedGame}
            setIsResultAvailable={setIsResultAvailable}
            setResult={setResult}
            setSelectionList1={setSelectionList1}
            setSelectionList2={setSelectionList2}
          />
        </Box>
        {isResultAvailable && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              sx={{ color: "green", marginTop: "5px" }}
            >
              <Tab label="Analysis Table" value={ANALYSIS_TABLE} />
              <Tab label="Analysis Summary" value={ANALYSIS_SUMMARY} />
            </Tabs>
            {value === ANALYSIS_TABLE && (
              <AnalysisTable
                selectedGame={selectedGame}
                value={value}
                data={result?.responseSlips}
                selectionList1={selectionList1}
                selectionList2={selectionList2}
              />
            )}
            {value === ANALYSIS_SUMMARY && (
              <AnalysisSummary value={value} data={result} />
            )}
          </Box>
        )}
      </Container>
    </AdminLayout>
  );
}

export default WinningCheck;
