import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { safeJSONParse } from "../../utils";

function GameOptions({ data }) {
  let resultOptions = safeJSONParse(data?.resultOptions) || [];
  let betOptions = safeJSONParse(data?.betOptions) || [];
  let overOptions = safeJSONParse(data?.overUnderOptions) || [];
  // console.log("bet Options options", overOptions);
  // console.log("result options", typeof overOptions);
  return (
    <Box>
      <Box py={1}>
        {betOptions && betOptions !== null && betOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Bet Options/Multiplier:</Typography>
            <Grid container spacing={2}>
              {betOptions?.map((item) => (
                <Grid key={`${item?.name}89`} item xs={6} sm={3} xl={2}>
                  <Chip
                    label={`${item?.name} / ${item?.multiplier}`}
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      <Box py={1}>
        {overOptions && overOptions !== null && overOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Over/Under Options:</Typography>
            <Grid container spacing={2}>
              {typeof overOptions === "string" ? (
                <Typography>{overOptions}</Typography>
              ) : (
                overOptions?.map((item) => (
                  <Grid key={item} item xs={6} sm={3} md={2}>
                    <Chip
                      label={item}
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        )}
      </Box>
      <Box py={1}>
        {resultOptions &&
          resultOptions !== null &&
          resultOptions?.length > 0 && (
            <Box mb={1} pb={1} pr={2}>
              <Typography gutterBottom>Result Options:</Typography>
              <Grid container spacing={2}>
                {typeof resultOptions === "string" ? (
                  <Typography>{resultOptions}</Typography>
                ) : (
                  resultOptions?.map((item) => (
                    <Grid key={item} item xs={6} sm={3}>
                      <Chip label={item} variant="outlined" />
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
          )}
      </Box>{" "}
    </Box>
  );
}

export default GameOptions;
