import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { currencyFormater } from "../../utils";

function PaymentConfigTable({ data }) {
  const headCells = ["Cut", "Staked Amount", "Potential Winning"];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.sn}>
                  <TableCell>{row?.cut}</TableCell>
                  <TableCell>&#8358;{currencyFormater(row?.stake)}</TableCell>
                  <TableCell>
                    &#8358;{currencyFormater(row?.potentialWinAmount)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default PaymentConfigTable;
