import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { singleDetailsBoxStyle } from "../../utils";

function AccountInformation({ data }) {
  return (
    <Box>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Account Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Account Name:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {data && data?.accountName}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Account Number:</Typography>
          <Typography align="left">{data && data?.accountNumber}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Bank Name:</Typography>
          <Typography align="left">{data && data?.bankName}</Typography>
        </Box>

        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Bank Code:</Typography>
          <Typography align="left">{data && data?.bankCode}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default AccountInformation;
