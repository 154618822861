import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
  safeJSONParse,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

export default function PreviewBookedDealModal({
  openDialog,
  handleCloseDialog,
  selectedTicket,
}) {
  const url = `${process.env.BASE_URL}sport/fetch-booked-deal-by-code/${selectedTicket?.bookingCode}`;
  const modalChipTitleStyles = {
    display: "block",
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  // "data": {

  //     "bookingCode": "BETCOM-SP23032093",
  //     "linesCount": 7,
  //     "totalStakedAmount": "500000.00",
  //     "betSlips": "[{\"eventId\":\"69e1d9a7-ae9e-43ef-b2ac-f87330dc73c6\",\"betType\":\"2\"},{\"eventId\":\"d8c38ff2-d89d-4791-8a87-8dbd0d973d31\",\"betType\":\"1\"},{\"eventId\":\"e8e37de1-6006-4f68-8763-18cfd805eabf\",\"betType\":\"2\"},{\"eventId\":\"68f91a35-4dc2-40a1-b6c4-6f74fd6c4ac3\",\"betType\":\"1\"},{\"eventId\":\"541287ea-9449-421d-80d6-5ec433688452\",\"betType\":\"1\"},{\"eventId\":\"14d8232d-67ae-4df5-a717-4320b02b3bf7\",\"betType\":\"X\"},{\"eventId\":\"40e8af07-5408-4580-a6a6-1f014c760798\",\"betType\":\"2\"}]",
  //                "gameStatus": "ongoing",
  //     "details": "{}",
  //     "paymentConfigs": "[{\"cut\":0,\"sn\":7,\"stake\":166666.66666666666,\"isPayable\":true,\"potentialWinAmount\":58658295.890502},{\"cut\":1,\"sn\":6,\"stake\":166666.66666666666,\"isPayable\":true,\"potentialWinAmount\":3626172.3821104695},{\"cut\":2,\"sn\":5,\"stake\":166666.66666666666,\"isPayable\":true,\"potentialWinAmount\":523051.2389090668}]",

  // }

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, ticket data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  if (!data)
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          BOOKED TICKET PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Player Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.firstname} &nbsp; {data?.User?.lastname}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Email:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.email}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Phone Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.phone}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Role:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.role}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption" sx={modalChipTitleStyles}>
                      Player Status:
                    </Typography>
                    {/* <Typography sx={dialogMainTextStyles}> */}
                    {data?.User?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                    {/* </Typography> */}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Date Joined:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.User?.createdAt).format("D MMM, YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Ticket Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Ticket ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.ticketId}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Booking Code:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.bookingCode}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Total Staked Amount:
                    </Typography>
                    <NumberFormat
                      value={data?.totalStakedAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>

                  <Box my={1}>
                    <Typography variant="caption">Lines Count:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.linesCount}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Payment Config:</Typography>
                    {safeJSONParse(data?.paymentConfigs)?.map((item) => (
                      <Box my={0.1} key={item?.cut}>
                        <Typography sx={dialogMainTextStyles}>
                          Cut: {item?.cut} &nbsp; Pot. Win: &#8358;
                          {currencyFormater(item?.potentialWinAmount)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption" sx={modalChipTitleStyles}>
                      Ticket Status:
                    </Typography>

                    {data?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </Box>

                  <Box my={1}>
                    <Typography variant="caption">Date Created:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.createdAt).format("D MMM, YYYY hh:mm a")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box my={2}>
              <Typography gutterBottom sx={previewSubTitleStyles}>
                Product Information
              </Typography>
              <Box>
                <img
                  src={data?.Product?.imageUrl}
                  alt={data?.Product?.title}
                  width={140}
                  height={100}
                  style={{
                    width: "140px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box my={1}>
                <Typography variant="caption">Name:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Product?.title}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption"> Cash Equivalence:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  &#8358;{currencyFormater(data?.Product?.cashEquivalence)}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Min Stake Amount:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  &#8358;{currencyFormater(data?.Product?.minStakedAmount)}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Max Stake Amount:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  &#8358;{currencyFormater(data?.Product?.maxStakedAmount)}
                </Typography>
              </Box>

              <Box my={1}>
                <Typography variant="caption">Product Description:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Product?.description}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Product Description:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Product?.eventTypes}
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
