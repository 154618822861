import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import CommissionTable from "./CommissionTable";
import CommissionFilter from "./CommissionFilter";
import axios from "axios";
import dayjs from "dayjs";
import useSWR, { useSWRConfig, mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  agentRoles,
} from "../../../utils";
import FullScreenError from "../../../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../../../components/ClearFilters/ClearFilters";
import { AppContext } from "../../../context";
import weekOfYear from "dayjs/plugin/weekOfYear";
import AdminLayout from "../../AdminLayout/AdminLayout";
import WeekTabs from "./WeekTabs";
dayjs.extend(weekOfYear);

function Approval3() {
  const [selected, setSelected] = useState("");
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [agentLevel, setAgentLevel] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chosenWeek, setChosenWeek] = useState(dayjs().week());
  const [processing, setProcessing] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [minCommission, setMinCommission] = useState(0);
  const [maxCommission, setMaxCommission] = useState(0);
  const [minSale, setMinSale] = useState(0);
  const [maxSale, setMaxSale] = useState(0);

  const { handleOpenSnackbar, adminData } = useContext(AppContext);

  const { mutate } = useSWRConfig();
  const currentWeek = dayjs().week();
  const currentYear = dayjs().year();
  let prevWeeks = [];
  let futureWeeks = [];
  let weeks = [];
  let day = 2;
  let prevDay = 2;

  for (let index = currentWeek; index <= 53; index++) {
    const element = dayjs().day(day);
    if (element?.$y > currentYear) {
      break;
    }
    futureWeeks.push(element);
    day = day + 7;
  }

  for (let index = currentWeek; index > 1; index--) {
    prevDay = prevDay - 7;
    const element = dayjs().day(prevDay);
    prevWeeks.push(element);
  }
  prevWeeks = prevWeeks.reverse();
  weeks = [...prevWeeks, ...futureWeeks];
  // console.log("weeks", weeks);
  // console.log("curentWeek", currentWeek);
  let filterURL = `${
    process.env.BASE_URL
  }commissions/get-aggregate-commissions?type=superadmin&limit=${rowsPerPage}&page=${page}&week=${chosenWeek}&year=${dayjs().year()}`;

  const handleChangeRowPerpage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];
  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sUserName = "userName";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinWonAmount = "minAmount";
  let sMaxWonAmount = "maxAmount";
  let sMinComm = "minComm";
  let sMaxComm = "maxComm";
  let sMinSale = "minSale";
  let sMaxSale = "maxSale";
  let sAgentLevel = "agentLevel";
  let sPaymentStatus = "paymentStatus";
  let sDebtStatus = "debtStatus";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleChangeMinSale = (e) => {
    setMinSale((curr) => e.target.value);
    sessionStorage.setItem(sMinSale, e.target.value);
  };
  const handleChangeMaxSale = (e) => {
    setMaxSale((curr) => e.target.value);
    sessionStorage.setItem(sMaxSale, e.target.value);
  };

  const handleChangeMinimumAmount = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinWonAmount, e.target.value);
  };

  const handleChangeMaximumAmount = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxWonAmount, e.target.value);
  };
  const handleChangeMinimumComm = (e) => {
    setMinCommission(e.target.value);
    sessionStorage.setItem(sMinComm, e.target.value);
  };

  const handleChangeMaximumComm = (e) => {
    setMaxCommission(e.target.value);
    sessionStorage.setItem(sMaxComm, e.target.value);
  };
  const handleChangeAgentLevel = (e) => {
    setAgentLevel((curr) => e.target.value);
    sessionStorage.setItem(sAgentLevel, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sUserName);
    sessionStorage.removeItem(sMinComm);
    sessionStorage.removeItem(sMaxComm);
    sessionStorage.removeItem(sMinWonAmount);
    sessionStorage.removeItem(sMaxWonAmount);
    sessionStorage.removeItem(sMinSale);
    sessionStorage.removeItem(sMaxSale);
    sessionStorage.removeItem(sAgentLevel);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssUserName = sessionStorage.getItem(sUserName);
    if (ssUserName) {
      setUserName(ssUserName);
      setHasFiltered(true);
    }

    // let ssStartDate = sessionStorage.getItem(sStartDate);
    // if (ssStartDate) {
    //   setStartDate(ssStartDate);
    //   setHasFiltered(true);
    // }
    // let ssEndDate = sessionStorage.getItem(sEndDate);
    // if (ssEndDate) {
    //   setEndDate(ssEndDate);
    //   setHasFiltered(true);
    // }
    let ssMinWonAmount = sessionStorage.getItem(sMinWonAmount);
    if (ssMinWonAmount) {
      setMinAmount(ssMinWonAmount);
      setHasFiltered(true);
    }
    let ssMaxWonAmount = sessionStorage.getItem(sMaxWonAmount);
    if (ssMaxWonAmount) {
      setMaxAmount(ssMaxWonAmount);
      setHasFiltered(true);
    }

    let ssMinSale = sessionStorage.getItem(sMinSale);
    if (ssMinSale) {
      setMinSale(ssMinSale);
      setHasFiltered(true);
    }
    let ssMaxSale = sessionStorage.getItem(sMaxSale);
    if (ssMaxSale) {
      setMaxSale(ssMaxSale);
      setHasFiltered(true);
    }

    let ssAgentLevel = sessionStorage.getItem(sAgentLevel);
    if (ssAgentLevel) {
      setAgentLevel((curr) => ssAgentLevel);
      setHasFiltered(true);
    }
    // Updating URL

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minAmount) {
      if (!filterURL.includes(sMinWonAmount)) {
        filterURL = filterURL + `&minWonAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxWonAmount)) {
        filterURL = filterURL + `&maxWonAmount=${maxAmount}`;
      }
    }
    if (minSale) {
      if (!filterURL.includes(sMinSale)) {
        filterURL = filterURL + `&minSale=${minSale}`;
      }
    }
    if (maxSale) {
      if (!filterURL.includes(sMaxSale)) {
        filterURL = filterURL + `&maxSale=${maxSale}`;
      }
    }

    if (userName) {
      if (!filterURL.includes(sUserName)) {
        filterURL = filterURL + `&userName=${userName}`;
      }
    }
    if (agentLevel) {
      if (!filterURL.includes(sAgentLevel)) {
        filterURL = filterURL + `&agentLevel=${agentLevel}`;
      }
    }
    if (minCommission) {
      if (!filterURL.includes(sMinComm)) {
        filterURL = filterURL + `&minCommAmount=${minCommission}`;
      }
    }
    if (maxCommission) {
      if (!filterURL.includes(sMaxComm)) {
        filterURL = filterURL + `&maxCommAmount=${maxCommission}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  const handleGetCurrentTuesday = () => {
    let startDay = dayjs();
    while (startDay.day() !== 2) {
      if (startDay.day() > 2) {
        startDay = startDay.subtract(1, "day");
      } else {
        startDay = startDay.add(1, "day");
      }
    }

    // console.log("startDay", startDay.add(6, "day"));
    let endDay = startDay.add(6, "day");
    startDay = startDay.format("YYYY-MM-DD");
    endDay = endDay.format("YYYY-MM-DD");
    let result = { startDay, endDay };
    return result;
  };

  useEffect(() => {
    const { startDay, endDay } = handleGetCurrentTuesday();
    setStartDate((curr) => startDay);
    setEndDate((curr) => endDay);
  }, []);
  useEffect(() => {
    handleSetSessionStorage();

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleSelectTicket = (ticket) => setSelectedTicket(ticket);

  const url = `${
    process.env.BASE_URL
  }commissions/get-aggregate-commissions?type=superadmin&limit=${rowsPerPage}&page=${page}&week=${chosenWeek}&year=${dayjs().year()}&startDate=${startDate}&endDate=${endDate}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus("");
    setUserName("");
    setAgentLevel("");
    setMinAmount(0);
    setMaxAmount(0);
    setMinSale((curr) => 0);
    setMaxSale((curr) => 0);
    setMinCommission((curr) => 0);
    setMaxCommission((curr) => 0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const handleCancelFilters = async () =>
    mutate(filterURL, async () => {
      try {
        filterURL = `${
          process.env.BASE_URL
        }commissions/get-aggregate-commissions?type=superadmin&limit=${rowsPerPage}&page=${page}&week=${chosenWeek}&year=${dayjs().year()}`;
        handleResetFields();
        checkExpiredAuthToken();
        const token = getAuthToken();

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPage((curr) => 1);
        setHasFiltered((curr) => false);
        await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleApproveOrdinaryAgt = async () => {
    try {
      const token = getAuthToken();
      setProcessing(true);
      const year = dayjs().year();
      let body = {
        chosenWeek,
        chosenYear: year,
        approvalId: adminData?.adminId,
        role: agentRoles[1]?.value, //"ordinaryagent"
      };

      const { data } = await axios.post(
        `${process.env.BASE_URL}commissions/confirm-approval3-commissions`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await mutate(filterURL);
      setProcessing(false);
      if (data?.data?.message) {
        return handleOpenSnackbar({
          message: data?.data?.message,
          severity: "success",
        });
      }

      return handleOpenSnackbar({
        message: "Approval Request Successful",
        severity: "error",
      });
    } catch (err) {
      setProcessing(false);
      if (err?.response?.data?.responsemessage?.message) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.message,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    }
  };

  const handleApprovePrincipalAgt = async () => {
    try {
      const token = getAuthToken();
      setProcessing(true);
      const year = dayjs().year();
      let body = {
        chosenWeek,
        chosenYear: year,
        approvalId: adminData?.adminId,
        role: agentRoles[3]?.value, //"principalagent"
      };

      const { data } = await axios.post(
        `${process.env.BASE_URL}commissions/confirm-approval3-commissions`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await mutate(filterURL);
      setProcessing(false);
      if (data?.data?.message) {
        return handleOpenSnackbar({
          message: data?.data?.message,
          severity: "success",
        });
      }

      return handleOpenSnackbar({
        message: "Approval Request Successful",
        severity: "error",
      });
    } catch (err) {
      setProcessing(false);
      if (err?.response?.data?.responsemessage?.message) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.message,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    }
  };

  const handleApproveSuperAgt = async () => {
    try {
      const token = getAuthToken();
      setProcessing(true);
      const year = dayjs().year();
      let body = {
        chosenWeek,
        chosenYear: year,
        approvalId: adminData?.adminId,
        role: agentRoles[2]?.value, //"superagent"
      };

      const { data } = await axios.post(
        `${process.env.BASE_URL}commissions/confirm-approval3-commissions`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await mutate(filterURL);
      setProcessing(false);
      if (data?.data?.message) {
        return handleOpenSnackbar({
          message: data?.data?.message,
          severity: "success",
        });
      }

      return handleOpenSnackbar({
        message: "Approval Request Successful",
        severity: "error",
      });
    } catch (err) {
      setProcessing(false);
      if (err?.response?.data?.responsemessage?.message) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.message,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    }
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  let allSuperAgents = [],
    allOrdinaryAgents = [],
    allPrincipalAgents = [];
  allSuperAgents =
    data &&
    data?.data?.length > 0 &&
    data?.data?.filter((item) => item?.agentLevel === "superagent");
  allOrdinaryAgents =
    data &&
    data?.data?.length > 0 &&
    data?.data?.filter((item) => item?.agentLevel === "ordinaryagent");
  allPrincipalAgents =
    data &&
    data?.data?.length > 0 &&
    data?.data?.filter((item) => item?.agentLevel === "principalagent");
  return (
    <AdminLayout>
      <div sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="primary"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <CommissionFilter
            status={status}
            userName={userName}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            minSale={minSale}
            maxSale={maxSale}
            agentLevel={agentLevel}
            minCommission={minCommission}
            maxCommission={maxCommission}
            handleChangeMinSale={handleChangeMinSale}
            handleChangeMaxSale={handleChangeMaxSale}
            handleChangeMinimumComm={handleChangeMinimumComm}
            handleChangeMaximumComm={handleChangeMaximumComm}
            handleChangeAgentLevel={handleChangeAgentLevel}
            handleChangeMinimumAmount={handleChangeMinimumAmount}
            handleChangeMaximumAmount={handleChangeMaximumAmount}
            handleChangeStatus={handleChangeStatus}
            handleChangeUserName={handleChangeUserName}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        <Grid container spacing={2}>
          <Grid item md={12} lg={10}>
            <WeekTabs
              weeks={weeks}
              selected={selected}
              setSelected={setSelected}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setChosenWeek={setChosenWeek}
              handleCancelFilters={handleCancelFilters}
            />
            <CommissionTable
              data={data?.data}
              selectedTicket={selectedTicket}
              hasFiltered={hasFiltered}
              isFiltering={isFiltering}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangeRowPerpage={handleChangeRowPerpage}
              handleChangePage={handleChangePage}
              handleSelectTicket={handleSelectTicket}
              page={page}
              totalCount={data?.totalCount}
            />
          </Grid>
          <Grid item md={12} lg={2}>
            <div className="appoveBtnDiv">
              <Button
                variant="contained"
                color="success"
                disabled={
                  data?.data?.length < 1 ||
                  allOrdinaryAgents?.length < 1 ||
                  (adminData?.role &&
                    !["superadmin"]?.includes(adminData?.role)) ||
                  processing
                }
                onClick={() => handleApproveOrdinaryAgt()}
              >
                {processing ? "Processing" : "Approve Payment"}
              </Button>
              <Typography variant="caption">For Ordinary Agents</Typography>
            </div>
            <div className="appoveBtnDiv">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  data?.data?.length < 1 ||
                  allSuperAgents?.length < 1 ||
                  (adminData?.role &&
                    !["superadmin"]?.includes(adminData?.role)) ||
                  processing
                }
                onClick={() => handleApproveSuperAgt()}
              >
                {processing ? "Processing" : "Approve Payment"}
              </Button>
              <Typography variant="caption">For Super Agents</Typography>
            </div>
            <div className="appoveBtnDiv">
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  data?.data?.length < 1 ||
                  allPrincipalAgents?.length < 1 ||
                  (adminData?.role &&
                    !["superadmin"]?.includes(adminData?.role)) ||
                  processing
                }
                onClick={() => handleApprovePrincipalAgt()}
              >
                {processing ? "Processing" : "Approve Payment"}
              </Button>
              <Typography variant="caption">For Principal Agents</Typography>
            </div>{" "}
          </Grid>
        </Grid>
      </div>
    </AdminLayout>
  );
}

export default Approval3;
