import React from "react";
import Container from "@mui/material/Container";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken, limit } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";
import PreviewLogModal from "../components/AdminLogsComponents/PreviewLogDialog";
import AdminLogsTable from "../components/AdminLogsComponents/AdminLogsTable";

function AdminLogs() {
  const [page, setPage] = React.useState(1);
  const [requestMethod, setRequestMethod] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState({});
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const { adminId } = useParams();

  // SessionStorage Variable Declarations

  let sRequestMethod = "requestMethod";
  let sUserName = "userName";
  let sStartDate = "startDate";
  let sEndDate = "endDate";

  // change methods for search filters
  const handleChangeRequestMethod = (e) => {
    setRequestMethod(e.target.value);
    sessionStorage.setItem(sRequestMethod, e.target.value);
  };
  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sRequestMethod);
    sessionStorage.removeItem(sUserName);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
  };

  const handleSetSessionStorage = () => {
    let sRequestMethod, sUserName, sStartDate, sEndDate;
    sRequestMethod = sessionStorage.getItem(sRequestMethod);

    if (sRequestMethod) {
      setRequestMethod(sRequestMethod);
    }
    sUserName = sessionStorage.getItem(sUserName);
    if (sUserName) {
      setUserName(sUserName);
    }
    sStartDate = sessionStorage.getItem(sStartDate);
    if (sStartDate) {
      setStartDate(sStartDate);
    }
    sEndDate = sessionStorage.getItem(sEndDate);
    if (sEndDate) {
      setEndDate(sEndDate);
    }
  };

  const handleSelectLog = (user) => setSelectedLog(user);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const url = `${process.env.BASE_URL}admin/fetch-activities?limit=${limit}&page=${page}&adminId=${adminId}`;

  let filterURL = `${
    process.env.BASE_URL
  }admin/fetch-activities?limit=${limit}&adminId=${adminId}&page=${page}${
    userName && `&search=${userName}`
  }`;

  if (requestMethod !== "") {
    filterURL = filterURL + `&requestMethod=${requestMethod}`;
  }
  if (startDate) {
    filterURL =
      filterURL + `&minCreateDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
  }
  if (endDate) {
    filterURL =
      filterURL + `&maxCreateDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
  }

  const handleResetFields = () => {
    setRequestMethod("");
    setUserName("");
    setStartDate(null);
    setEndDate(null);
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsFiltering(true);
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url, res.data?.data, false);
        setIsFiltering(false);
        return setHasFiltered(true);
      })
      .catch((err) => {
        setIsFiltering(false);
        return err;
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.BASE_URL}admin/fetch-activities?limit=${limit}&page=${
          newPage + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        mutate(url, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancelFilters = () => {
    handleClearSessionStorage();
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <FullScreenError
        title=" Sorry, can't retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Admin Logs Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Admin Logs" />
        {openDialog ? (
          <PreviewLogModal
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            selectedLog={selectedLog}
          />
        ) : null}

        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box> */}

        {/* {showFilter && (
          <AdminLogsFilter
            userName={userName}
            requestMethod={requestMethod}
            handleChangeRequestMethod={handleChangeRequestMethod}
            startDate={startDate}
            endDate={endDate}
            handleChangeUserName={handleChangeUserName}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleFiterSubmit={handleFiterSubmit}
          />
        )} */}

        <AdminLogsTable
          isFiltering={isFiltering}
          hasFiltered={hasFiltered}
          selectedLog={selectedLog}
          handleChangePage={handleChangePage}
          handleSelectLog={handleSelectLog}
          handleOpenDialog={handleOpenDialog}
          userName={userName}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default AdminLogs;
