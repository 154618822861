import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { GiTabletopPlayers } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { RiDeleteBackFill } from "react-icons/ri";
import { MdThumbUp } from "react-icons/md";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import "../../styles/analytics.css";
import HalfErrorScreen from "../../components/HalfErrorScreen/HalfErrorScreen";
import AnalyticsCard from "../../components/AnalyticsCard/AnalyticsCard";

const url = `${process.env.BASE_URL}user/analytics`;

function UsersAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, players analytics data cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return null;
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalUsers}
            icon={<GiTabletopPlayers className="cardIcon" />}
            text={data && data?.totalUsers <= 1 ? "Player" : "Players"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.suspendedUsers}
            icon={<AiFillDislike className="errorCardIcon" />}
            text={
              data && data?.suspendedUsers <= 1
                ? "Suspended Player"
                : "Suspended Players"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalUsers - data?.suspendedUsers}
            icon={<MdThumbUp className="cardIcon" />}
            text={
              data && data?.totalUsers - data?.suspendedUsers <= 1
                ? "Active Player"
                : "Active Players"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.deletedUsers}
            icon={<RiDeleteBackFill className="errorCardIcon" />}
            text={
              data && data?.deletedUsers <= 1
                ? "Deleted Player"
                : "Deleted Players"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UsersAnalytics;
