import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Players from "./pages/Players";
import Login from "./pages/Login";
import PlayerDetailsPage from "./pages/PlayerDetailsPage";
import NotFoundPage from "./pages/NotFoundPage";
import Agents from "./pages/Agents";
import Transactions from "./pages/Transactions";
import Games from "./pages/Games";
import AddAgent from "./pages/AddAgent";
import Admins from "./pages/Admins";
import AddAdmin from "./pages/AddAdmin";
import Tickets from "./pages/Tickets";
import WithdrawalRequests from "./pages/WithdrawalRequests";
import WithdrawalRequestDetails from "./pages/WithdrawalRequestDetails";
import GameInstances from "./pages/GameInstances";
import AgentRoles from "./pages/AgentRoles";
import AdminRoles from "./pages/AdminRoles";
import BookCodes from "./pages/BookCodes";
import Logs from "./pages/Logs";
import Transaction from "./pages/Transaction";
import Banners from "./pages/Banners";
import AddBanner from "./pages/AddBanner";
import SiteSettings from "./pages/SiteSettings";
import AddSiteConfig from "./pages/AddSiteConfig";
import BonusConfigurations from "./pages/BonusConfigurations";
import AddBonusConfiguration from "./pages/AddBonusConfiguration";
import AvatarSettings from "./pages/AvatarSettings";
import AddGame from "./pages/AddGame";
import AddGameInstance from "./pages/AddGameInstance";
import EditGameInstance from "./pages/EditGameInstance";
import GameInstanceDetails from "./pages/GameInstanceDetails";
import StaticPages from "./pages/StaticPages";
import AddStaticPage from "./pages/AddStaticPage";
import StaticPageDetails from "./pages/StaticPageDetails";
import EditStaticPage from "./pages/EditStaticPage";
import AgentDetailsPage from "./pages/AgentDetailsPage";
import GameDetailsPage from "./pages/GameDetailsPage";
import EditGame from "./pages/EditGame";
import AddMastersPage from "./pages/AddMastersPage";
import AddHowItWorks from "./pages/AddHowItWorks";
import AdminDetails from "./pages/AdminDetails";
import AdminLogs from "./pages/AdminLogs";
import EditBanner from "./pages/EditBanner";
import SiteSettingsDetails from "./pages/SiteSettingsDetails";
import UpdateSiteSetting from "./pages/UpdateSiteSetting";
import BonusDetails from "./pages/BonusDetails";
import Reports from "./pages/Reports";
import LotteryImagesSettings from "./pages/LotteryImagesSettings";
import TicketDetails from "./pages/TicketDetails";
import BookingCodeDetails from "./pages/BookingCodeDetails";
import DeviceAnalytics from "./pages/DeviceAnalytics";
import EditBonusConfiguration from "./pages/EditBonusConfiguration";
import AdminTransactions from "./pages/AdminTransactions";
import AgentsCommission from "./pages/AgentsCommission";
import AddHeader from "./pages/AddHeader";
import AllHeaders from "./pages/AllHeaders";
import HeaderDetails from "./pages/HeaderDetails";
import EditDynamicHeader from "./pages/EditDynamicHeader";
import PlayerReports from "./pages/PlayerReports";
import SinglePlayerReports from "./pages/SinglePlayerReports";
import AgentsReports from "./pages/AgentsReports";
import SingleAgentReports from "./pages/SingleAgentReports";
import Payouts from "./pages/Payouts";
import BonusTickets from "./pages/BonusTickets";
import CommissionReports from "./pages/CommissionReports";
import SingleAgentCommissionReports from "./pages/SingleAgentCommissionReports";
import ExcludeBetTypes from "./pages/ExcludeBetTypes";
import AddLeague from "./pages/AddLeague";
import AddTeam from "./pages/AddTeam";
import AddProductCategory from "./pages/AddProductCategory";
import AddProduct from "./pages/AddProduct";
import AddEvent from "./pages/AddEvent";
import VirtualAgents from "./pages/VirtualAgents";
import AddInfluencer from "./pages/AddInfluencer";
import Influencers from "./pages/Influencers";
import AddBundleBonus from "./pages/AddBundleBonus";
import ProductCategories from "./pages/ProductCategories";
import EditProductCategory from "./pages/EditProductCategory";
import Leagues from "./pages/Leagues";
import Teams from "./pages/Teams";
import EditTeam from "./pages/EditTeam";
import Events from "./pages/Events";
import EventDetailsPage from "./pages/EventDetailsPage";
import UpdateEvent from "./pages/UpdateEvent";
import UpdateLeague from "./pages/UpdateLeague";
import Products from "./pages/Products";
import UpdateProduct from "./pages/UpdateProduct";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import BundleBonuses from "./pages/BundleBonuses";
import BundleBonusDetails from "./pages/BundleBonusDetails";
import PendingResults from "./pages/PendingResults";
import SportbetResults from "./pages/SportbetResults";
import SportResultDetailsPage from "./pages/SportResultDetailsPage";
import ElapsedEvents from "./pages/ElapesedEvents";
import LeagueDetailsPage from "./pages/LeagueDetailsPage";
import Dealers from "./pages/Dealers";
import DealerDetailsPage from "./pages/DealerDetailsPage";
import ProductRequest from "./pages/ProductRequest";
import PrizeClaims from "./pages/PrizeClaims";
import PrizeDetails from "./pages/PrizeDetails";
import BookDeals from "./pages/BookDeals";
import BookingDealDetails from "./pages/BookingDealDetails";
import ElapsedVirtualEvents from "./pages/ElapsedVirtualEvents";
import SportsResult from "./pages/SportsResults";
import WinningCheck from "./pages/WinningCheck";
import DealsTickets from "./pages/DealsTickets";
import DealDetails from "./pages/DealDetails";
import { ROUTE_LINKS } from "./utils/routeLinks";
import LottoProducts from "./pages/LottoProducts";
import LottoProductDetailsPage from "./pages/LottoProductDetailsPage";
import UpdateLottoProduct from "./pages/UpdateLottoProduct";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/transactions/transaction-details/:transactionId"
          element={<Transaction />}
        />
        <Route
          path="games/more-details/:lotteryId"
          element={<GameDetailsPage />}
        />
        <Route path="games/edit-game/:lotteryId" element={<EditGame />} />
        <Route path="games/add-lottery" element={<AddGame />} />
        <Route
          path="configurations/avatar-settings"
          element={<AvatarSettings />}
        />
        <Route path="/configurations/bonus" element={<BonusConfigurations />} />
        <Route
          path="/withdrawal-requests/withdrawal-request-details/:requestId"
          element={<WithdrawalRequestDetails />}
        />
        <Route
          path="/player-reports/single-player/:userId/:userName"
          element={<SinglePlayerReports />}
        />
        <Route
          path="/agent-reports/single-player/:userId/:userName"
          element={<SingleAgentReports />}
        />
        <Route
          path="/agents/exclude-bettypes/:agentId"
          element={<ExcludeBetTypes />}
        />
        <Route
          path="/commission-reports/single-agent/:userId/:userName"
          element={<SingleAgentCommissionReports />}
        />
        <Route
          path="/games/add-how-it-works/:lotterySlug"
          element={<AddHowItWorks />}
        />
        <Route
          path="/games/add-masters-page/:lotterySlug"
          element={<AddMastersPage />}
        />
        <Route
          path="/admins/more-details/:adminId"
          element={<AdminDetails />}
        />
        <Route path="/admins/admin-logs/:adminId" element={<AdminLogs />} />
        <Route
          path="/players/player-details/:userId"
          element={<PlayerDetailsPage />}
        />
        <Route
          path="/dealers/dealer-details/:userId"
          element={<DealerDetailsPage />}
        />
        <Route
          path="/agents/agent-details/:userId"
          element={<AgentDetailsPage />}
        />
        <Route path="banners/edit-banner/:bannerId" element={<EditBanner />} />
        <Route
          path="/configurations/site-settings/update-details/:settingId"
          element={<UpdateSiteSetting />}
        />
        <Route
          path="configurations/bonus/bonus-detail/:bonusId"
          element={<BonusDetails />}
        />
        <Route
          path="/configurations/site-settings/settings-details/:settingId"
          element={<SiteSettingsDetails />}
        />
        <Route
          path="/configurations/site-settings"
          element={<SiteSettings />}
        />
        <Route
          path="static-page/edit-static-page/:contentId"
          element={<EditStaticPage />}
        />
        <Route
          path="static-page/edit-dynamic-page/:contentId"
          element={<EditDynamicHeader />}
        />
        <Route
          path="static-page/more-detail/:contentId"
          element={<StaticPageDetails />}
        />
        <Route
          path="static-page/page-detail/:contentId"
          element={<HeaderDetails />}
        />
        <Route path="static-pages/all-pages" element={<StaticPages />} />
        <Route path="static-pages/view-headers" element={<AllHeaders />} />
        <Route path="add-static-page" element={<AddStaticPage />} />
        <Route path="add-header" element={<AddHeader />} />
        <Route
          path="configurations/site-settings/addsetting"
          element={<AddSiteConfig />}
        />
        <Route
          path="games/create-game-instance/:lotteryId"
          element={<AddGameInstance />}
        />
        <Route
          path="games-instance/edit-game/:gameId"
          element={<EditGameInstance />}
        />
        <Route
          path="bookedcodes/ticket-details/:ticketId/:bookingCode"
          element={<BookingCodeDetails />}
        />
        <Route
          path="bookeddeals/ticket-details/:ticketId/:bookingCode"
          element={<BookingDealDetails />}
        />
        <Route
          path="deals/ticket-details/:ticketId"
          element={<DealDetails />}
        />
        <Route
          path="tickets/ticket-details/:ticketId"
          element={<TicketDetails />}
        />
        <Route
          path="product-categories/edit-product-category/:categoryId"
          element={<EditProductCategory />}
        />
        <Route path="add-team" element={<AddTeam />} />

        <Route path="edit-team-details/:teamId" element={<EditTeam />} />
        <Route
          path="products/edit-product/:productId"
          element={<UpdateProduct />}
        />
        <Route
          path="lotto-products/edit-product/:productId"
          element={<UpdateLottoProduct />}
        />

        <Route
          path="products/product-details/:productId"
          element={<ProductDetailsPage />}
        />
        <Route
          path="lotto-products/lotto-product-details/:productId"
          element={<LottoProductDetailsPage />}
        />
        <Route
          path="sports-bet/event-details/:eventId"
          element={<EventDetailsPage />}
        />
        <Route
          path="sports-bet/result-details/:resultId"
          element={<SportResultDetailsPage />}
        />
        <Route path="sports-bet/pending-results" element={<PendingResults />} />
        <Route path="sports-bet/view-results" element={<SportbetResults />} />
        <Route path="edit-event-details/:eventId" element={<UpdateEvent />} />
        <Route
          path="edit-league-details/:leagueId"
          element={<UpdateLeague />}
        />

        <Route
          path="configurations/bonus/edit-bonus-detail/:bonusId"
          element={<EditBonusConfiguration />}
        />
        <Route
          path="games-instance/more-details/:gameId"
          element={<GameInstanceDetails />}
        />
        <Route
          path="sports-bet/league-details/:leagueId"
          element={<LeagueDetailsPage />}
        />

        <Route path="banners/add" element={<AddBanner />} />
        <Route path="winning-check" element={<WinningCheck />} />
        <Route
          path="configurations/bonus/add-bonus"
          element={<AddBonusConfiguration />}
        />
        <Route
          path="configurations/lottery-images"
          element={<LotteryImagesSettings />}
        />
        <Route
          path="configurations/bonus/bundle-bonus-detail/:bonusId"
          element={<BundleBonusDetails />}
        />
        <Route
          path="sports-bet/prize-details/:claimId"
          element={<PrizeDetails />}
        />

        <Route path="/agents/commission" element={<AgentsCommission />} />
        <Route path="admins-transactions" element={<AdminTransactions />} />
        <Route path="sports-bet/sports-results" element={<SportsResult />} />
        <Route path="device-analytics" element={<DeviceAnalytics />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="product-requests" element={<ProductRequest />} />
        <Route path="products" element={<Products />} />
        <Route path={ROUTE_LINKS.LOTTO_PRODUCTS} element={<LottoProducts />} />

        <Route path="bonus-tickets" element={<BonusTickets />} />
        <Route path="deals-tickets" element={<DealsTickets />} />
        <Route path="banners" element={<Banners />} />
        <Route path="payouts" element={<Payouts />} />
        <Route path="players" element={<Players />} />
        <Route path="dealers" element={<Dealers />} />
        <Route path="virtual-agents" element={<VirtualAgents />} />
        <Route path="agents" element={<Agents />} />
        <Route path="bookedcodes" element={<BookCodes />} />
        <Route path="withdrawal-requests" element={<WithdrawalRequests />} />
        <Route path="games-instance" element={<GameInstances />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="roles/agentroles" element={<AgentRoles />} />
        <Route path="roles/adminroles" element={<AdminRoles />} />
        <Route path="logs" element={<Logs />} />
        <Route path="player-reports" element={<PlayerReports />} />
        <Route path="agent-reports" element={<AgentsReports />} />
        <Route path="reports" element={<Reports />} />
        <Route path="commission-reports" element={<CommissionReports />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="agents/add" element={<AddAgent />} />
        <Route path="influencers/add" element={<AddInfluencer />} />
        <Route path="influencers" element={<Influencers />} />
        <Route path="bookeddeals" element={<BookDeals />} />
        <Route path="add-league" element={<AddLeague />} />
        <Route path="influencers/add-bundle" element={<AddBundleBonus />} />
        <Route path="configurations/bundle-bonus" element={<BundleBonuses />} />
        <Route path="add-product-category" element={<AddProductCategory />} />
        <Route path="sports-bet/all-leagues" element={<Leagues />} />
        <Route path="sports-bet/prize-claims" element={<PrizeClaims />} />
        <Route path="sports-bet/all-teams" element={<Teams />} />
        <Route path="sports-bet/view-events" element={<Events />} />
        <Route path="sports-bet/elapsed-events" element={<ElapsedEvents />} />
        <Route
          path="sports-bet/elapsed-virtual-events"
          element={<ElapsedVirtualEvents />}
        />
        <Route path="product-categories" element={<ProductCategories />} />
        <Route path="add-product" element={<AddProduct />} />
        <Route path="add-event" element={<AddEvent />} />
        <Route path="games" element={<Games />} />
        <Route path="admins" element={<Admins />} />
        <Route path="admins/add" element={<AddAdmin />} />
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
