import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import AddRoleDialog from "./AddRoleDialog";
import { DEALER, currencyFormater, rowsPerPageOptions } from "../../utils";
import TableCSVButton from "../TableCSVButton/TableCSVButton";
import { renderStatus } from "../../utils/statusChip";

function ProductRequestsTable({
  totalCount,
  selectedUser,
  rowsPerPage,
  isFiltering,
  handleChangePage,
  handleSelectRequest,
  handleOpenDialog,
  handleChangeRowsPerPage,
  handleOpenDeactivateUserDialog,
  handleOpenActivateUserDialog,
  page,
  data,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event, request) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(request?.requestId);
    handleSelectRequest(request);
  };

  const handleOpenRoleDialog = (requestId) => {
    setOpenDialog(true);
    setSelectedId(requestId);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const moveToUserDetailsPage = () => {
    if (selectedUser.role === DEALER) {
      return navigate(`/dealers/dealer-details/${selectedId}`);
    }
    navigate(`player-details/${selectedId}`);
  };

  const headCells = [
    "Image",
    "Request ID",
    "Product Name",
    "Price",
    "Status",
    "Date Created",
    "Description",
  ];
  const headers = [
    "Request ID",
    "Product Name",
    "Price",
    "Status",
    "Date Created",
    "Description",
  ];
  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.requestId,
        item?.title,
        item?.price,
        item?.approvalStatus,
        item?.createdAt,
        item?.description,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="request.csv" />
      ) : null}
      <AddRoleDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        selectedUser={selectedId}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.requestId}>
                  <TableCell>
                    <img
                      src={row?.imageUrl}
                      alt={row?.title}
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{row?.requestId}</TableCell>
                  <TableCell>{row?.title}</TableCell>
                  <TableCell>NGN{currencyFormater(row?.price)}</TableCell>

                  <TableCell>{renderStatus(row.approvalStatus)}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" paragraph>
                      {row?.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      id="menuBtn"
                      onClick={(e) => handleClick(e, row)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      row={row}
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {/* {!Boolean(selectedUser?.status) ? (
                        <MenuItem
                          onClick={() => handleOpenActivateUserDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Account
                        </MenuItem>
                      ) : null} */}
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Request
                      </MenuItem>
                      <Divider />
                      {/* <MenuItem
                        onClick={() => moveToUserDetailsPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Details
                      </MenuItem> */}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default ProductRequestsTable;
