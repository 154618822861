import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";

export default function DeactivateGameInstanceDialog({
  openDialog,
  handleCloseDialog,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>CONFIRM GAME DEACTIVATION</DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            <DialogContentText>
              Do you want to deactivate this game, click on the deactivate
              button to proceed or, click on the cancel button to ignore
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            {isSubmitting ? "Deactivating" : "Deactivate"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
