import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";

export default function PreviewPayoutModal({
  openDialog,
  handleCloseDialog,
  selectedPayout,
  renderStatus,
}) {
  // const [isSubmitting, setIsSubmitting] = React.useState(false);
  const url = `${process.env.BASE_URL}payout/fetch-payout/${selectedPayout?.payoutId}`;

  // const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, payout data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  if (!data)
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );

  // "bankDetails":"{}",
  // "receipientCode":null,

  const boxStyle = {
    display: "flex",
    gap: "8px",
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          PAYOUT PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box my={1} sx={boxStyle}>
              <Typography>User ID:</Typography>
              <Typography sx={dialogMainTextStyles}>{data?.userId} </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Payout ID:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {data?.payoutId}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Ticket ID:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {data?.ticketId}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Payment Attempts:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {data?.attempts}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Amount:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {currencyFormater(data?.amount)}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Winning Redemption Method:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {data?.winningRedemptionMethod}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Status:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {renderStatus(data?.status)}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Payment Status:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {renderStatus(data?.isPaid)}{" "}
              </Typography>
            </Box>
            <Box my={1} sx={boxStyle}>
              <Typography>Date Created:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {dayjs(data?.createdAt).format("hh:mma, DD MMM YYYY")}{" "}
              </Typography>
            </Box>{" "}
            <Box my={1} sx={boxStyle}>
              <Typography>Date Paid:</Typography>
              <Typography sx={dialogMainTextStyles}>
                {dayjs(parseInt(data?.paymentDate)).format(
                  "hh:mma, DD MMM YYYY"
                )}{" "}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
