import React from "react";
import Paper from "@mui/material/Paper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import { Bar } from "react-chartjs-2";

export function TicketAnalytics({
  title = "No. of Tickets for Last 6 Months",
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: [415, 98, 200, 500, 320, 400],
        backgroundColor: [
          "#ff9800",
          "#66bb6a",
          "#b71c1c",
          "#00d2d2",
          "#00695f",
          "#1769aa",
        ],
      },
    ],
  };

  return (
    <Paper style={{ minHeight: "50vh", padding: "5px" }}>
      <Bar style={{ minHeight: "45vh" }} options={options} data={data} />
    </Paper>
  );
}

export default TicketAnalytics;
