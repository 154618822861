import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import useSWR from "swr";
import TableLoader from "../TableLoader/TableLoader";
import dayjs from "dayjs";
import { getAuthToken } from "../../utils";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useParams } from "react-router-dom";

const tableHead = [
  "First Name",
  "Last Name",
  "Email",
  "Assigned By",
  "Assigned On",
];
function BundleTransactions() {
  const { bonusId } = useParams();

  const url = `${process.env.BASE_URL}bonus/fetch-bundle-transaction/${bonusId}`;

  const fetcher = () => {
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, cant retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;
  //   console.log("dddddddddddddd", data);
  return (
    <Box component={Paper} sx={{ padding: "10px 0px" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="Bonus Transactions table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              {tableHead.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data?.data?.length > 0
              ? data?.data?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row?.assignedBy || "---"}</TableCell>
                    <TableCell style={{ width: 160 }}>
                      {dayjs(row?.assignedOn).format("MMM D, YYYY")}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {data && data?.data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default BundleTransactions;
