import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  boxStyle5,
  currencyFormater,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";
import GameOptions from "../components/GameDetailsComponents/GameOptions";

function SportResultDetailsPage() {
  const { resultId } = useParams();

  const url = `${process.env.BASE_URL}sport/fetch-result/${resultId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  //   "data": {
  //     "id": 1,
  //     "eventId": "a005170b-bab4-4571-8958-43e170102358",
  //     "resultId": "ddd043dd-1fe0-477d-bc05-c4d75050cb78",
  //     "status": "inactive",
  //     "createdAt": "2024-01-15T21:22:34.000Z",
  //     "updatedAt": "2024-01-16T04:18:51.000Z",
  //     "Event": {
  //         "id": 1,
  //         "eventId": "a005170b-bab4-4571-8958-43e170102358",
  //         "": "BARCA - PALMAS",
  //         "homeTeamId": "3e7ff58e-26e5-4f7d-b5fc-2eba497726c0",
  //         "awayTeamId": "82ae72d7-2245-4553-9095-3f69e74c8a4d",
  //         "": "2023-12-31T17:30:00.000Z",
  //         "week": 2,
  //         "endDate": "2023-12-31T19:07:00.000Z",
  //         "status": true,
  //         "deleted": false,
  //         "createdAt": "2023-12-29T13:43:22.000Z",
  //         "updatedAt": "2023-12-29T13:43:22.000Z"
  //     }
  // }
  return (
    <AdminLayout>
      <MetaDecorator title={`Result details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Result details" />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box py={2}>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Draw Name:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.drawName}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Home Team Score:</Typography>
                <Typography align="left">{data && data?.homeScore}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Away Team Score:</Typography>
                <Typography align="left">{data && data?.awayScore}</Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Event Date:</Typography>
                <Typography align="left">
                  {dayjs(data?.Event?.eventDate).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Event Title:</Typography>
                <Typography align="left">
                  {data && data?.Event?.title}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Event Week:</Typography>
                <Typography align="left">
                  {data && data?.Event?.week}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Date Created:</Typography>
                <Typography align="left">
                  {dayjs(data?.createdAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              {/* <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Last Updated:</Typography>
                <Typography align="left">
                  {dayjs(data?.updatedAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box> */}
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Approval Status:</Typography>
                {data?.isApproved ? (
                  <Chip color="success" label="Approved" size="small" />
                ) : (
                  <Chip label="Not Approved" color="error" size="small" />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GameOptions data={data} />
          </Grid>
        </Grid>
        {/* <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Game Instances
          </Typography>
          <GameInstancesTable data={data?.Games} />
        </Box> */}
      </Container>
    </AdminLayout>
  );
}

export default SportResultDetailsPage;
