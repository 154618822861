import React from "react";
import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import {
  currencyFormater,
  renderHostURL,
  tableButtonStyles,
  rowsPerPageOptions,
} from "../../utils";
import TableCSVButton from "../TableCSVButton/TableCSVButton";
import { AppContext } from "../../context";

function ProductsTable({
  data,
  page,
  selectedCategory,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleSelectCategory,
  handleChangePage,
  handleOpenActivateDialog,
  handleOpenDeactivateDialog,
  totalCount,
}) {
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleOpenSnackbar } = useContext(AppContext);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    handleSelectCategory(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToEditProductPage = () => {
    navigate(`edit-product/${selectedCategory?.productId}`);
  };
  const moveToViewProductPage = () => {
    navigate(`product-details/${selectedCategory?.productId}`);
  };
  const handleCopyBannerLink = async () => {
    let host = renderHostURL();
    let res = `${host}/product?productId=${selectedCategory?.productId}`;
    await navigator.clipboard.writeText(res);
    handleOpenSnackbar({
      message: "Link Copied Successfully",
      severity: "info",
    });
  };
  const headCells = [
    "Name",
    "ID",
    "Image",
    "Category",
    "Cash Equivalence",
    "Date Created",
    "Status",
    "Actions",
  ];
  const headers = [
    "Name",
    "ID",
    "Image",
    "Category",
    "Cash Equivalence",
    "Date Created",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.title,
        item?.customProductId,
        item?.imageUrl,
        item?.ProductCategory?.name,
        item?.cashEquivalence,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="products.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="product category table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.productId}>
                  <TableCell>{row?.title}</TableCell>
                  <TableCell>{row?.customProductId}</TableCell>
                  <TableCell>
                    {row?.imageUrl ? (
                      <>
                        <img
                          src={`${row?.imageUrl}`}
                          alt="product image"
                          width="75px"
                          height={"75px"}
                          style={{ objectFit: "contain", overflow: "hidden" }}
                        />{" "}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>{row?.ProductCategory?.name}</TableCell>
                  <TableCell>
                    {" "}
                    &#8358;{currencyFormater(row?.cashEquivalence)}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {!selectedCategory?.status ? (
                        <MenuItem
                          onClick={() => handleOpenActivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate
                        </MenuItem>
                      ) : null}

                      {selectedCategory?.status ? (
                        <MenuItem
                          onClick={() => handleOpenDeactivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem
                        onClick={() => moveToEditProductPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Update Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToViewProductPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleCopyBannerLink()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Copy Banner Link
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default ProductsTable;
