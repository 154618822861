import React, { useState, useEffect, useCallback } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../../../utils";
import ClearFilters from "../../ClearFilters/ClearFilters";
import OverdraftsFilter from "./OverdraftsFilter";
import OverdraftsTable from "./OverdraftsTable";
import PreviewOverdraftModal from "./PreviewOverdraftModal";
import HalfErrorScreen from "../../HalfErrorScreen/HalfErrorScreen";
import TableLoader from "../../TableLoader/TableLoader";
import dayjs from "dayjs";

function AdminSentOverdraft() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [status, setStatus] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTrans, setSelectedTrans] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [userRole, setUserRole] = useState("");
  const [agentName, setAgentName] = useState("");
  const [adminName, setAdminName] = useState("");

  let filterURL = `${process.env.BASE_URL}wallet/fetch-admin-sent-overdraft?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations

  let sStatus = "status";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";
  let sAgentName = "agentName";
  let sAdminName = "adminName";
  let sUserRole = "userRole";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumBal = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumBal = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };
  const handleChangeUserRole = (e) => {
    setUserRole(e.target.value);
    sessionStorage.setItem(sUserRole, e.target.value);
  };
  const handleChangeAdminName = (e) => {
    setAdminName(e.target.value);
    sessionStorage.setItem(sAdminName, e.target.value);
  };
  const handleChangeAgentName = (e) => {
    setAgentName(e.target.value);
    sessionStorage.setItem(sAgentName, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sAgentName);
    sessionStorage.removeItem(sAdminName);
    sessionStorage.removeItem(sUserRole);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }
    let sSAgentName = sessionStorage.getItem(sAgentName);
    if (sSAgentName) {
      setAgentName(sSAgentName);
      setHasFiltered(true);
    }
    let sSAdminName = sessionStorage.getItem(sAdminName);
    if (sSAdminName) {
      setAdminName(sSAdminName);
      setHasFiltered(true);
    }
    let sSUserRole = sessionStorage.getItem(sUserRole);
    if (sSUserRole) {
      setUserRole(sSUserRole);
      setHasFiltered(true);
    }

    let sSMinAmount = sessionStorage.getItem(sMinAmount);
    if (sSMinAmount) {
      setMinAmount(sSMinAmount);
      setHasFiltered(true);
    }
    let sSMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (sSMaxAmount) {
      setMaxAmount(sSMaxAmount);
      setHasFiltered(true);
    }
    let sSStartDate = sessionStorage.getItem(sStartDate);
    if (sSStartDate) {
      setStartDate(sSStartDate);
      setHasFiltered(true);
    }
    let sSEndDate = sessionStorage.getItem(sEndDate);
    if (sSEndDate) {
      setEndDate(sSEndDate);
      setHasFiltered(true);
    }

    if (adminName !== "") {
      if (!filterURL.includes(sAdminName)) {
        filterURL = filterURL + `&adminName=${adminName}`;
      }
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (agentName !== "") {
      if (!filterURL.includes(sAgentName)) {
        filterURL = filterURL + `&agentName=${agentName}`;
      }
    }
    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (userRole) {
      if (!filterURL.includes(sUserRole)) {
        filterURL = filterURL + `&userRole=${userRole}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };
  const handleSelectTransaction = (trans) => setSelectedTrans(trans);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  useEffect(() => {
    handleSetSessionStorage();
    return () => {
      handleClearSessionStorage();
    };
  }, []);
  const url = `${process.env.BASE_URL}wallet/fetch-admin-sent-overdraft?page=${page}&limit=${rowsPerPage}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus((prev) => "");
    setAdminName((prev) => "");
    setAgentName((prev) => "");
    setUserRole((prev) => "");
    setMinAmount(0);
    setMaxAmount(0);
    setStartDate(null);
    setEndDate(null);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  // const handleCancelFilters = () => {
  //   handleClearSessionStorage();
  //   handleResetFields();
  //   setHasFiltered(false);
  //   checkExpiredAuthToken();
  //   const token = getAuthToken();
  //   return axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       return mutate(url, res.data?.data, false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleCancelFilters = useCallback(() => {
    mutate(filterURL, async (trans) => {
      handleClearSessionStorage();
      handleResetFields();
      setHasFiltered(false);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return await res.data?.data;
    });
  }, []);

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;
  return (
    <Container maxWidth="xl">
      {openDialog ? (
        <PreviewOverdraftModal
          openDialog={openDialog}
          selectedTransaction={selectedTrans}
          handleCloseDialog={handleCloseDialog}
        />
      ) : null}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <>{hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}</>
        <FormControlLabel
          sx={{ marginLeft: "auto" }}
          control={
            <Switch
              color="primary"
              checked={showFilter}
              onChange={handleChangeFilter}
              inputProps={{ "aria-label": "Show Filters" }}
            />
          }
          label="Show Filters"
        />
      </Box>
      {showFilter && (
        <OverdraftsFilter
          status={status}
          startDate={startDate}
          endDate={endDate}
          minAmount={minAmount}
          maxAmount={maxAmount}
          userRole={userRole}
          adminName={adminName}
          agentName={agentName}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeMinimumBal={handleChangeMinimumBal}
          handleChangeMaximumBal={handleChangeMaximumBal}
          handleChangeStatus={handleChangeStatus}
          handleChangeAdminName={handleChangeAdminName}
          handleChangeAgentName={handleChangeAgentName}
          handleChangeUserRole={handleChangeUserRole}
          handleFiterSubmit={handleFiterSubmit}
        />
      )}
      <OverdraftsTable
        status={status}
        isFiltering={isFiltering}
        hasFiltered={hasFiltered}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSelectTransaction={handleSelectTransaction}
        handleOpenDialog={handleOpenDialog}
        page={page}
        rowsPerPage={rowsPerPage}
        data={data?.data}
        totalCount={data?.totalCount}
      />
    </Container>
  );
}

export default AdminSentOverdraft;
