import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { safeJSONParse } from "../../utils";
const CutsOption = ({ cuts }) => {
  const parsedCuts = safeJSONParse(cuts);
  //   console.log("cccccccccccccc", parsedCuts);
  return (
    <Box>
      <Typography variant="h6" fontWeight={600} gutterBottom>
        Cuts Options
      </Typography>
      <Box>
        {parsedCuts &&
          parsedCuts?.length > 0 &&
          parsedCuts?.map((item, idx) => (
            <Typography key={`${item}${idx}`} gutterBottom>
              {item}
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

export default CutsOption;
