import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import PendingResults from "./PendingResults";
import SportbetResults from "./SportbetResults";
import ApprovedSportsResults from "./ApprovedSportsResults";

const ALL_RESULTS = "ALL_RESULTS";
const APPROVED_RESULTS = "APPROVED_RESULTS";
const PENDING_RESULTS = "PENDING_RESULTS";

const SportsResult = () => {
  const [value, setValue] = useState(ALL_RESULTS);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <div>
        <MetaDecorator title="Sports bet results - Admin Dashboard" />
        <PageHeading title="Sports bet results" />
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          sx={{ color: "green", marginTop: "5px" }}
        >
          <Tab label="All Results" value={ALL_RESULTS} />
          <Tab label="Approved Results" value={APPROVED_RESULTS} />
          <Tab label="Pending Approval" value={PENDING_RESULTS} />
        </Tabs>
        {value === ALL_RESULTS && <SportbetResults value={value} />}
        {value === APPROVED_RESULTS && <ApprovedSportsResults value={value} />}
        {value === PENDING_RESULTS && <PendingResults value={value} />}
      </Box>
    </AdminLayout>
  );
};

export default SportsResult;
