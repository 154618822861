import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import {
  boldTextCalculationStyles,
  getStakedAmountSum,
  getWinningAmountSum,
  limit,
} from "../../utils";
import TableLoader from "../TableLoader/TableLoader";
import { renderStatus } from "../../utils/statusChip";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function TicketsTable({
  isFiltering,
  data,
  page,
  totalCount,
  selectedTicket,
  handleChangePage,
  handleSelectTicket,
  handleOpenPreviewTicket,
  handleOpenBlacklistDialog,
  handleOpenHoldTicketDialog,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
}) {
  const [selectedId, setSelectedId] = useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event, ticket) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(ticket?.ticketId);
    handleSelectTicket(ticket);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "Player",
    "Ticket ID",
    "Staked Amount",
    "Winning",
    "Redemption Method",
    "Game Name",
    "Status",
    "Date Created",
    "Ticket State",
    "Actions",
  ];
  const headers = [
    "Player",
    "Ticket ID",
    "Staked Amount",
    "Wining",
    "Redemption Method",
    "Game Name",
    "Status",
    "Date Created",
    "Ticket State",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.ticketId,
        item?.totalStakedAmount,
        `${item?.resultId ? `${item?.totalWinAmount}` : `No Result Yet`}`,
        item?.winningRedemptionMethod,
        item?.Game?.name,
        item?.gameStatus,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="tickets.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.ticketId}>
                  <TableCell>
                    {row?.User?.firstname} {row?.User?.lastname}
                  </TableCell>
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.totalStakedAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {row?.resultId ? (
                      <NumberFormat
                        value={row?.totalWinAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"NGN"}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    ) : (
                      "No Result Yet"
                    )}
                  </TableCell>
                  <TableCell>{row.winningRedemptionMethod}</TableCell>
                  <TableCell>{row?.Game?.name}</TableCell>
                  <TableCell>{row.gameStatus}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row?.status)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {" "}
                      <MenuItem
                        onClick={() => handleOpenPreviewTicket()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenHoldTicketDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {selectedTicket?.status === "held"
                          ? "Hold Ticket"
                          : "Release Ticket"}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleOpenBlacklistDialog();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {selectedTicket?.status !== "blacklisted"
                          ? "Blacklist Ticket"
                          : "Unblacklist Ticket"}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          navigate(`/tickets/ticket-details/${selectedId}`)
                        }
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={2}>
                  Total
                </TableCell>

                <TableCell align="right">
                  <NumberFormat
                    value={getStakedAmountSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={getWinningAmountSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default TicketsTable;
