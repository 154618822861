import React, { Component } from "react";

const AppContext = React.createContext();

class AppProvider extends Component {
  state = {
    auth: false,
    snackbarMessage: "",
    snackbarSeverity: "info",
    openSnackbar: false,
    adminData: {},
  };

  componentDidMount() {
    console.log("");
  }

  handleOpenSnackbar = (snackbarData) => {
    this.setState({
      snackbarMessage: snackbarData.message,
      snackbarSeverity: snackbarData.severity,
      openSnackbar: true,
    });
  };

  handleSetAdminData = (data) => {
    this.setState({
      adminData: data,
      auth: true,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      snackbarMessage: "",
      snackbarSeverity: "",
      openSnackbar: false,
    });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          handleOpenSnackbar: this.handleOpenSnackbar,
          handleCloseSnackbar: this.handleCloseSnackbar,
          handleSetAdminData: this.handleSetAdminData,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppConsumer = AppContext.Consumer;

export function withAppConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <AppConsumer>
        {(value) => <Component {...props} context={value} />}
      </AppConsumer>
    );
  };
}
export { AppProvider, AppConsumer, AppContext };
