import React, { useState } from "react";
import Container from "@mui/material/Container";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  formatErrorMessage,
} from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import { useNavigate } from "react-router-dom";
import EventsTable from "../components/Events/EventsTable";
import DeactivateEventDialog from "../components/Events/DectivateEventDialog";
import ActivateEventDialog from "../components/Events/ActivateEventDialog";
import EventsFilterForm from "../components/Events/EventsFilterForm";
import ClearFilters from "../components/ClearFilters/ClearFilters";

// SessionStorage  Variable Declarations
export const sSearch = "search";
export const sTimeline = "timeline";
export const sEventOptions = "eventOptions";
export const sEventType = "eventType";

function Events() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [timeline, setTimeline] = useState("");
  const [eventOptions, setEventOptions] = useState("");
  const [eventType, setEventType] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openAddResultDialog, setOpenAddResultDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const url = `${process.env.BASE_URL}game/fetch-events?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}game/fetch-events?limit=${rowsPerPage}&page=${page}`;
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // change methods for search filters
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    sessionStorage.setItem(sSearch, e.target.value);
  };
  const handleChangeTimeline = (e) => {
    setTimeline(e.target.value);
    sessionStorage.setItem(sTimeline, e.target.value);
  };
  const handleChangeEventOptions = (e) => {
    setEventOptions(e.target.value);
    sessionStorage.setItem(sEventOptions, e.target.value);
  };
  const handleChangeEventType = (e) => {
    setEventType(e.target.value);
    sessionStorage.setItem(sEventType, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sSearch);
    sessionStorage.removeItem(sTimeline);
    sessionStorage.removeItem(sEventOptions);
    sessionStorage.removeItem(sEventType);
  };

  const handleResetFields = () => {
    setSearch("");
    setTimeline("");
    setEventOptions("");
    setEventType("");
  };

  const handleSetSessionStorage = () => {
    let sSSearch = sessionStorage.getItem(sSearch);
    if (sSSearch) {
      setSearch(sSSearch);
      setHasFiltered(true);
    }

    if (search !== "") {
      if (!filterURL.includes(sSearch)) {
        filterURL = filterURL + `&search=${search}`;
      }
    }
    if (timeline !== "") {
      if (!filterURL.includes(sTimeline)) {
        filterURL = filterURL + `&timeline=${timeline}`;
      }
    }
    if (eventOptions !== "") {
      if (!filterURL.includes(sEventOptions)) {
        filterURL = filterURL + `&eventOptions=${eventOptions}`;
      }
    }
    if (eventType) {
      if (!filterURL.includes(sEventType)) {
        filterURL = filterURL + `&eventType=${eventType}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const handleMutateURL = () => mutate(url);

  React.useEffect(() => {
    handleSetSessionStorage();
    handleMutateURL();
    return async () => {
      await handleCancelFilters();
    };
  }, []);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectCategory = (game) => setSelectedEvent(game);

  const handleOpenDeactivateDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenActivateDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateDialog = () => {
    setOpenActivateDialog(false);
  };
  const handleOpenAddResultDialog = (item) => {
    setOpenAddResultDialog(true);
    setSelectedEvent(item);
  };
  const handleCloseAddResultDialog = () => {
    setOpenAddResultDialog(false);
    setSelectedEvent(null);
  };
  const handleSubmitDeactivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      eventId: selectedEvent?.eventId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/deactivate-event`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Event have Been Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);
        const errMsg = formatErrorMessage(err);

        handleOpenSnackbar({
          message: errMsg,
          severity: "error",
        });
      });
  };

  const handleSubmitActivate = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      eventId: selectedEvent?.eventId,
    };
    setIsSubmitting(true);
    axios
      .post(`${process.env.BASE_URL}game/activate-event`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Event Have Been Successfully Activated",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Event Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleCancelFilters = () => {
    handleResetFields();
    handleClearSessionStorage();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Active Events Table - Admin Dashboard SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Active Events" />

        {openDeactivateDialog ? (
          <DeactivateEventDialog
            openDialog={openDeactivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateDialog}
            handleSubmit={handleSubmitDeactivate}
          />
        ) : null}
        {openActivateDialog ? (
          <ActivateEventDialog
            openDialog={openActivateDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseActivateDialog}
            handleSubmit={handleSubmitActivate}
          />
        ) : null}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={() => navigate("/add-event")}>
            Add new Event
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch checked={showFilter} onChange={handleChangeFilter} />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <EventsFilterForm
            search={search}
            timeline={timeline}
            eventOptions={eventOptions}
            eventType={eventType}
            handleChangeSearch={handleChangeSearch}
            handleChangeTimeline={handleChangeTimeline}
            handleChangeEventOptions={handleChangeEventOptions}
            handleChangeEventType={handleChangeEventType}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        <EventsTable
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedEvent={selectedEvent}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSelectCategory={handleSelectCategory}
          handleOpenActivateDialog={handleOpenActivateDialog}
          handleOpenDeactivateDialog={handleOpenDeactivateDialog}
          handleOpenAddResultDialog={handleOpenAddResultDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default Events;
