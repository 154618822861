import React from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import dayjs from "dayjs";
import useSWRImmutable from "swr/immutable";
import useSWR, { mutate } from "swr";
import { AppContext } from "../context";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import WithdrawalRequestsTable from "../components/WithdrawalRequestsComponents/WithdrawalRequestsTable";
import WithdrawalRequestsFilter from "../components/WithdrawalRequestsComponents/WithdrawalRequestsFilter";
import WithdrawalRequestsAnalytics from "../components/WithdrawalRequestsComponents/WithdrawalRequestsAnalytics";
import PreviewWithdrawalRequestModal from "../components/WithdrawalRequestsComponents/PreviewWithdrawalRequestModal";
import ApproveWithdrawalDialog from "../components/WithdrawalRequestsComponents/ApproveWithdrawalDialog";

function WithdrawalRequests() {
  const [status, setStatus] = React.useState("");
  const [ticketID, setTicketID] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [selectedRequest, setSelectedRequest] = React.useState({});
  const [openPreviewTicket, setOpenPreviewTicket] = React.useState(false);
  const [openApproveRequest, setOpenApproveRequest] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [minAmount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  let url = `${process.env.BASE_URL}transactions/fetch-withdrawal-requests?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}transactions/fetch-withdrawal-requests?limit=${rowsPerPage}&page=${page}`;

  const handleCloseApproveRequestDialog = () => setOpenApproveRequest(false);
  const handleOpenApproveRequestDialog = () => setOpenApproveRequest(true);
  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sTicketName = "ticketName";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeTicketID = (e) => {
    setTicketID(e.target.value);
    sessionStorage.setItem(sTicketName, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumAmount = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumAmount = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sTicketName);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssTicketName = sessionStorage.getItem(sTicketName);
    if (ssTicketName) {
      setTicketID(ssTicketName);
      setHasFiltered(true);
    }

    let ssStartDate = sessionStorage.getItem(sStartDate);
    if (ssStartDate) {
      setStartDate(ssStartDate);
      setHasFiltered(true);
    }
    let ssEndDate = sessionStorage.getItem(sEndDate);
    if (ssEndDate) {
      setEndDate(ssEndDate);
      setHasFiltered(true);
    }
    let ssMinAmount = sessionStorage.getItem(sMinAmount);
    if (ssMinAmount) {
      setMinAmount(ssMinAmount);
      setHasFiltered(true);
    }
    let ssMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (ssMaxAmount) {
      setMaxAmount(ssMaxAmount);
      setHasFiltered(true);
    }
    // Updating URL

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (ticketID) {
      if (!filterURL.includes(sTicketName)) {
        filterURL = filterURL + `&search=${ticketID}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleSelectTicket = (ticket) => setSelectedRequest(ticket);
  const handleClosePreviewRequest = () => setOpenPreviewTicket(false);
  const handleOpenPreviewRequest = () => setOpenPreviewTicket(true);

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus("");
    setTicketID("");
    setStartDate(null);
    setEndDate(null);
    setMinAmount(0);
    setMaxAmount(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data);
        return res?.data?.data;
      });
  };

  const handleSubmitApproveRequest = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      requestId: selectedRequest?.requestId,
    };

    axios
      .post(`${process.env.BASE_URL}admin/approve-withdrawal-request`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Withdrawal Request Have Been Successfully Approved",
          severity: "success",
        });
        mutate(url);
        return handleCloseApproveRequestDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Player's Withdrawal Request Could Not Be Approved",
          severity: "error",
        });
      });
  };

  const handleFilterSubmit = () =>
    mutate(url, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const handleCancelFilters = () =>
    mutate(url, async () => {
      try {
        setIsFiltering(true);
        handleResetFields();
        setHasFiltered(false);
        checkExpiredAuthToken();
        const token = getAuthToken();
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIsFiltering(false);
        return res.data?.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const { data, error } = useSWRImmutable(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Withdrawal Requests - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Withdrawal Requests" />
        {openPreviewTicket ? (
          <PreviewWithdrawalRequestModal
            openDialog={openPreviewTicket}
            selectedRequest={selectedRequest}
            handleCloseDialog={handleClosePreviewRequest}
          />
        ) : null}

        {openApproveRequest ? (
          <ApproveWithdrawalDialog
            openDialog={openApproveRequest}
            isSubmitting={isSubmitting}
            selectedRequest={selectedRequest}
            handleCloseDialog={handleCloseApproveRequestDialog}
            handleSubmit={handleSubmitApproveRequest}
          />
        ) : null}

        {/* <WithdrawalRequestsAnalytics /> */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <WithdrawalRequestsFilter
            status={status}
            ticketID={ticketID}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumAmount={handleChangeMinimumAmount}
            handleChangeMaximumAmount={handleChangeMaximumAmount}
            handleChangeStatus={handleChangeStatus}
            handleChangeTicketID={handleChangeTicketID}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}

        <WithdrawalRequestsTable
          data={data?.data}
          hasFiltered={hasFiltered}
          selectedRequest={selectedRequest}
          isFiltering={isFiltering}
          handleChangePage={handleChangePage}
          handleSelectTicket={handleSelectTicket}
          handleOpenPreviewRequest={handleOpenPreviewRequest}
          handleOpenApproveRequestDialog={handleOpenApproveRequestDialog}
          page={page}
          totalCount={data?.totalCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </AdminLayout>
  );
}

export default WithdrawalRequests;
