import { useState, useContext, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import Files from "react-files";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formPaperStyle,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  safeJSONParse,
} from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import { useNavigate, useParams } from "react-router-dom";
import FormLoader from "../components/FormLoader/FormLoader";
import { eventTypesList } from "./AddProduct";

function UpdateProduct() {
  const [productImage, setProductImage] = useState("");
  const [gameName, setGameName] = useState("");
  const [searchedGames, setSearchedGames] = useState([]);
  const [isSearchingGame, setIsSearchingGame] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [selectedGame, setSelectedGame] = useState(false);
  const [loadingBetOption, setLoadingBetOption] = useState(false);
  const [betOption, setBetOption] = useState([]);
  const [eventError, setEventError] = useState("");
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [productData, setProductData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleOpenSnackbar } = useContext(AppContext);
  const btnRef = useRef();

  const { productId } = useParams();
  const navigate = useNavigate();
  const productUrl = `${process.env.BASE_URL}product/${productId}`;
  const gameUrl = `${process.env.BASE_URL}game/fetch-games?limit=25&page=1&search=${gameName}`;

  const url = `${
    process.env.BASE_URL
  }categories/fetch-categories?page=${1}&limit=${1000}&status=true`;

  const handleChangeImage = (files) => {
    setProductImage((curr) => files[0]);
  };
  const handleErrorImage = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const handleSearchGames = async () => {
    try {
      setIsSearchingGame(true);
      setBetOption([]);
      const token = getAuthToken();
      const res = await axios.get(gameUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSearchedGames(res?.data?.data?.data);
    } catch (err) {
      if (err?.response?.data?.responsemessage?.msg) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage?.msg,
          severity: "error",
        });
      }
      if (err?.response?.data?.responsemessage) {
        return handleOpenSnackbar({
          message: err?.response?.data?.responsemessage,
          severity: "error",
        });
      }
      return handleOpenSnackbar({
        message: err?.message,
        severity: "error",
      });
    } finally {
      setIsSearchingGame(false);
      setHasSearchedGames(true);
    }
  };
  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOptions = async () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    if (selectedGame?.Lottery?.category === "meta4") {
      return await getBetOption("meta4-bet-options");
    } else if (selectedGame?.Lottery?.category === "cash-out") {
      return await getBetOption("cash-out-bet-options");
    } else if (selectedGame?.Lottery?.category === "eazy-win") {
      return await getBetOption("eazy-win-bet-options");
    } else if (selectedGame?.Lottery?.category === "perfect-balls") {
      return await getBetOption("perfect-balls-bet-options");
    } else if (selectedGame?.Lottery?.category === "lotto") {
      return await getBetOption("lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === "metro") {
      return await getBetOption("metro-bet-options");
    } else if (selectedGame?.Lottery?.category === GHANA_GAME) {
      return await getBetOption(GHANA_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME) {
      return await getBetOption(WESTERN_INDOOR_GAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === GHANA_GAME_V2) {
      return await getBetOption(GHANA_GAME_V2_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === WESTERN_INDOOR_GAME_V2) {
      return await getBetOption(WESTERN_INDOOR_GAME_V2_BETOPTIONS);
    }
  };
  let initialValues = {
    title: "",
    customProductId: "",
    imageUrl: "",
    eventCount: 1,
    minimumSelectionCount: 1,
    maximumSelectionCount: 4,
    cashEquivalence: 1,
    minStakedAmount: 10,
    maxStakedAmount: 100000,
    categoryId: "",
    description: "",
    eventTypes: [],
    cut1: "",
    cut2: "",
    cut3: "",
    cut4: "",
    cut5: "",
    cut6: "",
  };
  const fetchSingleProduct = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(productUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setProductData(res?.data?.data?.data);
      setLoadingEvent(false);
    } catch (error) {
      setLoadingEvent(false);
      setEventError(error);
      console.log("error fetching lottery data", error);
    }
  };

  useEffect(() => {
    fetchSingleProduct();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb", values);
      let newValues = {
        title: values.title,
        minimumSelectionCount: values.minimumSelectionCount,
        maximumSelectionCount: values.maximumSelectionCount,
        cashEquivalence: parseInt(values.cashEquivalence),
        minStakedAmount: parseInt(values.minStakedAmount),
        maxStakedAmount: parseInt(values.maxStakedAmount),
        categoryId: values.categoryId,
        description: values.description,
        eventTypes: JSON.stringify(values.eventTypes),
      };

      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      if (productImage) {
        const foreground = await handleSubmitImage(
          productImage,
          "Sorry, an error occured while uploading the category icon image"
        );
        newValues.imageUrl = foreground;
      }

      let cuts = [
        values.cut1,
        values.cut2,
        values.cut2,
        values.cut4,
        values.cut5,
        values.cut6,
      ];
      newValues.cuts = JSON.stringify(cuts);

      axios
        .put(`${process.env.BASE_URL}product/${productId}`, newValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Product Updated Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            navigate("/products");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Product Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      customProductId: yup.string().required().label("Product ID"),
      categoryId: yup.string().required().label("Category"),
      description: yup.string().required().label("Description"),
      minStakedAmount: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Min Staked Amount"),
      maxStakedAmount: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Max Staked Amount"),
      cashEquivalence: yup
        .number()
        .positive()
        .min(1)
        .required()
        .label("Cash Equivalence"),
    }),
  });
  // console.log("sssssssssssssssssss", productData);
  if (productData?.title) {
    initialValues.title = productData?.title;
    initialValues.customProductId = productData?.customProductId;
    initialValues.imageUrl = productData?.imageUrl;
    initialValues.minimumSelectionCount = productData?.minimumSelectionCount;
    initialValues.maximumSelectionCount = productData?.maximumSelectionCount;
    initialValues.cashEquivalence = productData?.cashEquivalence;
    initialValues.minStakedAmount = productData?.minStakedAmount;
    initialValues.maxStakedAmount = productData?.maxStakedAmount;
    initialValues.categoryId = productData?.ProductCategory?.categoryId;
    initialValues.description = productData?.description;
    let parsed = safeJSONParse(productData?.cuts);
    let parsedEventTypes = safeJSONParse(productData?.eventTypes);
    initialValues.cut1 = parsed && parsed[0] ? parsed[0] : "";
    initialValues.cut2 = parsed && parsed[1] ? parsed[1] : "";
    initialValues.cut3 = parsed && parsed[2] ? parsed[2] : "";
    initialValues.cut4 = parsed && parsed[3] ? parsed[3] : "";
    initialValues.cut5 = parsed && parsed[4] ? parsed[4] : "";
    initialValues.cut6 = parsed && parsed[5] ? parsed[5] : "";
    initialValues.eventTypes = parsedEventTypes;
  }
  const { data, error } = useSWR(productUrl, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update Product - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Product" />
        <div>
          <button
            style={{ display: "none" }}
            onClick={() => getOptions()}
            ref={btnRef}
          >
            Get Bet Options
          </button>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Paper sx={formPaperStyle}>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>
                    Select Display Image for Product
                  </FormLabel>
                  <br />
                  <Files
                    className="dropzone"
                    onChange={handleChangeImage}
                    onError={handleErrorImage}
                    accepts={["image/*"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Typography gutterBottom align="center">
                      Drop file here or click to upload image of product
                    </Typography>
                    {productImage ? (
                      <Box>
                        <img
                          src={URL.createObjectURL(productImage)}
                          className="imagePreview"
                          alt="product image"
                        />
                      </Box>
                    ) : !productImage && productData?.imageUrl ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "7px",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Prev Image</Typography> &nbsp;{" "}
                        <img
                          src={productData?.imageUrl}
                          className="imagePreview"
                          alt="product image"
                        />
                      </Box>
                    ) : null}
                  </Files>
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formik.touched.categoryId && formik.errors.categoryId}
                >
                  <FormLabel variant="outlined">Select Category</FormLabel>
                  <Select
                    name="categoryId"
                    value={formik.values.categoryId}
                    onChange={formik.handleChange}
                  >
                    {data?.map((item, idx) => (
                      <MenuItem
                        key={`122${idx}${item?.categoryId}`}
                        value={item?.categoryId}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.categoryId && formik.errors.categoryId}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Name of Product"
                        error={formik.touched.title && formik.errors.title}
                        name="title"
                        variant="outlined"
                        value={formik.values.title}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={formik.touched.title && formik.errors.title}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="ID of Product"
                        error={
                          formik.touched.customProductId &&
                          formik.errors.customProductId
                        }
                        name="customProductId"
                        variant="outlined"
                        value={formik.values.customProductId}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.customProductId &&
                          formik.errors.customProductId
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Minimum Staked Amount"
                        error={
                          formik.touched.minStakedAmount &&
                          formik.errors.minStakedAmount
                        }
                        name="minStakedAmount"
                        type="number"
                        variant="outlined"
                        value={formik.values.minStakedAmount}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.minStakedAmount &&
                          formik.errors.minStakedAmount
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Maximum Staked Amount"
                        error={
                          formik.touched.maxStakedAmount &&
                          formik.errors.maxStakedAmount
                        }
                        type="number"
                        name="maxStakedAmount"
                        variant="outlined"
                        value={formik.values.maxStakedAmount}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.maxStakedAmount &&
                          formik.errors.maxStakedAmount
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Cash Equivalence of Product"
                        error={
                          formik.touched.cashEquivalence &&
                          formik.errors.cashEquivalence
                        }
                        name="cashEquivalence"
                        type="number"
                        variant="outlined"
                        value={formik.values.cashEquivalence}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.cashEquivalence &&
                          formik.errors.cashEquivalence
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Minimum Selection Count"
                        error={
                          formik.touched.minimumSelectionCount &&
                          formik.errors.minimumSelectionCount
                        }
                        name="minimumSelectionCount"
                        type="number"
                        variant="outlined"
                        value={formik.values.minimumSelectionCount}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.minimumSelectionCount &&
                          formik.errors.minimumSelectionCount
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Maximum Selection Count"
                        error={
                          formik.touched.maximumSelectionCount &&
                          formik.errors.maximumSelectionCount
                        }
                        name="maximumSelectionCount"
                        type="number"
                        variant="outlined"
                        value={formik.values.maximumSelectionCount}
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.maximumSelectionCount &&
                          formik.errors.maximumSelectionCount
                        }
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Description of Product"
                    error={
                      formik.touched.description && formik.errors.description
                    }
                    name="description"
                    variant="outlined"
                    multiline
                    value={formik.values.description}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Breakdown of Prizes with brief explanation"
                    error={formik.touched.breakdown && formik.errors.breakdown}
                    name="breakdown"
                    variant="outlined"
                    multiline
                    value={formik.values.breakdown}
                    fullWidth
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    helperText={
                      formik.touched.breakdown && formik.errors.breakdown
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  error={formik.touched.eventTypes && formik.errors.eventTypes}
                  component="fieldset"
                  sx={{ my: 1 }}
                  variant="standard"
                >
                  <FormLabel component="legend">Pick Event Types</FormLabel>
                  <FormGroup row>
                    {eventTypesList?.map((item) => (
                      <FormControlLabel
                        key={item?.value}
                        name="eventTypes"
                        value={item?.value}
                        control={
                          <Checkbox
                            checked={formik.values.eventTypes?.includes(
                              item.value
                            )}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="eventTypes"
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </FormGroup>
                  <FormHelperText>{formik.errors.eventTypes}</FormHelperText>
                </FormControl>
              </Box>
            </Paper>
            <br />
            <Paper sx={formPaperStyle}>
              <Box my={2}>
                <Typography variant="h6" gutterBottom fontWeight={500}>
                  Cuts
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 1"
                          name="cut1"
                          variant="outlined"
                          value={formik.values.cut1}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 2"
                          name="cut2"
                          variant="outlined"
                          value={formik.values.cut2}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 3"
                          name="cut3"
                          variant="outlined"
                          value={formik.values.cut3}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 4"
                          name="cut4"
                          variant="outlined"
                          value={formik.values.cut4}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 5"
                          name="cut5"
                          variant="outlined"
                          value={formik.values.cut5}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Cut 6"
                          name="cut6"
                          variant="outlined"
                          value={formik.values.cut6}
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <br />

            <Paper sx={formPaperStyle}>
              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Updating Product" : " Update Product"}
              </Button>
            </Paper>
          </form>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default UpdateProduct;
