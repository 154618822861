import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppContext } from "../context";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import Container from "@mui/material/Container";
import { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import PageEditor from "../components/AddStaticPageComponents/PageEditor";

const ABOUT_US = "about-us";
const OUR_PROGRAMS = "our-programs";
const OTHER = "other";
function AddStaticPage() {
  const [bannerContent, setBannerContent] = React.useState("");
  const { handleOpenSnackbar } = React.useContext(AppContext);
  let initialValues = {
    title: "",
    description: "",
    content: "",
    purpose: OTHER,
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      try {
        let slug = "";
        if (values.purpose === OTHER) {
          let slugArray = values.title?.split(" ");
          slugArray.map((item, index) => {
            if (index === slugArray?.length - 1) {
              slug += `${item}`;
              return;
            }
            slug += `${item}-`;
          });
        }
        if (values.purpose === OUR_PROGRAMS) {
          slug = OUR_PROGRAMS;
        }
        if (values.purpose === ABOUT_US) {
          slug = ABOUT_US;
        }

        let payload = {};
        payload.title = values.title;
        payload.description = values.description;
        payload.content = values.content;
        payload.slug = slug;
        checkExpiredAuthToken();
        const token = getAuthToken();
        const res = await axios.post(
          `${process.env.BASE_URL}content/create-content`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await mutate(`${process.env.BASE_URL}content/fetch-contents`);
        helpers.setSubmitting(false);
        formik.resetForm();
        helpers.resetForm({ values: initialValues });
        setBannerContent("");
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Content Page Created Successfully",
          severity: "success",
        });
      } catch (err) {
        helpers.setSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Page Could Not Be Created",
          severity: "error",
        });
      }
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      description: yup
        .string()
        .required()
        .label("Content Description")
        .max(280),
      content: yup.string().required().label("Content"),
    }),
  });
  return (
    <AdminLayout>
      <MetaDecorator title={`Add New Page- Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Add New Page" />

        <div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box my={1}>
              <FormControl>
                <FormLabel>
                  Only select one of this if you are adding any of these pages
                  (Optional)
                </FormLabel>
                <RadioGroup
                  row
                  name="purpose"
                  value={formik.values.purpose}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value={ABOUT_US}
                    control={<Radio />}
                    label="About Us Page"
                  />
                  <FormControlLabel
                    value={OUR_PROGRAMS}
                    control={<Radio />}
                    label="Our Program Page"
                  />
                  <FormControlLabel
                    value={OTHER}
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Enter Title"
                  error={formik.touched.title && formik.errors.title}
                  name="title"
                  variant="outlined"
                  fullWidth
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </FormControl>
            </Box>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Description"
                  error={
                    formik.touched.description && formik.errors.description
                  }
                  name="description"
                  multiline
                  variant="outlined"
                  value={formik.values.description}
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FormControl>
            </Box>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <br />
                <PageEditor
                  bannerContent={bannerContent}
                  setBannerContent={setBannerContent}
                  placeHolder={"Enter the content for the page"}
                  formik={formik}
                />
                <FormHelperText error>
                  {formik.touched.content && formik.errors?.content}
                </FormHelperText>
              </FormControl>
            </Box>
            <br />
            <Button
              startIcon={
                formik.isSubmitting && (
                  <CircularProgress severity="primary" size={"1rem"} />
                )
              }
              type="submit"
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting ? "Adding Page" : "Add Page"}
            </Button>
          </form>
        </div>
        {/* </Container> */}
      </Container>
    </AdminLayout>
  );
}

export default AddStaticPage;
