import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  userRoles,
  labelStyles,
} from "../../utils";
import { AppContext } from "../../context";

export default function AddRoleDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [role, setRole] = React.useState("");
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {};
    values.role = role;
    values.userId = selectedUser;

    axios
      .put(`${process.env.BASE_URL}user/add-role`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Role Added Successfully",
          severity: "success",
        });
        return handleCloseDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Role Could Not Be Added",
          severity: "error",
        });
      });
  };

  const handleChange = (value) => setRole(value);
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle>Add Role To User</DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Select Role
              </InputLabel>
              <Select
                value={role}
                variant="outlined"
                onChange={(e) => handleChange(e.target.value)}
              >
                {userRoles.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            variant="contained"
            color="success"
          >
            Add Role
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
