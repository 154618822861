import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  currencyFormater,
  formatBonusType,
  singleDetailsBoxStyle,
} from "../../utils";

function BundleInformation({ data }) {
  return (
    <Box>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Assigned Bonus Information
      </Typography>
      {data?.AssignedBonus?.bonusId ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Bonus Name:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.AssignedBonus?.title}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Bonus ID:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.AssignedBonus?.bonusId}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Game Type:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.AssignedBonus?.gameType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Bet Type:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.AssignedBonus?.betType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Bonus Type:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && formatBonusType(data?.AssignedBonus?.type)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Prize:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                NGN{data && currencyFormater(data?.AssignedBonus?.prize)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">
                Available Quantity:
              </Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.AssignedBonus?.quantity}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box my={3} p={3}>
          <Typography align="center" gutterBottom>
            No Bonus Assigned
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default BundleInformation;
