import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppContext } from "../context";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import PageEditor from "../components/AddStaticPageComponents/PageEditor";
import { useParams } from "react-router-dom";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

function EditStaticPage() {
  const [bannerContent, setBannerContent] = useState("");
  const { handleOpenSnackbar } = useContext(AppContext);
  let initialValues = {
    title: "",
    description: "",
    content: "",
  };
  const { contentId } = useParams();

  const url = `${process.env.BASE_URL}content/fetch-content/${contentId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBannerContent(res?.data?.data?.data?.content);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (data) {
    initialValues.title = data?.title;
    (initialValues.description = data?.description),
      (initialValues.content = data?.content);
  }

  //   console.log("dddddddddddddddd", data);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      // values.slug = "slug";
      checkExpiredAuthToken();
      const token = getAuthToken();
      return axios
        .put(
          `${process.env.BASE_URL}content/update-content/${contentId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          mutate(url);
          helpers.setSubmitting(false);
          helpers.resetForm();
          setBannerContent("");
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Content Page Updated Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          helpers.setSubmitting(false);

          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Page Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      description: yup.string().required().label("Content Description"),
      content: yup.string().required().label("Content"),
    }),
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title={`Update Content Page ${data?.title} - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Update Content Page" />
        <Container maxWidth="xl">
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Enter Title"
                    error={formik.touched.title && formik.errors.title}
                    value={formik.values.title}
                    name="title"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Description"
                    error={
                      formik.touched.description && formik.errors.description
                    }
                    value={formik.values.description}
                    name="description"
                    multiline
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <PageEditor
                    bannerContent={bannerContent}
                    setBannerContent={setBannerContent}
                    placeHolder={"Enter the content for the page"}
                    formik={formik}
                  />
                  <FormHelperText error>
                    {formik.touched.content && formik.errors?.content}
                  </FormHelperText>
                </FormControl>
              </Box>
              <br />
              <Button
                startIcon={
                  formik.isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {formik.isSubmitting ? "Updating Page" : "Update Page"}
              </Button>
            </form>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditStaticPage;
