import React, { useState, useEffect, useCallback } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";

import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";

import dayjs from "dayjs";
import PayoutsTable from "../components/PayoutComponents/PayoutsTable";
import PayoutsFilter from "../components/PayoutComponents/PayoutsFilter";
import PreviewPayoutModal from "../components/PayoutComponents/PreviewPayoutModal";
// import ReverseFundDialog from "../components/TransactionsComponents/ReverseFundDialog";

function Payouts() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [status, setStatus] = useState("");
  const [transactionName, setTransactionName] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [openReverseFundDialog, setOpenReverseFundDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTrans, setSelectedTrans] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  let filterURL = `${process.env.BASE_URL}payout/fetch-payouts?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations

  let sStatus = "status";
  let sTransactionName = "transactionName";
  let sTransactionType = "role";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeTicketName = (e) => {
    setTransactionName(e.target.value);
    sessionStorage.setItem(sTransactionName, e.target.value);
  };

  const handleChangeTransactionType = (e) => {
    setTransactionType(e.target.value);
    sessionStorage.setItem(sTransactionType, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumBal = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumBal = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sTransactionName);
    sessionStorage.removeItem(sTransactionType);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }
    let sSTransactionName = sessionStorage.getItem(sTransactionName);
    if (sSTransactionName) {
      setTransactionName(sSTransactionName);
      setHasFiltered(true);
    }
    let sSTransactionType = sessionStorage.getItem(sTransactionType);
    if (sSTransactionType) {
      setTransactionType(sSTransactionType);
      setHasFiltered(true);
    }
    let sSStartDate = sessionStorage.getItem(sStartDate);
    if (sSStartDate) {
      setStartDate(sSStartDate);
      setHasFiltered(true);
    }
    let sSEndDate = sessionStorage.getItem(sEndDate);
    if (sSEndDate) {
      setEndDate(sSEndDate);
      setHasFiltered(true);
    }
    let sSMinAmount = sessionStorage.getItem(sMinAmount);
    if (sSMinAmount) {
      setMinAmount(sSMinAmount);
      setHasFiltered(true);
    }
    let sSMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (sSMaxAmount) {
      setMaxAmount(sSMaxAmount);
      setHasFiltered(true);
    }

    if (transactionName !== "") {
      if (!filterURL.includes(sTransactionName)) {
        filterURL = filterURL + `&searchId=${transactionName}`;
      }
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (transactionType !== "") {
      if (!filterURL.includes(sTransactionType)) {
        filterURL = filterURL + `&role=${transactionType}`;
      }
    }
    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };
  const handleSelectTransaction = (trans) => setSelectedTrans(trans);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleOpenReverseFundsDialog = () => setOpenReverseFundDialog(true);
  const handleCloseReverseFundDialog = () => setOpenReverseFundDialog(false);
  const handleChangeFilter = (event) => setShowFilter(event.target.checked);
  const renderStatus = (gameStatus) => {
    if (typeof gameStatus === "string") {
      if (gameStatus === "success") {
        return <Chip color="success" label={gameStatus} size="small" />;
      }
      if (gameStatus === "reversed") {
        return <Chip color="warning" label={gameStatus} size="small" />;
      }

      return <Chip color="error" label={gameStatus} size="small" />;
    }
    if (typeof gameStatus === "boolean") {
      if (gameStatus) {
        return <Chip color="success" label={"Paid"} size="small" />;
      }

      return <Chip color="error" label="Not Paid" size="small" />;
    }
  };
  useEffect(() => {
    handleSetSessionStorage();
    return () => {
      handleClearSessionStorage();
    };
  }, []);
  const url = `${process.env.BASE_URL}transactions/fetch-transactions?page=${page}&limit=${rowsPerPage}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus((prev) => "");
    setTransactionName((prev) => "");
    setTransactionType((prev) => "");
    setStartDate(null);
    setEndDate(null);
    setMinAmount(0);
    setMaxAmount(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const handleCancelFilters = useCallback(() => {
    mutate(filterURL, async (trans) => {
      handleClearSessionStorage();
      handleResetFields();
      setHasFiltered(false);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return await res.data?.data;
    });
  }, []);

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, Payouts data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  // console.log("dddddddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator title="Payouts Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Payouts" />

        {openDialog ? (
          <PreviewPayoutModal
            openDialog={openDialog}
            renderStatus={renderStatus}
            selectedPayout={selectedTrans}
            handleCloseDialog={handleCloseDialog}
          />
        ) : null}

        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box> */}
        {showFilter && (
          <PayoutsFilter
            status={status}
            transactionName={transactionName}
            transactionType={transactionType}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumBal={handleChangeMinimumBal}
            handleChangeMaximumBal={handleChangeMaximumBal}
            handleChangeStatus={handleChangeStatus}
            handleChangeTicketName={handleChangeTicketName}
            handleChangeTransactionType={handleChangeTransactionType}
            handleFiterSubmit={handleFiterSubmit}
          />
        )}
        <PayoutsTable
          status={status}
          isFiltering={isFiltering}
          hasFiltered={hasFiltered}
          renderStatus={renderStatus}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSelectTransaction={handleSelectTransaction}
          handleOpenReverseFundsDialog={handleOpenReverseFundsDialog}
          transactionName={transactionName}
          handleOpenDialog={handleOpenDialog}
          page={page}
          rowsPerPage={rowsPerPage}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default Payouts;
