import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  boxStyle5,
  currencyFormater,
  formatErrorMessage,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";
import GameInformation from "../components/LottoProductDetails/GameInformation";
import ProductUsersTable from "../components/LottoProductDetails/ProductUsersTable";

function LottoProductDetailsPage() {
  const { productId } = useParams();

  const url = `${process.env.BASE_URL}lotto-product/${productId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error) {
    const errorMsg = formatErrorMessage(error);
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={errorMsg}
      />
    );
  }
  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`${data && data?.title} details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Lotto Product details" />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box my={1}>
              {data?.imageUrl ? (
                <img
                  src={data?.imageUrl}
                  width="100"
                  height="100"
                  alt={data?.title}
                />
              ) : (
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  No Display Image Available for this product
                </Typography>
              )}
            </Box>

            <Box py={2}>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Name:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.title}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Category:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.category}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Date Created:</Typography>
                <Typography align="left">
                  {dayjs(data?.createdAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Last Updated:</Typography>
                <Typography align="left">
                  {dayjs(data?.updatedAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">
                  Fixed Staked Amount:
                </Typography>
                <Typography align="left">
                  &#8358; {data && currencyFormater(data?.fixedStakedAmount)}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">
                  Cash Equivalence:
                </Typography>
                <Typography align="left">
                  &#8358; {data && currencyFormater(data?.cashEquivalence)}
                </Typography>
              </Box>

              <Box mb={1} pb={1} pr={2} sx={boxStyle5}>
                <Typography color="text.secondary">Status:</Typography>
                {data?.status ? (
                  <Chip color="success" label="Active" size="small" />
                ) : (
                  <Chip label="Not Active" color="error" size="small" />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GameInformation game={data?.Game} betType={data?.betType} />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Product Users/Tickets
          </Typography>
          <ProductUsersTable />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default LottoProductDetailsPage;
