import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import PreviewGameInstanceModal from "../GameInstanceComponents/PreviewGameInstanceModal";

function TeamTable({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState("");
  const [selectedGame, setSelectedGame] = React.useState("");
  const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    setSelectedGame(game);
  };
  const handleOpenPreviewGameDialog = () => setOpenPreviewDialog(true);
  const handleClosePreviewGameDialog = () => setOpenPreviewDialog(false);
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = ["Name", "Logo", "ID", "Alias", "Date Created", "Status"];
  return (
    <Box sx={{ padding: "10px 5px" }}>
      {openPreviewDialog && (
        <PreviewGameInstanceModal
          handleCloseDialog={handleClosePreviewGameDialog}
          openDialog={openPreviewDialog}
          selectedGame={selectedGame}
        />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} size="small">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.teamId}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>
                    {row?.logo ? (
                      <>
                        <img
                          src={`${row.logo}`}
                          alt="flag image"
                          width="40px"
                          height={"40px"}
                          style={{ objectFit: "contain", overflow: "hidden" }}
                        />{" "}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>

                  <TableCell>{row?.customTeamId}</TableCell>
                  <TableCell>{row?.alias}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>

                  {/* <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {!selectedCategory?.status ? (
                        <MenuItem
                          onClick={() => handleOpenActivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Team
                        </MenuItem>
                      ) : null}

                      {selectedCategory?.status ? (
                        <MenuItem
                          onClick={() => handleOpenDeactivateDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate Team
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem
                        onClick={() => moveToEditGamePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit Details
                      </MenuItem>
                    </Menu>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default TeamTable;
