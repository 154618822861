import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik, Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formPaperStyle,
  getAuthToken,
  labelStyles,
  labelStylesThree,
} from "../utils";
import { countryListAllIsoData } from "../utils/countries";
import { useNavigate } from "react-router-dom";

function AddLeague() {
  const [selectedSlug, setSelectedSlug] = React.useState("");

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      leagueName: "",
      country: "",
      customLeagueId: "",
      flagImage: "",
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const formData = new FormData();

      formData.append("leagueName", values.leagueName);
      formData.append("country", values.country);
      formData.append("customLeagueId", values.customLeagueId);
      formData.append("flagImage", values.imageUrl);

      axios
        .post(`${process.env.BASE_URL}game/create-league`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          handleOpenSnackbar({
            message: "League Created Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, League Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      leagueName: yup.string().required().label("League Name"),
      country: yup.string().required().label("Country"),
      customLeagueId: yup.string().required().label("League ID"),
    }),
  });

  return (
    <AdminLayout>
      <MetaDecorator title="Add New League - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Add New League" />

        <Paper sx={formPaperStyle}>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>Select Image of Flag</FormLabel>
                  <br />
                  <input
                    type="file"
                    name="imageUrl"
                    accept="image/*"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue("imageUrl", e.target.files[0])
                    }
                  />
                </FormControl>
              </Box>

              <Box my={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formik.touched.category && formik.errors.category}
                >
                  <InputLabel variant="outlined" sx={labelStylesThree}>
                    Select Country
                  </InputLabel>
                  {/* <br />
                  <br /> */}
                  <Select
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  >
                    {countryListAllIsoData?.map((country, idx) => (
                      <MenuItem
                        key={`122${idx}${country?.name}`}
                        value={country?.name}
                      >
                        {country?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.country && formik.errors.country}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Name of League"
                    error={
                      formik.touched.leagueName && formik.errors.leagueName
                    }
                    name="leagueName"
                    variant="outlined"
                    value={formik.values.leagueName}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.leagueName && formik.errors.leagueName
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Id of League"
                    error={
                      formik.touched.customLeagueId &&
                      formik.errors.customLeagueId
                    }
                    name="customLeagueId"
                    variant="outlined"
                    value={formik.values.customLeagueId}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.customLeagueId &&
                      formik.errors.customLeagueId
                    }
                  />
                </FormControl>
              </Box>

              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Adding League" : " Add League"}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </AdminLayout>
  );
}

export default AddLeague;
