import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Files from "react-files";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik, Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesThree,
} from "../utils";
import "../styles/AddProductCategory.css";
import { useNavigate } from "react-router-dom";

function AddProductCategory() {
  const [categoryIcon, setCategoryIcon] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleOpenSnackbar } = useContext(AppContext);
  const navigate = useNavigate();
  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      slug: "",
      description: "",
      imageUrl: "",
      icon: "",
      isFeatured: false,
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(false);
      setIsSubmitting((prevState) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      let foreground = "",
        background = "";

      if (categoryImage) {
        background = await handleSubmitImage(
          categoryImage,
          "Sorry, an error occured while uploading category image"
        );
      }

      if (categoryIcon)
        foreground = await handleSubmitImage(
          categoryIcon,
          "Sorry, an error occured while uploading the category icon image"
        );
      values.imageUrl = background;
      values.icon = foreground;

      axios
        .post(`${process.env.BASE_URL}categories/new`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            return setTimeout(() => {
              navigate("/product-categories");
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Category Created Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            navigate("/product-categories");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Category Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      name: yup.string().required().label("Name"),
      description: yup.string().required().label("Description"),
    }),
  });

  const formPaperStyle = { padding: 3 };
  const handleChangeIcon = (files) => {
    setCategoryIcon((curr) => files[0]);
  };

  const handleErrorIcon = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  const handleChangeImage = (files) => {
    setCategoryImage((curr) => files[0]);
  };

  const handleErrorImage = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add Product Category - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Add Product Category" />

        <Paper sx={formPaperStyle}>
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>
                    Select Icon for Category
                  </FormLabel>
                  <br />
                  <Files
                    className="dropzone"
                    onChange={handleChangeIcon}
                    onError={handleErrorIcon}
                    accepts={["image/*"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Typography gutterBottom align="center">
                      Drop file here or click to upload icon of category
                    </Typography>
                    {categoryIcon ? (
                      <Box>
                        <img
                          src={URL.createObjectURL(categoryIcon)}
                          className="imagePreview"
                          alt="category icon"
                        />
                      </Box>
                    ) : null}
                  </Files>
                </FormControl>
              </Box>
              <Box my={2}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyles}>
                    Select Display Image for Category
                  </FormLabel>
                  <br />
                  <Files
                    className="dropzone"
                    onChange={handleChangeImage}
                    onError={handleErrorImage}
                    accepts={["image/*"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Typography gutterBottom align="center">
                      Drop file here or click to upload image of category
                    </Typography>
                    {categoryImage ? (
                      <Box>
                        <img
                          src={URL.createObjectURL(categoryImage)}
                          className="imagePreview"
                          alt="category image"
                        />
                      </Box>
                    ) : null}
                  </Files>
                </FormControl>
              </Box>

              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Name of Category"
                    error={formik.touched.name && formik.errors.name}
                    name="name"
                    variant="outlined"
                    value={formik.values.name}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="ID of Category"
                    error={formik.touched.slug && formik.errors.slug}
                    name="slug"
                    variant="outlined"
                    value={formik.values.slug}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.slug && formik.errors.slug}
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Description of Category"
                    error={
                      formik.touched.description && formik.errors.description
                    }
                    name="description"
                    variant="outlined"
                    multiline
                    value={formik.values.description}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl>
                  <FormLabel id="feature">
                    Should Category be Featured
                  </FormLabel>
                  <RadioGroup
                    name="isFeatured"
                    value={formik.values.isFeatured}
                    onChange={formik.handleChange}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <br />
              <Button
                startIcon={
                  isSubmitting && (
                    <CircularProgress severity="primary" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                {isSubmitting ? "Adding Category" : " Add Category"}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </AdminLayout>
  );
}

export default AddProductCategory;
