import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function ToggleContentStatusDialog({
  openDialog,
  handleCloseDialog,
  selectedPage,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>
          {selectedPage?.status
            ? "CONFIRM CONTENT PAGE DEACTIVATION"
            : "CONFIRM CONTENT PAGE ACTIVATION"}
        </DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            {selectedPage?.status ? (
              <DialogContentText>
                Do you want to deactivate this page, click on the deactivate
                button to proceed or, click on the cancel button to ignore
              </DialogContentText>
            ) : (
              <DialogContentText>
                Do you want to activate this page, click on the activate button
                to proceed or, click on the cancel button to ignore
              </DialogContentText>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            {isSubmitting ? (
              "Processing"
            ) : (
              <> {selectedPage?.status ? "Deactivate" : "Activate"} </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
