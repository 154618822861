import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  formatErrorMessage,
  getAuthToken,
  getWindowQueryString,
  labelStylesTwo,
  safeJSONParse,
} from "../utils";
import FormLoader from "../components/FormLoader/FormLoader";
import { bundleInitialValues } from "../components/AddBonusConfiguration/initialvalues";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  betType: yup.string().required().label("Bet Type"),
  expiration: yup.string().required().label("Expiration"),
  commission: yup.number().min(1).max(100).label("Company Commission"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
});

function AddBundleBonus() {
  const [influencerId, setInfluencerId] = useState("");
  const [endTime, setEndTime] = React.useState(new Date());
  const [bonusSearchField, setBonusSearchField] = React.useState("");
  const [currentGameId, setCurrentGameId] = React.useState("");
  const [selectedGame, setSelectedGame] = React.useState({});
  const [betOption, setBetOption] = React.useState([]);
  const [gamesArray, setGamesArray] = React.useState([]);
  const [isGameSelected, setIsGameSelected] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [hasSearchedGames, setHasSearchedGames] = React.useState(false);
  const [loadingBetOption, setLoadingBetOption] = React.useState(false);
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const optionsRef = React.useRef();

  useEffect(() => {
    const userId = getWindowQueryString("influencerId");
    setInfluencerId((curr) => userId);
  }, []);

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // values.expiration = dayjs(values?.expiration).format("DD-MM-YYYY");
    // const userId = getWindowQueryString("influencerId");
    values.gameType = selectedGame?.name;
    values.type = "bundle-bonus";
    values.commission = Number(values.commission) / 100;

    axios
      .post(`${process.env.BASE_URL}bonus/create-bonus`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm();
        if (res?.data?.data?.message?.msg) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message?.msg,
            severity: "success",
          });
        }
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bundle Bonus Created Successfully",
          severity: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        const errMsg = formatErrorMessage(err);
        helpers.setSubmitting(false);

        handleOpenSnackbar({
          message: errMsg,
          severity: "error",
        });
      });
  };

  const handleChangeSearch = (e) => {
    setBonusSearchField(e.target.value);
  };
  const url = `${
    process.env.BASE_URL
  }game/fetch-games?limit=${100}&search=${bonusSearchField}`;
  // const url = `${process.env.BASE_URL}game/fetch-games?limit=${20}`;

  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);

    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption((prevState) => content);
        setLoadingBetOption((prevState) => false);
        setHasSearchedGames((prevState) => true);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleClick = async (game) => {
    setSelectedGame((prevState) => game);
    setCurrentGameId((prevState) => game?.gameId);
    setIsGameSelected((prev) => true);
    setTimeout(() => {
      optionsRef.current.click();
    }, 800);
  };

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Bundle Bonus Configuration - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="New Bundle Bonus" />
        <div>
          <Formik
            initialValues={bundleInitialValues}
            enableReinitialize={true}
            validationSchema={bonusSchema}
            validateOnBlur={true}
            validateOnChange={true}
            //   onSubmit={(values) => console.log(values)}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={2}>
                  <Typography gutterBottom>
                    To add a bonus, search for a game or click on the search
                    button without any text to get the most recent 100 games,
                    select a game from the result and complete filling the form,
                    and click on the Add Bonus button
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Game Title"
                        size="small"
                        fullWidth
                        onChange={(e) => handleChangeSearch(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        size="large"
                        disabled={isSearching}
                        variant="contained"
                        type="button"
                        onClick={() => searchGamesData()}
                      >
                        {isSearching ? "Searching" : "Search"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {hasSearchedGames ? (
                  <Box>
                    {gamesArray && gamesArray?.length > 0 ? (
                      <Grid container spacing={1}>
                        {gamesArray.map((item, idx) => (
                          <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                            <Chip
                              label={item?.name}
                              color={
                                currentGameId === item?.gameId
                                  ? "success"
                                  : "default"
                              }
                              variant={
                                currentGameId === item?.gameId
                                  ? "filled"
                                  : "outlined"
                              }
                              onClick={() => {
                                handleClick(item);
                                setSelectedGame(item);
                                return optionsRef.current.click();
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography>
                        Sorry, no game match your search, search for a new game
                        again
                      </Typography>
                    )}
                  </Box>
                ) : isSearching ? (
                  <FormLoader />
                ) : null}
                <br />
                <button
                  style={{ display: "none" }}
                  type="button"
                  ref={optionsRef}
                  // onClick={() => getOptions()}
                >
                  Fetch Options
                </button>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Bonus Title"
                      error={touched.title && errors.title}
                      disabled={isGameSelected === false}
                      value={values.title}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>

                <Box mt={2} mb={1}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Prize"
                          disabled={isGameSelected === false}
                          value={values.prize}
                          name="prize"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.prize && errors.prize}
                          helperText={touched.prize && errors.prize}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Unit Cost"
                          disabled={isGameSelected === false}
                          value={values.unitCost}
                          name="unitCost"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.unitCost && errors.unitCost}
                          helperText={touched.unitCost && errors.unitCost}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Win Count"
                          disabled={isGameSelected === false}
                          name="winCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.winCount && errors.winCount}
                          helperText={touched.winCount && errors.winCount}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Game Play Count"
                          disabled={isGameSelected === false}
                          name="gamePlayCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.gamePlayCount && errors.gamePlayCount}
                          helperText={
                            touched.gamePlayCount && errors.gamePlayCount
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} my={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Bonus Expiration Period (In Days)"
                          disabled={isGameSelected === false}
                          value={values.expiration}
                          name="expiration"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.expiration && errors.expiration}
                          helperText={touched.expiration && errors.expiration}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Company Commission (1 - 100)"
                          disabled={isGameSelected === false}
                          value={values.commission}
                          name="commission"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.commission && errors.commission}
                          helperText={touched.commission && errors.commission}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Description"
                      multiline
                      disabled={isGameSelected === false}
                      value={values.description}
                      name="description"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </FormControl>
                </Box>

                <Box my={2}>
                  {selectedGame &&
                  selectedGame?.Lottery &&
                  safeJSONParse(selectedGame?.Lottery?.betOptions, "array")
                    ?.length > 0 ? (
                    <FormControl>
                      <FormLabel sx={labelStylesTwo}>Bet Type</FormLabel>
                      <RadioGroup
                        required
                        value={values.betType}
                        row
                        name="betType"
                        onChange={handleChange}
                      >
                        {safeJSONParse(
                          selectedGame?.Lottery?.betOptions,
                          "array"
                        )?.map((item, idx) => (
                          <FormControlLabel
                            key={`${item?.name}ll${idx}`}
                            value={item?.name}
                            control={<Radio color="success" name="betType" />}
                            label={item?.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                </Box>
                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress severity="success" size={"1rem"} />
                    )
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {isSubmitting ? "Adding Bundle" : "Add Bundle"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddBundleBonus;
