import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";

import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmFundingDialog from "./ConfirmFundingDialog";

export default function FundUserAccountDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
  usersURL,
}) {
  const [formValues, setFormValues] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleCloseConfirmFundingDialog = () => {
    handleCloseDialog();
    setOpen(false);
  };

  const { handleOpenSnackbar, adminData } = React.useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      amount: 1,
      narration: "",
      userId: "",
      payReference: "",
      provider: "",
    },
    onSubmit: (values, helpers) => {
      values.userId = selectedUser?.userId;
      values.provider = "source";

      values.adminId = adminData?.adminId;

      setFormValues(values);
      setOpen(true);
    },
    validationSchema: yup.object().shape({
      // payReference: yup.string().required().label("Payment Reference"),
      narration: yup.string().required().label("Narration"),
      amount: yup.number().positive().min(1).required().label("Amount"),
    }),
  });

  return (
    <div>
      {open ? (
        <ConfirmFundingDialog
          values={formValues}
          selectedUser={selectedUser}
          usersURL={usersURL}
          handleFailure={handleCloseConfirmFundingDialog}
          openDialog={open}
        />
      ) : null}
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>
            FUND USER ACCOUNT
          </DialogTitle>
          <DialogContent>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Amount"
                  error={formik.touched.amount && formik.errors.amount}
                  value={formik.values.amount}
                  name="amount"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </FormControl>
            </Box>
            {/* <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                           <TextField
                  label="Payment Reference"
                  value={formik.values.payReference}
                  error={
                    formik.touched.payReference && formik.errors.payReference
                  }
                  name="payReference"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.payReference && formik.errors.payReference
                  }
                />
              </FormControl>
            </Box> */}
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined">Narration</InputLabel> */}
                <TextField
                  label="Narration"
                  value={formik.values.narration}
                  error={formik.touched.narration && formik.errors.narration}
                  name="narration"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.narration && formik.errors.narration
                  }
                />
              </FormControl>
            </Box>
            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "20px" }}>
            <Button
              type="button"
              disabled={formik.isSubmitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={
                formik.isSubmitting && <CircularProgress size={"1rem"} />
              }
              variant="contained"
              color="success"
            >
              {formik.isSubmitting ? "Processing" : "Fund"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
