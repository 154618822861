import React, { useState, useContext } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import AgentsTable from "../components/AgentsComponents/AgentsTable";
import PageHeading from "../components/PageHeading/PageHeading";
import AgentsFilter from "../components/AgentsComponents/AgentsFilter";
import axios from "axios";
import dayjs from "dayjs";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import PreviewAgentModal from "../components/AgentsComponents/PreviewAgentModal";
import FundAgentAccountDialog from "../components/AgentsComponents/FundAgentAccountDialog";
import AgentsAnalytics from "../components/AgentsComponents/AgentAnalytics";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import ToggleAgentDialog from "../components/AgentsComponents/ToggleAgentDialog";
import { AppContext } from "../context";
import DebitAgentAccountDialog from "../components/AgentsComponents/DebitAgentAccount";
import ChangeDeviceIDDialog from "../components/AgentsComponents/ChangeDeviceIDDialog";

function Agents() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openChangeDeviceId, setOpenChangeDeviceId] = useState(false);
  const [openDebitAccountDialog, setOpenDebitAccountDialog] = useState(false);
  const [openReverseFundDialog, setOpenReverseFundDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openToggleDialog, setOpenToggleDialog] = useState(false);
  const [openFundAgentDialog, setOpenFundAgentDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [loginDate, setLoginDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minimumWalletBal, setMinimumWalletBal] = useState(0);
  const [maximumWalletBal, setMaximumWalletBal] = useState(0);
  const { handleOpenSnackbar } = useContext(AppContext);
  const navigate = useNavigate();

  let filterURL = `${process.env.BASE_URL}agent/fetch-agents?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sUserName = "userName";
  let sLoginDate = "loginDate";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinimumBalance = "minimumBalance";
  let sMaximumBalance = "maximumBalance";
  let sUserRole = "userRole";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };
  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleChangeLoginDate = (value) => {
    setLoginDate(value);
    sessionStorage.setItem(sLoginDate, value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumBal = (e) => {
    setMinimumWalletBal(e.target.value);
    sessionStorage.setItem(sMinimumBalance, e.target.value);
  };

  const handleChangeMaximumBal = (e) => {
    setMaximumWalletBal(e.target.value);
    sessionStorage.setItem(sMaximumBalance, e.target.value);
  };
  const handleChangeUserRole = (e) => {
    setUserRole(e.target.value);
    sessionStorage.setItem(sUserRole, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sUserName);
    sessionStorage.removeItem(sLoginDate);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinimumBalance);
    sessionStorage.removeItem(sMaximumBalance);
    sessionStorage.removeItem(sUserRole);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssUserName = sessionStorage.getItem(sUserName);
    if (ssUserName) {
      setUserName(ssUserName);
      setHasFiltered(true);
    }
    let ssLoginDate = sessionStorage.getItem(sLoginDate);
    if (ssLoginDate) {
      setLoginDate(ssLoginDate);
      setHasFiltered(true);
    }
    let sStartDate;
    sStartDate = sessionStorage.getItem(sStartDate);
    if (sStartDate) {
      setStartDate(sStartDate);
      setHasFiltered(true);
    }
    let sSEndDate = sessionStorage.getItem(sEndDate);
    if (sSEndDate) {
      setEndDate(sSEndDate);
      setHasFiltered(true);
    }
    let sSMinimumBalance = sessionStorage.getItem(sMinimumBalance);
    if (sSMinimumBalance) {
      setMinimumWalletBal(sSMinimumBalance);
      setHasFiltered(true);
    }
    let sSMaximumBalance = sessionStorage.getItem(sMaximumBalance);
    if (sSMaximumBalance) {
      setMaximumWalletBal(sSMaximumBalance);
      setHasFiltered(true);
    }
    let sSUserRole = sessionStorage.getItem(sUserRole);
    if (sSUserRole) {
      setUserRole(sSUserRole);
      setHasFiltered(true);
    }

    // Updating URL

    if (userName !== "") {
      if (!filterURL.includes(sUserName)) {
        filterURL = filterURL + `&search=${userName}`;
      }
    }
    if (userRole !== "") {
      if (!filterURL.includes(sUserRole)) {
        filterURL = filterURL + `&role=${userRole}`;
      }
    }
    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minimumWalletBal) {
      if (!filterURL.includes(sMinimumBalance)) {
        filterURL = filterURL + `&minWalletBalance=${minimumWalletBal}`;
      }
    }
    if (maximumWalletBal) {
      if (!filterURL.includes(sMaximumBalance)) {
        filterURL = filterURL + `&maxWalletBalance=${maximumWalletBal}`;
      }
    }
    if (loginDate) {
      if (!filterURL.includes(sLoginDate)) {
        filterURL =
          filterURL + `&lastLoginDate=${dayjs(loginDate).format("DD/MM/YYYY")}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&minCreateDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&maxCreateDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };

  const handleSelectUser = (user) => setSelectedUserInfo(user);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleOpenReverseFundDialog = () => {
    setOpenReverseFundDialog(true);
  };
  const handleCloseReverseFundDialog = () => {
    setOpenReverseFundDialog(false);
  };
  const handleOpenDebitAccountDialog = () => {
    setOpenDebitAccountDialog(true);
  };
  const handleCloseDebitAccountDialog = () => {
    setOpenDebitAccountDialog(false);
  };
  const handleOpenToggleAgentDialog = () => {
    setOpenToggleDialog(true);
  };
  const handleCloseToggleAgentDialog = () => {
    setOpenToggleDialog(false);
  };

  const handleOpenFundAgentDialog = () => {
    setOpenFundAgentDialog(true);
    // setOpenActivateDialog(false);
  };
  const handleCloseFundAgentDialog = () => {
    setOpenFundAgentDialog(false);
  };
  const handleOpenChangeDeviceIdDialog = () => {
    setOpenChangeDeviceId(true);
  };
  const handleCloseChangeDeviceIdDialog = () => {
    setOpenChangeDeviceId(false);
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    if (sStartDate) {
      handleSetSessionStorage();
    }

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const url = `${process.env.BASE_URL}agent/fetch-agents?limit=${rowsPerPage}&page=${page}`;

  const handleResetFields = () => {
    setStatus("");
    setUserName("");
    setUserRole("");
    setLoginDate(null);
    setStartDate(null);
    setEndDate(null);
    setMinimumWalletBal(0);
    setMaximumWalletBal(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleToggleSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: selectedUserInfo?.status ? false : true,
      userId: selectedUserInfo?.userId,
    };

    axios
      .post(`${process.env.BASE_URL}agent/toggle-account-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url);
        handleCloseToggleAgentDialog();
        setIsSubmitting(false);
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }

        handleOpenSnackbar({
          message: "Agent's Account Operation Successful",
          severity: "success",
        });
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Agent's Account Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const handleFiterSubmit = () =>
    mutate(url, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(`${process.env.BASE_URL}agent/fetch-agents?page=${newPage + 1}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url, res?.data?.data);
        setIsFiltering(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancelFilters = () => {
    handleClearSessionStorage();
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, cant retrieve agents data at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Agents Table - Admin, SportyBid" />
      <Container maxWidth="xl">
        <PageHeading title="Agents" />

        {openToggleDialog ? (
          <ToggleAgentDialog
            openDialog={openToggleDialog}
            selectedUser={selectedUserInfo}
            handleCloseDialog={handleCloseToggleAgentDialog}
            handleSubmit={handleToggleSubmit}
            isSubmitting={isSubmitting}
          />
        ) : null}

        {openDialog ? (
          <PreviewAgentModal
            openDialog={openDialog}
            selectedUser={selectedUserInfo}
            handleCloseDialog={handleCloseDialog}
          />
        ) : null}
        {openFundAgentDialog ? (
          <FundAgentAccountDialog
            openDialog={openFundAgentDialog}
            agentsURL={url}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseFundAgentDialog}
          />
        ) : null}
        {openDebitAccountDialog ? (
          <DebitAgentAccountDialog
            openDialog={openDebitAccountDialog}
            agentsURL={url}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDebitAccountDialog}
          />
        ) : null}
        {openChangeDeviceId ? (
          <ChangeDeviceIDDialog
            openDialog={openChangeDeviceId}
            agentsURL={url}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseChangeDeviceIdDialog}
          />
        ) : null}

        <AgentsAnalytics />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <AgentsFilter
            status={status}
            minimumWalletBal={minimumWalletBal}
            maximumWalletBal={maximumWalletBal}
            loginDate={loginDate}
            startDate={startDate}
            endDate={endDate}
            userName={userName}
            userRole={userRole}
            handleChangeStatus={handleChangeStatus}
            handleChangeLoginDate={handleChangeLoginDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumBal={handleChangeMinimumBal}
            handleChangeMaximumBal={handleChangeMaximumBal}
            handleChangeUserName={handleChangeUserName}
            handleChangeUserRole={handleChangeUserRole}
            handleFiterSubmit={handleFiterSubmit}
          />
        )}
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/agents/add")}
          >
            Add New Agent
          </Button>
        </Box>
        <AgentsTable
          hasFiltered={hasFiltered}
          isFiltering={isFiltering}
          selectedUser={selectedUserInfo}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSelectUser={handleSelectUser}
          handleOpenDialog={handleOpenDialog}
          handleOpenReverseFundDialog={handleOpenReverseFundDialog}
          handleOpenToggleAgentDialog={handleOpenToggleAgentDialog}
          handleOpenFundAgentDialog={handleOpenFundAgentDialog}
          handleOpenDebitAccountDialog={handleOpenDebitAccountDialog}
          handleOpenChangeDeviceIdDialog={handleOpenChangeDeviceIdDialog}
          userName={userName}
          userRole={userRole}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default Agents;
